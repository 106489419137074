import React, { useContext, useState, useReducer, createContext } from 'react';
import useConfig from '../useConfig';
import { getPublishedLabel, localStorageHelper } from '../../utils/helpers';

import type { ImageType } from '../../types';

export type LightboxObjType = { assetId: string; publishedId?: string; image: ImageType; slug?: string };

interface BaseConfigProviderProps {
  children: React.ReactNode;
}

interface AssetManagerContextType {
  getImage: (imageAssetId: string) => ImageType;
  setImage: (image: ImageType) => void;
  setImages: (images: ImageType[]) => void;
  setLatestUploads: (images: ImageType[]) => void;
  getLastUploadedImages: () => string[];
  getAssetFromLightbox: (assetId: string) => LightboxObjType;
  addAssetToLightbox: (assetId: string, publishedId?: string) => void;
  removeAssetFromLightbox: (assetId: string) => void;
  removeAllAssetsFromLightbox: () => void;
  getAllAssetsFromLightbox: () => LightboxObjType[];
  lightboxHasAsset: (assetId: string) => boolean;
}

const AssetManagerContext = createContext<AssetManagerContextType>({
  getImage: function (imageId: string): ImageType {
    throw new Error('getImage Function not implemented.');
  },
  setImage: function (image: ImageType): void {
    throw new Error('setImage Function not implemented.');
  },
  setImages: function (images: ImageType[]): void {
    throw new Error('setImages Function not implemented.');
  },
  setLatestUploads: function (images: ImageType[]): void {
    throw new Error('setLatestUploads Function not implemented.');
  },
  getLastUploadedImages: function (): string[] {
    throw new Error('getLastUploadedImages Function not implemented.');
  },
  addAssetToLightbox: function (assetId: string, publishedId?: string): void {
    throw new Error('addAssetToLightbox Function not implemented.');
  },
  removeAssetFromLightbox: function (assetId: string): void {
    throw new Error('removeAssetFromLightbox Function not implemented.');
  },
  removeAllAssetsFromLightbox: function (): void {
    throw new Error('removeAllAssetsFromLightbox Function not implemented.');
  },
  getAssetFromLightbox: function (assetId: string): LightboxObjType {
    throw new Error('getAssetFromLightbox Function not implemented.');
  },
  getAllAssetsFromLightbox: function (): LightboxObjType[] {
    throw new Error('getAllAssetsFromLightbox Function not implemented.');
  },
  lightboxHasAsset: function (assetId: string): boolean {
    throw new Error('lightboxHasAsset Function not implemented.');
  },
});

export function AssetManagerProvider({ children }: BaseConfigProviderProps) {
  const {
    session: { property },
  } = useConfig();
  const [images, setImagesFn] = useState(new Map());
  const [latestUploads, setLatestUploadsFn] = useReducer((prev: any, ids: any) => {
    return { ...prev, ...{ [property]: ids } };
  }, []);
  const [lightbox, setLightbox] = useState(new Map());

  const getImage = (imageAssetId: string) => {
    let image = images.get(`${property}_${imageAssetId}`);

    if (!image) {
      return null;
    }
    return image;
  };

  const setImage = (image: ImageType) => {
    setImagesFn((prev) => new Map(prev.set(`${property}_${image.asset_id}`, image)));
  };

  const setImages = (images: ImageType[]) => {
    images.map((image) => {
      setImage(image);
    });
  };

  const setLatestUploads = (images: ImageType[]) => {
    if (images.length === 0) return;

    const latestUploadIds = images.map((image) => {
      setImage(image);
      return image.asset_id;
    });

    localStorageHelper.createItem(property, latestUploadIds);
    setLatestUploadsFn(latestUploadIds);
  };

  const addAssetToLightbox = (assetId: string, publishedId?: string) => {
    setLightbox((map) => new Map(map.set(`${property}_${assetId}`, publishedId || null)));
  };

  const removeAssetFromLightbox = (assetId: string) => {
    setLightbox((map) => {
      map.delete(`${property}_${assetId}`);
      return new Map(map);
    });
  };

  const removeAllAssetsFromLightbox = () => {
    setLightbox(new Map());
  };

  const getAssetFromLightbox = (assetId: string): LightboxObjType => {
    const publishedId = lightbox.get(`${property}_${assetId}`);
    const image = getImage(assetId);

    const asset = {
      assetId,
      publishedId,
      image,
      slug: publishedId ? getPublishedLabel(image, publishedId, property) : 'Unpublished',
    };
    return asset;
  };

  const getAllAssetsFromLightbox = () => {
    const assets: LightboxObjType[] = [];
    lightbox.forEach((_, assetId) => {
      const asset = getAssetFromLightbox(assetId.replace(`${property}_`, ''));
      assets.push(asset);
    });
    return assets;
  };
  const lightboxHasAsset = (assetId: string) => {
    return lightbox.has(`${property}_${assetId}`);
  };

  const value: AssetManagerContextType = {
    getImage,
    setImage,
    setImages,
    setLatestUploads,
    getLastUploadedImages: () => latestUploads[property] || [],
    addAssetToLightbox,
    removeAssetFromLightbox,
    removeAllAssetsFromLightbox,
    getAssetFromLightbox,
    getAllAssetsFromLightbox,
    lightboxHasAsset,
  };
  return <AssetManagerContext.Provider value={value}>{children}</AssetManagerContext.Provider>;
}

export function useAssetManager() {
  const context = useContext(AssetManagerContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}

export default useAssetManager;
