import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Alert, Group, IconError, PageWrapper, Grid } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

import useAssetManager from '../../hooks/useAssetManager';
import useConfig from '../../hooks/useConfig';
import useUpload from '../../hooks/useUpload';

import UploadNavbar from '../../components/Upload/Navbar';
import UploadBox from '../../components/Upload/UploadBox';
import UploadForm from '../../components/Upload/Form';
import UploadDynamicForm from '../../components/Upload/Form/Dynamic';
import UploadStaging from '../../components/Upload/Staging';

import { localStorageHelper } from '../../utils/helpers';
import { isFrame } from '../../utils/iframe';

import styles from './Upload.module.css';

const UploadLayout = () => {
  const {
    assetsToUpload,
    rejectedAssets,
    removeRejectedAsset,
    type,
    allAssetsPassValidation,
    navigation,
    publishAssets,
    setUploadNavigation,
    getRootProps,
    onDragOver,
    isComplete,
  } = useUpload();

  const { onClick: handleBrowseFiles } = getRootProps();
  const { setLatestUploads } = useAssetManager();
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    session: { property, SEARCH },
  } = useConfig();

  if (!property || !SEARCH) {
    return;
  }

  const backButton = () => {
    if (!location.pathname.includes('/upload')) {
      localStorageHelper.deleteItem(property);
    }
    navigate(-1);
  };

  const publishAndMyUploadsButton = async () => {
    setUploadNavigation(1);
    if (!isComplete) {
      await publishAssets();
    }

    const djUserId = (user?.dj_user_id && user?.dj_user_id.toLowerCase()) || '';
    const acceptedFiles = assetsToUpload.filter((asset) => asset.state === 'complete');
    setLatestUploads(acceptedFiles.map((asset) => asset.response?.data));

    if (navigation === 1 || navigation === 2) {
      //Navigate To Image Details
      if (acceptedFiles.length === 1 || type === 'dynamic') {
        const image = acceptedFiles[0].response?.data || {};
        navigate(`/${property}/image/${image.asset_id}`, { state: { image } });

        //Navigate To My Uploads
      } else if (acceptedFiles.length > 1) {
        navigate(`/${property}?user=${djUserId}`);
      }
    }
    return null;
  };

  const stagingCondition = navigation === 1 || navigation === 2;

  return (
    <div
      data-withinFrame={isFrame() ? 'uploadWrapperWithoutHeader' : 'uploadWrapper'}
      className={`${isFrame() ? styles.uploadWrapperWithoutHeader : styles.uploadWrapper} ${
        onDragOver ? styles.onDragOver : ''
      }`}
    >
      <UploadNavbar
        status={assetsToUpload.map((asset) => asset.state)}
        response={[]}
        onButtonLeft={navigation < 1 ? backButton : null}
        onButtonRight={allAssetsPassValidation() ? publishAndMyUploadsButton : null}
        stepActive={navigation + 1}
        stepComplete={navigation}
        type={type}
      />
      <div className={styles.pageWrapperContent}>
        {!navigation && (
          <>
            <PageWrapper>
              {!!rejectedAssets.length && (
                <Grid>
                  <Grid.Row center="xs">
                    <Grid.Col md={assetsToUpload.length ? 10 : 8} xs={12}>
                      <div className={styles.errorWrapper}>
                        <Group
                          align="stretch"
                          className={styles.errorContainer}
                          direction="column"
                          data-testid="error-alert"
                          gap="sm"
                          margin={{ vertical: 'md' }}
                        >
                          {rejectedAssets.map((rejectedFile, i) => {
                            const { errors, file } = rejectedFile;

                            const rejectedImageError = errors?.[0];
                            const link = rejectedImageError?.code?.includes('image-exists')
                              ? `/${property}/image/${rejectedImageError.asset_id}`
                              : null;

                            const fileName = file?.path?.split('/').pop();
                            const message = rejectedImageError?.message || 'Rejected file';

                            return (
                              <Alert
                                icon={IconError}
                                key={fileName}
                                onDismiss={() => {
                                  removeRejectedAsset({ index: i });
                                }}
                                type={link ? 'primary' : 'error'}
                              >
                                {message} - {fileName}
                                {link && (
                                  <Alert.Action componentEl={Link} to={link} target="_blank">
                                    View image
                                  </Alert.Action>
                                )}
                              </Alert>
                            );
                          })}
                        </Group>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              )}
              {!assetsToUpload.length && <UploadBox handleBrowseFiles={handleBrowseFiles} />}
              {!!assetsToUpload.length && (
                <>
                  {type === 'dynamic' ? (
                    <UploadDynamicForm handleBrowseFiles={handleBrowseFiles} />
                  ) : (
                    <UploadForm handleBrowseFiles={handleBrowseFiles} />
                  )}
                </>
              )}
            </PageWrapper>
          </>
        )}

        {stagingCondition && <UploadStaging />}
      </div>
    </div>
  );
};

export default UploadLayout;
