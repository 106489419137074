import type { ActivityLogsOptionsType } from '../types';

export const RESERVED_SEARCH_WORDS = ['AND', 'OR', 'NOT'];
export const PUBLISHED_ID_CHARS = '1234567890';

/**
 * The number of images loaded per page on search
 */
export const PAGE_SIZE = 50;

/**
 * The enum values for loading states
 */
export const LOADING_STATES = Object.freeze({
  COMPLETE: 'complete',
  ERROR: 'error',
  LOADING: 'loading',
  READY: 'ready',
});

export const REQUEST_METHODS = Object.freeze({
  DELETE: 'DELETE',
  GET: 'GET',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT',
});

/**
 * Options for image metadata field "GraphicType"
 */
export const GRAPHIC_TYPES = Object.freeze(['Photo', 'Photo Illustration', 'Illustration', 'Chart', 'Commerce']);

/**
 * Date formats used in various contexts throughout the app
 */
export const DATE_FORMATS = Object.freeze({
  CLOUDINARY: 'yyyy-MM-dd',
  SEARCH: Object.freeze({
    DATE_FILTER: 'MMMM do, yyyy',
  }),
  UPLOADS: Object.freeze({
    EDIT_METADATA_FORM: 'MM/dd/yyyy',
  }),
});

export const TAGS = Object.freeze({
  ONE_TIME_USE: 'one_time_use',
  UNCREDITED: 'uncredited',
});

/**
 * The maximum number of files that can be uploaded at once
 */
export const MAX_FILES_IN_UPLOADER = { single: 25, dynamic: 25 };

/**
 * The maximum number of files that can be uploaded at once
 * -- https://www.gbmb.org/mb-to-bytes
 * 40 MB = 40000000 Bytes (in decimal)
 * 40 MB = 41943040 Bytes (in binary)
 * --
 */
export const MAX_FILE_SIZE = 41943040; // 40mb

/**
 * The maximum number of pixels allowed in an image
 */
export const MAX_PIXELS = 175e6; // 175 million

/**
 * The maximum number of pixels allowed in an image
 */
export const WARNING_PIXELS = 50e6; // 50 million

/**
 * The maximum number of characters allowed in a slug
 */
export const MAX_SLUG_LENGTH = 32;

/**
 * The maximum number of character  accpeted on a cloudinary field
 */

export const CLOUDINARY_MAXIMUM_STRING_LENGTH = 1024;

/**
 * The maximum pixels width for rendering a image
 *  Note: this width matches the default width in cloudinary fn_selects
 */
export const DEFAULT_WIDTH = 1280;

export const ALL_USERS = 'All Users';
export const MY_IMAGES = 'My Images';

export const DEFAULT_IMAGE_KEY = 'Default';

export const DEFAULT_SLUG = 'Slug';

// TODO:get error messages from Carlos
export const ERRORS = {
  FETCH_ERROR: 'An error occurred while fetching users.',
  USERS_NOT_FOUND: 'No users found.',
};

export const DEFAULTS: {
  [key: string]: string | number | boolean;
} = {
  GRAPHIC_TYPE: 'Photo',
  DYNAMIC_GRAPHIC_TYPE: 'Illustration',
};

// Image formats allowed in upload page.
export const validFiles = {
  single: { 'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': [] },
  dynamic: { 'image/svg+xml': [], 'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': [] },
};

export const ACTIVITY_OPTIONS: ActivityLogsOptionsType = {
  update: {
    label: 'updated %VALUE%',
    defaultValue: 'image metadata',
  },
  crop: {
    label: 'cropped image',
  },
  focalpoint: {
    label: 'edited the focal area',
  },
  coordinates: {
    label: 'edited the focal area',
  },
  upload: {
    label: 'uploaded the image',
  },
  import: {
    label: 'was automatically imported',
    showRelatedProperty: true,
  },
  eager: {
    label: 'updated image cache ',
  },
  publish: {
    label: 'published the image %PROPERTY%',
    showRelatedProperty: true,
  },
  delete: {
    label: 'deleted the image',
  },
  invalidate: {
    label: 'invalidated the image cache',
    hide: true,
  },
  error_log: {
    label: 'error recorded',
  },
  access_mode: {
    label: 'made image publicly accessible',
    hide: true,
  },
  delete_cache: {
    label: 'cleared image cache',
    hide: true,
  },
  disable_thumbnails_crop: {
    label: '%BOOLEAN_VALUE% thumbnails crops',
    defaultValue: 'updated',
    hideDetails: true,
    valueMap: {
      true: 'disabled',
      false: 'enabled',
    },
  },
  placed_in_allesseh: {
    label: 'Image placed in %PRODUCT% article %ARTICLE_LINK% %PROPERTY%',
    showRelatedProperty: true,
  },
  add_tag: {
    label: 'added tag %TAG%',
  },
  add_tag_to_context: {
    label: 'added tag to context',
    hide: true,
  },
  remove_tag: {
    label: 'removed tag %TAG%',
  },
};

export const privateTransformations = [
  { named: 'preview', format: 'avif' },
  { width: 1000, format: 'avif', aspect_ratio: '$AR' },
];

/**
 * Default for Image Previews om image details page
 */
export const FIXED_IMAGE_HEIGHT = 530;
export const THUMBNAIL_WIDTH = 250;
export const MAX_CLOUDINARY_COLORS = 10;

export const specialCharMap: { [key: string]: string } = {
  äàáâäæãåā: 'a',
  çćč: 'c',
  đð: 'd',
  èéêëēėę: 'e',
  îïíīįì: 'i',
  ł: 'l',
  ñńň: 'n',
  ôöòóœøōõ: 'o',
  ř: 'r',
  śš: 's',
  ß: 'ss',
  ť: 't',
  ûüùúūů: 'u',
  ÿý: 'y',
  žżŻź: 'z',
};

export const extraSpecialChars = `!^&*\(\){}[\\]\<\>\?\~\=\:\@\#\$\%\'.\-`;

export const OPERATORS = {
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT',
};

export const GRAPHIC_TYPES_OPTIONS_CONFIG: Record<string, { label: string; metadata: string }> = {
  photo: {
    label: 'Photo',
    metadata: 'metadata.graphic_type=Photo',
  },
  illustration: {
    label: 'Illustration',
    metadata: 'metadata.graphic_type=Illustration',
  },
  photoIllustration: {
    label: 'Photo Illustration',
    metadata: 'metadata.graphic_type="Photo Illustration"',
  },
  chart: {
    label: 'Chart',
    metadata: 'metadata.graphic_type=Chart',
  },
  commerce: {
    label: 'Commerce',
    metadata: 'metadata.graphic_type=Commerce',
  },
};

export const DEFAULT_SEARCH_FIELD_VALUES = {
  region: ['Domestic (US)', 'International', 'Tri-State Area'],
  category: ['Sport', 'Entertainment'],
};

export const IMAGE_PAGE_MODE = {
  DETAILS: 'details',
  METADATA: 'metadata',
  EDIT: 'edit',
};

export const IMAGE_PROPERTY = {
  RESIZE_ONLY: 'resize_only',
};
