import React from 'react';

import {
  Typography,
  FormLabel,
  IconEdit,
  IconTrash,
  IconLoader,
  Button,
  Checkbox,
  Group,
  Box,
  Wrapper,
} from '@screentone/core';

type SelectBarProps = {
  /** a flag that indicates whether or not all images are selected */
  selectedImages: number[];
  /** handler for closing bulk form */
  closeSidebar(): void;
  /** handler for opening bulk form */
  openSidebar(): void;
  /** image data object */
  onRemoveImage?(): void;
  /** numberOfValidImages */
  numberOfValidImages: number;
  /** the status of the bulk edit form */
  status: 'open' | 'closed';
  /** cloud name info */
  totalImages: number;
  /** handle selecting all images for bulk edit */
  onSelectAll(selected: boolean): void;
};

const SelectBar = ({
  closeSidebar,
  openSidebar,
  selectedImages,
  onRemoveImage,
  onSelectAll,
  numberOfValidImages = 0,
  status,
  totalImages,
}: SelectBarProps) => (
  <>
    <Wrapper padding={{ all: 'md' }}>
      <Group align="space-between">
        <Typography inline variant="header3" margin={{ all: 'none' }}>
          Upload
        </Typography>
        <Group style={{ marginLeft: 'auto' }}>
          <Typography variant="note">
            <Group direction="row" gap="sm">
              <IconLoader size="md" />
              {numberOfValidImages} / {totalImages} Images ready to publish
            </Group>
          </Typography>
        </Group>
      </Group>
    </Wrapper>
    <Box margin={{ bottom: 'md' }} padding={{ all: 'md' }}>
      <Group align="space-between">
        <FormLabel label="Select all to bulk edit" labelPosition="right">
          <Checkbox
            data-testid="select-all-checkbox"
            checked={totalImages === selectedImages.length}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const isSelected = e.target.checked;

              // close form if all images are unselected
              if (!isSelected) closeSidebar();

              onSelectAll(isSelected);
            }}
          />
        </FormLabel>
        <Group align="space-between">
          <Button
            data-testid="bulk-edit-btn"
            disabled={!selectedImages.length}
            icon={IconEdit}
            onClick={status === 'open' ? closeSidebar : openSidebar}
            tertiary
          >
            Bulk Edit Metadata
          </Button>
          <Button
            disabled={!selectedImages.length || !onRemoveImage}
            tertiary
            icon={IconTrash}
            onClick={() => {
              if (onRemoveImage) onRemoveImage();
            }}
          >
            Remove
          </Button>
        </Group>
      </Group>
    </Box>
  </>
);

export default SelectBar;
