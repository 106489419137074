import React from 'react';
import { Tooltip, Typography, IconInfo, List } from '@screentone/core';

const AltTextTooltip = () => {
  return (
    <Tooltip>
      <Tooltip.Content position="bottom" style={{ width: '360px', left: '180px' }}>
        <Typography size="sm" weight="med" margin={{ bottom: 'sm' }} style={{ color: 'var(--st-color-cool)' }}>
          Useful Guidelines
        </Typography>
        <List listStyle="bullet">
          <List.Item>
            <Typography size="sm" style={{ color: 'var(--st-color-cool)' }}>
              1-2 sentences
            </Typography>
          </List.Item>
          <List.Item>
            <Typography size="sm" style={{ color: 'var(--st-color-cool)' }}>
              Mention if image is a logo, painting, illustration, diagram, video, etc.
            </Typography>
          </List.Item>
          <List.Item>
            <Typography size="sm" style={{ color: 'var(--st-color-cool)' }}>
              Redundant wording is not ideal
            </Typography>
          </List.Item>
          <List.Item>
            <Typography size="sm" style={{ color: 'var(--st-color-cool)' }}>
              End alt-text with a period.
            </Typography>
          </List.Item>
        </List>
      </Tooltip.Content>
      <Tooltip.Trigger aria-describedby="tooltipContent">
        <Typography variant="label3" inline>
          Alt Text
        </Typography>
        <IconInfo
          size="smd"
          color="asphalt"
          margin={{ left: 'xs', right: 'xs' }}
          style={{
            cursor: 'help',
            verticalAlign: 'middle',
          }}
        />
      </Tooltip.Trigger>
    </Tooltip>
  );
};

export default AltTextTooltip;
