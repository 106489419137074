import React, { useContext, useState, useEffect, createContext } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Link } from 'react-router-dom';

import type { AppConfigBase, OktaAuthServiceBase } from './useConfig.types';

const BaseConfigContext = createContext({});

export type ConfigType = {
  app: AppConfigBase;
  auth: OktaAuthServiceBase;
} | null;

function OverrideLinkRender({
  props,
  component: Component,
  location,
}: {
  props: any;
  component: React.ElementType;
  location: string;
}) {
  const { currentProperty } = useAuth();
  if (location === 'header-app-name') {
    return (
      <Component componentEl={Link} to={`/${currentProperty || ''}`} state={{ from: 'header-app-name' }} {...props} />
    );
  }
  return <Component {...props} />;
}

export function BaseConfigProvider({ children }: { children: React.ReactNode }) {
  const [config, setConfig] = useState<ConfigType>();

  useEffect(() => {
    async function getAppConfig() {
      const response = await fetch('/api/config');
      const config = await response.json();
      if (config.app) {
        config.app.overrideLinkRender = OverrideLinkRender;
      }

      setConfig(config as ConfigType);
    }
    getAppConfig();
  }, []);

  return (
    <BaseConfigContext.Provider value={{ baseConfig: config as ConfigType }}>{children}</BaseConfigContext.Provider>
  );
}

export function useBaseConfig() {
  const context = useContext(BaseConfigContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context as { baseConfig: ConfigType };
}

export default useBaseConfig;
