import React, { useContext, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useAuth } from '@screentone/addon-auth-wrapper';

import useConfig from '../useConfig';

import UploadBox from '../../components/Upload/UploadBox';

import { processDroppedFile, uploadAssets } from './utils';

import fieldsValidation, { firstLoadValidation } from '../../utils/fieldsValidation';

import {
  getAvailablePropertiesKeys,
  localStorageHelper,
  mergeReducer,
  requiredFieldsForPublisher,
} from '../../utils/helpers';

import { constants } from '../../utils';

import type {
  ImageType,
  UserType,
  IUseUploadState,
  IUseUploadInitialState,
  tUploadProviderArguments,
  tProcessedFile,
} from '../../types';

// TODO: why are we using layout styles here?
import styles from '../../layouts/Upload/Upload.module.css';

const UploadOverlay = () => {
  return (
    <div className={`${styles.uploadWrapperWithoutHeader} ${styles.onDragOver}`}>
      <div className={styles.uploadWrapperContent}>
        <UploadBox />
      </div>
    </div>
  );
};

const UploadContext = React.createContext<IUseUploadState | undefined>(undefined);

const initialState: IUseUploadInitialState = {
  assetsToUpload: [] as tProcessedFile[],
  rejectedAssets: [] as any[],
  bulk: {
    metadata: {},
    selectedAssetsIndexes: [],
  },
  navigation: 0,
  loading: false,
  isComplete: false,
};
const bulkFields = ['slug', 'caption', 'credit', 'contact', 'headline', 'graphicType', 'datePhotographed', 'altText'];

/** Provider for upload context */
export const UploadProvider = ({ children }: tUploadProviderArguments) => {
  const {
    authFetch,
    session: { property, SEARCH },
  } = useConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();
  const [onDragOver, setOnDragOver] = useState(false);

  const [uploadState, setUploadState] = useReducer(mergeReducer, structuredClone(initialState));
  const pathnameArray = location.pathname.split('/');
  const uploadPage = pathnameArray[3] === 'upload';
  const type = pathnameArray[4] === 'dynamic' ? 'dynamic' : 'single';
  const propertyKeys = getAvailablePropertiesKeys();
  const isCurrentProperty = propertyKeys.includes(property) && pathnameArray[1] === property;
  const isDisabled = !isCurrentProperty || (uploadState.assetsToUpload.length !== 0 && uploadState.isComplete);
  const requiredFields = requiredFieldsForPublisher(property, SEARCH?.OPTIONS?.FORM_VALIDATION, !SEARCH);

  /**
   * Process files for upload
    @param files - array of files to process
    @param error(errors) - array of errors;
   */
  const processFiles = async ({ files, errors }: { files: File[]; errors: any[] }) => {
    const processedFiles: any[] = [];
    const acceptedAssetsToUpload: any[] = [];
    const rejectedAssetsToUpload: any[] = [];

    for (let i in files) {
      const processedFile = await processDroppedFile({
        file: files[i],
        type,
        user: (user as UserType) || undefined,
        authFetch,
      });

      processedFiles.push(processedFile);
    }

    processedFiles.forEach((_, i) => {
      const numberOfUploads = [...uploadState.assetsToUpload, ...acceptedAssetsToUpload].length;
      if (numberOfUploads >= constants.MAX_FILES_IN_UPLOADER[type]) {
        rejectedAssetsToUpload.push({
          ...processedFiles[i],
          errors: [
            {
              code: 'max-files',
              message: `File exceeds max number of files (max ${constants.MAX_FILES_IN_UPLOADER[type]})`,
            },
          ],
        });
      } else if (processedFiles[i].state !== 'rejected') {
        acceptedAssetsToUpload.push(processedFiles[i]);
      } else {
        const { error, ...others } = processedFiles[i];
        rejectedAssetsToUpload.push({ ...others, errors: [error] });
      }
    });

    acceptedAssetsToUpload.forEach((asset, i) => {
      Object.keys(asset.validation).forEach((key) => {
        // This is to validate the metadata fields on first load. Current this is only for max length to avoid issues in cloudinary
        let firstValidation = firstLoadValidation(key, asset.metadata[key]);
        let validationResult = null;
        // if it is a required field it should be null on  first load to avoid displaying error message. the publish validating will still be impacted.
        if (requiredFields[key] && !firstValidation) {
          const normalValidation = fieldsValidation({
            field: key,
            value: asset.metadata[key],
            isRequired: requiredFields[key],
          });
          //you need to pass the false value id the validation is valid if not it need to stay null to prevent the error message to be displayed
          if (!normalValidation) {
            validationResult = normalValidation;
          }
        }
        acceptedAssetsToUpload[i].validation[key] = firstValidation ? firstValidation : validationResult;
      });
    });

    setUploadState({
      assetsToUpload: [...uploadState.assetsToUpload, ...acceptedAssetsToUpload],
      rejectedAssets: [...uploadState.rejectedAssets, ...rejectedAssetsToUpload, ...errors],
    });
  };

  /**
   * Main function to update metadata of an asset. Usually called from the form onChange
    @param index - index of the asset in the assetsToUpload array
    @param metadata - object with the filed and value to update 
    @param validate - boolean to validate the metadata fields
   */
  const assetMetadataUpdate = ({
    index,
    metadata,
    validate = false,
  }: {
    index: number;
    metadata: any;
    validate: boolean;
  }) => {
    const { assetsToUpload } = uploadState;
    const currentMetadata = uploadState.assetsToUpload[index].metadata;
    assetsToUpload[index].metadata = { ...currentMetadata, ...metadata };

    if (validate) {
      Object.keys(metadata).forEach((key) => {
        // if (requiredFields[key]) {
        const value = metadata[key];
        assetsToUpload[index].validation[key] = fieldsValidation({
          field: key,
          value,
          isRequired: requiredFields[key],
        });
        // }
      });
    }

    setUploadState({ assetsToUpload });
  };

  /**
   * This function removes an asset from the assetsToUpload array
    @param index - index of the asset to remove
   */
  const removeAsset = ({ index }: { index: number }) => {
    const { assetsToUpload } = uploadState;
    selectForBulkUpdate({ index, isSelected: false });
    setUploadState({ assetsToUpload: assetsToUpload.filter((_: any, i: number) => i !== index) });
  };

  /**
   * This function checks if all assets pass validation
   * false if there is any asset with a validation error
   * true if all assets pass validation
   * @returns boolean
   */
  const allAssetsPassValidation = () => {
    const { assetsToUpload } = uploadState;

    if (assetsToUpload.length === 0) return false;

    if (type === 'dynamic') {
      for (let key in requiredFields) {
        if (!uploadState?.bulk?.metadata[key] || assetsToUpload[0]?.validation[key] !== false) {
          return false;
        }
      }
    } else {
      // single
      for (let i in assetsToUpload) {
        const asset = assetsToUpload[i];
        for (let key in asset.validation) {
          if ((requiredFields[key] && asset.validation[key] !== false) || asset.validation[key]) {
            return false;
          }
        }
      }
    }

    return true;
  };

  /**
   * This function dismissed the rejected asset with error
   * @param index - index of the asset to remove with rejectedAssets array
   */
  const removeRejectedAsset = ({ index }: { index: number }) => {
    const { rejectedAssets } = uploadState;
    setUploadState({ rejectedAssets: rejectedAssets.filter((_: any, i: number) => i !== index) });
  };

  /**
   * Handle the selection or deselection of assets for bulk update
   * @param isSelected boolean - if the asset is selected or not
   * @param index number - index of the asset in the assetsToUpload array
   */
  const selectForBulkUpdate = ({ isSelected, index }: { isSelected: boolean; index: number }) => {
    const { selectedAssetsIndexes } = uploadState.bulk;

    if (isSelected) {
      // If For Resolving Bulk
      if (!selectedAssetsIndexes.includes(index)) {
        selectedAssetsIndexes.push(index);
      }
    } else {
      const indexToRemove = selectedAssetsIndexes.indexOf(index);
      selectedAssetsIndexes.splice(indexToRemove, 1);
    }

    // Set same value in for bulk metadata for all selected asset
    bulkFields.forEach((key) => {
      const value1 = uploadState.assetsToUpload[selectedAssetsIndexes[0]]?.metadata[key];
      for (let i = 0; i < selectedAssetsIndexes.length; i++) {
        const value2 = uploadState.assetsToUpload[selectedAssetsIndexes[i]]?.metadata[key];

        if (value1 && value2 && value1 === value2) {
          uploadState.bulk.metadata[key] = value1;
        } else {
          delete uploadState.bulk.metadata[key];
        }
      }
    });

    setUploadState({ bulk: { ...uploadState.bulk, selectedAssetsIndexes } });
  };

  /**
   * Update the metadata of all selected assets
   * @param metadata - object with the filed and value to update
   * @param reset - boolean to reset the metadata
   */
  const bulkUpdate = ({ metadata, reset = false }: { metadata: Record<string, any>; reset?: boolean }) => {
    const currentMeta = { ...uploadState.bulk.metadata, ...metadata };
    setUploadState({ bulk: { ...uploadState.bulk, metadata: reset ? {} : currentMeta } });
  };

  /**
   * Update the metadata of all selected assets
   * @param navigation - number of the upload navigation steps
   */
  const setUploadNavigation = (navigation: number) => {
    setUploadState({ navigation });
  };

  /**
   * loop through all assets and upload them to cloudinary
   */
  const publishAssets = async (): Promise<ImageType[]> => {
    const assetsToUpload = uploadState.assetsToUpload;
    let isComplete = true;
    const publishCallback = ({
      error,
      indexArr,
      response,
      state,
    }: {
      error: any;
      indexArr: number[];
      response: any;
      state: any;
    }) => {
      for (let index of indexArr) {
        if (error) {
          assetsToUpload[index].error = error;
          assetsToUpload[index].state = 'rejected';
          console.error('publishAssets callback Error: ', error);
        } else if (state === 'complete') {
          assetsToUpload[index].state = state;
          assetsToUpload[index].response = response;
        } else {
          assetsToUpload[index].state = state;
        }
      }

      setUploadState({ assetsToUpload, isComplete });
    };

    setUploadState({
      assetsToUpload: assetsToUpload.map((asset: any) => {
        if (asset.state !== 'complete') asset.state = 'uploading';
        return asset;
      }),
    });

    const nonCompleteAssets = assetsToUpload
      .map((asset: tProcessedFile, i) => {
        asset.index = i;
        return asset;
      })
      .filter((asset: tProcessedFile) => asset.status !== 'complete');
    const response = await uploadAssets({ assets: nonCompleteAssets, authFetch, property, type }, publishCallback);

    for (let i in response) {
      assetsToUpload[nonCompleteAssets[i].index].response = response[i];
      if (response[i] && response[i].error) {
        isComplete = false;
      }
    }
    setUploadState({ isComplete });
    return response;
  };

  const retryPublishAssets = () => {
    const assetsToUpload = uploadState.assetsToUpload;
    for (let i in assetsToUpload) {
      if (assetsToUpload[i].state === 'rejected') {
        assetsToUpload[i].state = 'accepted';
        delete assetsToUpload[i].response;
        delete assetsToUpload[i].error;
      }
    }
    setUploadState({ assetsToUpload });
    publishAssets();
  };

  /**
   * Resets the upload state to the initial state
   */
  const resetUploadState = () => {
    setUploadState(structuredClone(initialState));
  };

  /**
   * set dropzone properties
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: constants.validFiles[type],
    maxSize: constants.MAX_FILE_SIZE,
    noDragEventsBubbling: true,
    disabled: isDisabled,
    onDrop: (files: File[], errors: FileRejection[]) => {
      property && localStorageHelper.deleteItem(property);

      if (uploadState.isComplete) {
        resetUploadState();
      }

      processFiles({ files, errors });

      if (!uploadPage) {
        navigate(`/${property}/images/upload`);
      }
      setOnDragOver(false);
    },
    onDragEnter: (e) => {
      setOnDragOver(true);
    },
    onDragOver: (e) => {
      setOnDragOver(true);
    },
    onDragLeave: (e) => {
      setOnDragOver(false);
    },
  });

  const value: IUseUploadState = {
    assetsToUpload: uploadState.assetsToUpload,
    bulk: uploadState.bulk,
    navigation: uploadState.navigation,
    loading: uploadState.loading,
    isComplete: uploadState.isComplete,
    assetMetadataUpdate,
    rejectedAssets: uploadState.rejectedAssets,
    removeRejectedAsset,
    allAssetsPassValidation,
    removeAsset,
    selectForBulkUpdate,
    bulkUpdate,
    setUploadNavigation,
    publishAssets,
    retryPublishAssets,
    resetUploadState,
    type,
    onDragOver,
    getRootProps,
  };

  const shouldRenderUploadOverlay = onDragOver && !uploadPage;
  const { onClick, ...dropZoneProps } = getRootProps();

  return (
    <UploadContext.Provider value={value}>
      <div {...dropZoneProps}>
        <input data-testid="file-input-inside" {...getInputProps()} />
        {shouldRenderUploadOverlay && <UploadOverlay />}
        {children}
      </div>
    </UploadContext.Provider>
  );
};

/**
 * Hook for getting and setting information about upload options
 * For more information about the pattern used, see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const useUpload = () => {
  const context = useContext(UploadContext);

  if (context === undefined) {
    throw new Error('useUpload must be used within a UploadProvider');
  }

  return context;
};

export { useUpload };
export default useUpload;
