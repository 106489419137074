import React, { useEffect, useState } from 'react';
import { Combobox, IconCheck, Group, FormLabel } from '@screentone/core';
import { TRANSFORMERS } from 'cloudinary-build-url/dist/esm/constants';
import { PredefinedVariables } from 'cloudinary-build-url/dist/esm/constants/arithmetic';

import useEditImage from '../../../../hooks/useEditImage';
import useConfig from '../../../../hooks/useConfig';

import styles from './Overlays.module.css';
import { find, flatMap } from 'lodash';
import { getTransformerObj } from './Overlays.utils';

const convertTransformationStringToObj = (transformations: string) => {
  const TRANSFORMERS_KEYS: { [key: string]: string } = {};
  const TRANSFORMERS_OBJ: { [key: string]: string } = {
    ...TRANSFORMERS,
    ...PredefinedVariables,
    crop: 'c',
    flags: 'fl',
    border: 'bo',
  };
  Object.keys(TRANSFORMERS_OBJ).map((key) => {
    TRANSFORMERS_KEYS[TRANSFORMERS_OBJ[key as keyof typeof TRANSFORMERS_OBJ]] = key;
  });

  const transformationArray = transformations?.split('/');
  const transformationObj: { [key: string]: any } = {};
  transformationArray?.forEach((segment: string) => {
    segment.split(',').forEach((item) => {
      const [key, value] = item.split(/_(.*)/s);
      transformationObj[TRANSFORMERS_KEYS[key]] = value;
    });
  });

  return transformationObj;
};

type PlacementType =
  | 'north_west'
  | 'north'
  | 'north_east'
  | 'east'
  | 'center'
  | 'west'
  | 'south_west'
  | 'south'
  | 'south_east';
const Placements = ({
  placement: initialPlacement,
  onChange,
}: {
  placement: PlacementType;
  onChange: (placement: PlacementType) => void;
}) => {
  const [selectedPlacements, setSelectedPlacements] = useState<PlacementType>(initialPlacement);
  const placements: PlacementType[] = [
    'north_west',
    'north',
    'north_east',
    'west',
    'center',
    'east',
    'south_west',
    'south',
    'south_east',
  ];

  useEffect(() => {
    setSelectedPlacements(initialPlacement);
  }, [initialPlacement]);

  return (
    <FormLabel label="Overlay Placement" labelPosition="top">
      <Group gap="sm" className={styles.placements__container}>
        {placements.map((placement) => (
          <button
            key={placement}
            className={selectedPlacements === placement ? styles.placements__container_active : undefined}
            onClick={() => {
              setSelectedPlacements(placement);
              onChange(placement);
            }}
          >
            {selectedPlacements === placement ? <IconCheck color="blurple" /> : null}
          </button>
        ))}
      </Group>
    </FormLabel>
  );
};
const Overlays = ({ panelKey }: { panelKey: string }) => {
  const {
    session: { OVERLAYS },
  } = useConfig();
  const { getData, setData } = useEditImage();
  const overlayString = getData('overlays', panelKey);
  const [overlayObj, setOverlayObj] = useState<{ [key: string]: any }>({});
  const overlayItems = OVERLAYS?.options || {};

  const [selectedOverlays, setSelectedOverlays] = useState<string>();
  const [overrides, setOverrides] = useState<{ [key: string]: { [key: string]: any } }>({});

  useEffect(() => {
    const transformationObj = convertTransformationStringToObj(overlayString);
    setOverlayObj(transformationObj);

    const initialObj = find(overlayItems, (item: { key: string; transformations: { field: string; default: any } }) => {
      const obj: { [key: string]: any } = {};
      flatMap(item.transformations).forEach((segment) => {
        const typedSegment = segment as { field: string; default: any };
        obj[typedSegment.field] = typedSegment.default;
      });
      return obj.overlay === transformationObj.overlay;
    });

    setSelectedOverlays(initialObj?.key);
  }, [overlayString]);

  useEffect(() => {
    const handleOverlayChange = () => {
      if (!selectedOverlays) return;

      const [transformerObj, transformation] = getTransformerObj(
        OVERLAYS?.options[selectedOverlays],
        selectedOverlays,
        overrides,
      );

      if (typeof transformerObj === 'object') {
        setOverlayObj(transformerObj);
      }
      setData('overlays', transformation, panelKey);
    };

    handleOverlayChange();
  }, [selectedOverlays, overrides]);

  const items = Object.keys(overlayItems).map((item, i) => ({
    id: i,
    label: OVERLAYS?.options[item].label,
    value: item,
  }));

  const restOverlay = () => {
    setData('overlays', null, panelKey);
    setOverlayObj({});
    setSelectedOverlays('');
  };

  const selectedItem = items.find((item) => item.value === selectedOverlays);

  let OptionsComponents: any;
  if (selectedItem) {
    const overlayOptions = OVERLAYS?.options[selectedItem.value];

    OptionsComponents = Object.keys(overlayOptions?.editable).map((item) => {
      const component = OVERLAYS?.fields[item].component;

      switch (component) {
        case 'Placements':
          const placement =
            overlayObj[item] || overrides?.[selectedItem.value]?.[item] || overlayOptions?.editable?.[item].default;
          return (
            <Placements
              placement={placement}
              onChange={(placement) => {
                setOverrides({ ...(overrides || {}), [selectedItem.value]: { [item]: placement } });
              }}
            />
          );
        default:
          return null;
      }
    });
  }

  return (
    <>
      <Combobox
        items={items}
        onSelectedItemChange={(selectedItemData: { value: string }) => {
          setSelectedOverlays(selectedItemData?.value);
        }}
        selectedItem={selectedItem}
        initialSelectedItem={selectedItem}
        reset={() => {
          restOverlay();
        }}
        onInputValueChange={(label: string) => {
          const matchedItem = items.find((item) => item.label === label);
          if (matchedItem) {
            setSelectedOverlays(matchedItem.value);
          } else {
            restOverlay();
          }
        }}
      />

      {OptionsComponents}
    </>
  );
};

export default Overlays;
