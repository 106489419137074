import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Group,
  Steps,
  IconArrowRight,
  IconCaretLeft,
  IconLoader,
  IconSend,
  Responsive,
  IconPlus,
  Subnav,
} from '@screentone/core';

import { useAssetManager } from '../../../hooks/useAssetManager';
import useConfig from '../../../hooks/useConfig';
import useUpload from '../../../hooks/useUpload';

import { getLastPublished } from '../../../utils/helpers';
import { constants, iframe } from '../../../utils';

import styles from './UploadNavbar.module.css';

type UploadNavbarTypes = {
  /** text for left button */
  buttonLeftContent?: string;
  /** text for right button */
  buttonRightContent?: string;
  /** active step */
  stepActive?: number;
  /** completed steps */
  stepComplete?: number;
  /** event handler for left button. Button will be disabled if not provided */
  onButtonLeft?: (() => void) | null;
  /** event handler for right button. Button will be disabled if not provided */
  onButtonRight?: (() => void) | null;
  /** status of upload process */
  status: string[];
  /** type of upload */
  type: string;
  /** response from upload */
  response?: any;
};

function UploadNavbar({
  buttonLeftContent = 'Back',
  buttonRightContent = 'Publish',
  stepActive = 1,
  stepComplete = 0,
  onButtonLeft,
  onButtonRight,
  status,
  response,
  type,
}: UploadNavbarTypes) {
  const {
    authFetch,
    session: { property },
  } = useConfig();

  const { assetsToUpload, allAssetsPassValidation } = useUpload();
  const navigate = useNavigate();
  const { getLastUploadedImages, getImage } = useAssetManager();
  const latestUploads = getLastUploadedImages();
  const [isAddingToSource, setIsAddingToSource] = useState(false);
  const images = latestUploads.map((image) => getImage(image));

  const [firstImage, setFirstImage] = useState<any>(response.length ? response[0] : null);
  const isComplete = status.length ? status.every((stat) => stat === 'complete') : false;
  const hasLoadingAssets = !assetsToUpload.every((asset) => asset.state !== 'uploading');

  if (isComplete && (status.length === 1 || type === 'dynamic')) {
    const image = assetsToUpload[0].response;
    if (type === 'dynamic') {
      const all_resources = structuredClone(assetsToUpload.map((asset) => asset.response));
      image.additional_resources = all_resources;
    }
    navigate(`/${property}/image/${assetsToUpload[0].response.asset_id}`, { state: { image } });
  }

  const showAddToSource = iframe.isFrame() && iframe.sendImageArray() && isComplete && status.length > 1;

  useEffect(() => {
    if (images.length) {
      setFirstImage(images[0]);
    }
  }, [images]);

  // if (type === 'dynamic') {
  //   console.group('buttonRightContent: ', buttonRightContent);
  //   console.log('isDisabled: ', !onButtonRight || status.includes('uploading') || !allAssetsPassValidation());
  //   console.log('!onButtonRight: ', !onButtonRight);
  //   console.log("status.includes('uploading'): ", status.includes('uploading'));
  //   console.log('!allAssetsPassValidation(): ', !allAssetsPassValidation());
  //   console.groupEnd();
  // }

  return (
    <Subnav margin={{ all: 'none' }} className={styles.wrapper}>
      <Responsive xs>
        <Group align="space-between" fullWidth>
          <Button icon={IconCaretLeft} tertiary onClick={onButtonLeft} disabled={!onButtonLeft}>
            {buttonLeftContent}
          </Button>
          <Steps active={stepActive} complete={stepComplete}>
            <Steps.Item number={1} />
            <Steps.Item number={2} />
          </Steps>
          <Button
            primary
            icon={isComplete ? IconArrowRight : IconSend}
            iconPosition="right"
            onClick={onButtonRight}
            disabled={!onButtonRight || hasLoadingAssets}
          />
        </Group>
      </Responsive>
      <Responsive sm md lg>
        <Group align="space-between" fullWidth>
          <Button icon={IconCaretLeft} tertiary onClick={onButtonLeft} disabled={!onButtonLeft}>
            {buttonLeftContent}
          </Button>
          <Steps active={stepActive} complete={stepComplete}>
            <Steps.Item>Upload</Steps.Item>
            <Steps.Item>Publish</Steps.Item>
          </Steps>
          <Group inline>
            <Button
              data-testid="top-right-btn"
              size={iframe.isFrame() ? 'sm' : undefined}
              primary={!showAddToSource && !isComplete}
              secondary={showAddToSource || isComplete}
              onClick={onButtonRight}
              disabled={!onButtonRight || status.includes('uploading') || !allAssetsPassValidation()}
            >
              {isComplete ? constants.MY_IMAGES : buttonRightContent}
            </Button>
            {isComplete ? (
              <Button
                data-testid="adjust-images-button"
                size={iframe.isFrame() ? 'sm' : undefined}
                primary
                icon={IconArrowRight}
                iconPosition="right"
                componentEl={Link}
                disabled={!isComplete}
                state={{ uploads: response }}
                to={`/${property}/batch/${firstImage?.asset_id}/edit/${getLastPublished(firstImage, property)}`}
              >
                Adjust Images
              </Button>
            ) : null}
            {!!showAddToSource && (
              <Button
                data-testid="add-all-to-article-btn"
                size="sm"
                icon={isAddingToSource ? IconLoader : IconPlus}
                primary
                onClick={async () => {
                  setIsAddingToSource(true);

                  try {
                    await iframe.sendToSource({
                      image: assetsToUpload.map((item) => item?.response?.data),
                      ids: latestUploads,
                      property,
                      authFetch,
                    });
                    setIsAddingToSource(false);
                  } catch (e) {
                    console.error('Error sending images to source', e);
                    setIsAddingToSource(false);
                  }
                }}
              >
                {iframe.getSendToSourceLabel(true)}
              </Button>
            )}
          </Group>
        </Group>
      </Responsive>
    </Subnav>
  );
}

export default UploadNavbar;
