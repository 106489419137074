import { cloneDeep } from 'lodash';

import { errors, regex } from '../../../utils';

type SignedParams = { [key: string]: string };

const uploadAssets = ({ authFetch, assets, type, property }, callback = () => {}) => {
  const result: any[] = [];
  const metadataToUpload = assets.map((asset) => cloneDeep(asset.metadata));
  return authFetch(`/api/:property/upload/${type === 'single' ? 'request' : type}`, {
    method: 'POST',
    body: JSON.stringify({ metadata: { ...metadataToUpload, property } }),
  })
    .then(async (response: any) => {
      const body = response;

      if (body.length > 0) {
        const assetsToUpload = assets;
        for (let i = 0; i < assetsToUpload.length; i++) {
          const asset = assetsToUpload[i];
          const file = asset.file;

          try {
            const { formData, uploadUrl } = body[i];
            const form = new FormData();

            form.append('file', file);
            // Append all options (tags, metadata, context, eager, etc) to formData
            Object.entries(formData as SignedParams).forEach(([key, value]) => {
              form.append(key, value);
            });

            const uploadResponse = await fetch(uploadUrl, { method: 'POST', body: form });
            const data = await uploadResponse.text();
            const responseData = JSON.parse(data);

            if (!responseData.error) {
              const cleanResponseData = { ...responseData };
              cleanResponseData.isDynamic = responseData.public_id.split('/')[1] === 'dynamic';
              cleanResponseData.isAnimated = (responseData.pages || 0) > 1;
              cleanResponseData.isChart = !!regex.chartSourceRegex.exec(responseData?.metadata?.import_source || '');

              if (cleanResponseData?.context?.custom) {
                cleanResponseData.context = cleanResponseData.context.custom;
              }

              result.push({ data: cleanResponseData });
              callback({ state: 'complete', indexArr: [i], response: cleanResponseData });
            } else {
              result.push({ error: responseData.error.message });
              callback({ state: 'rejected', indexArr: [i], error: responseData.error.message });
              throw new Error(responseData.error.message);
            }
          } catch (error: any) {
            console.error(`error uploading to cloudinary: ${JSON.stringify(error)}`);
            const { message, status } = errors.extractError(error);
            const errorMessage = `error uploading to cloudinary: ${JSON.stringify(message)}`;
            callback({ state: 'rejected', indexArr: [i], error: errorMessage });
            result.push({ error: errorMessage });
          }
        }
        return result;
      }
    })
    .catch((error) => {
      const errorMessage = `error uploading to cloudinary: ${JSON.stringify(error)}`;
      console.error(errorMessage);
      callback({ status: 'error', indexArr: assets.map((asset, i) => i), error: errorMessage });
      return assets.map(() => ({ error: errorMessage }));
    });
};

export { uploadAssets };
export default uploadAssets;
