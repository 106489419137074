{
  "SLUG": {
    "KEY": "SLUG",
    "LABEL": "Slug",
    "SCHEMA": { "slug": { "field": "published_label", "type": "text" } },
    "DESCRIPTION": "A short description to identify this image set",
    "REQUIRED": true
  },
  "FOCAL_AREA": {
    "KEY": "FOCAL_AREA",
    "LABEL": "Focal Area",
    "DESCRIPTION": "Adjust the gravity when rendering different sizes",
    "SCHEMA": {
      "coordinates": {
        "field": "coordinates",
        "type": "arrayNumber",
        "length": 6,
        "value": "{DATA}",
        "join": ",",
        "regex": {
          "string": "(?<x>\\d{0,}),(?<y>\\d{0,}),(?<w>\\d{0,}),(?<h>\\d{0,}),(?<cx>\\d{0,}),(?<cy>\\d{0,})",
          "options": "gm"
        }
      }
    }
  },
  "THUMB_FOCAL_AREA": {
    "KEY": "THUMB_FOCAL_AREA",
    "LABEL": "Thumbnails",
    "DESCRIPTION": "Adjust the thumbnail gravity and zoom",
    "SCHEMA": {
      "disable": { "field": "disable_thumbnails_crop", "type": "boolean" },
      "coordinates": {
        "field": "gravity_thumb",
        "type": "arrayNumber",
        "length": 4,
        "value": "auto:subject:aoi_{DATA}",
        "join": "_",
        "regex": {
          "string": "auto:subject:aoi_(?<x>\\d{0,})_(?<y>\\d{0,})_(?<w>\\d{0,})_(?<h>\\d{0,})",
          "options": "gm"
        }
      }
    }
  },
  "CROP": {
    "KEY": "CROP",
    "LABEL": "Reframe Image",
    "DESCRIPTION": "Adjust the crop of the default image",
    "SCHEMA": {
      "coordinates": {
        "field": "crop",
        "type": "arrayNumber",
        "length": 4,
        "value": "{DATA}",
        "join": ",",
        "regex": {
          "string": "(?<x>\\d{0,}),(?<y>\\d{0,}),(?<w>\\d{0,}),(?<h>\\d{0,})",
          "options": "gm"
        }
      }
    }
  },
  "BACKGROUND_COLOR": {
    "KEY": "BACKGROUND_COLOR",
    "LABEL": "Background Color",
    "SCHEMA": {
      "background": { "field": "background", "type": "string" }
    }
  },
  "QUALITY": {
    "KEY": "QUALITY",
    "LABEL": "Quality",
    "SCHEMA": {
      "quality": { "field": "quality", "type": "string" }
    }
  },
  "GRAVITY": {
    "KEY": "GRAVITY",
    "LABEL": "Gravity",
    "SCHEMA": {
      "gravity": { "field": "gravity", "type": "string" }
    }
  },
  "OVERLAYS": {
    "KEY": "OVERLAYS",
    "LABEL": "Watermark to Overlay",
    "TOOLTIP": {
      "LABEL": "Beta",
      "CONTENT": "Note: this is a BETA feature and is subject to change."
    },
    "DESCRIPTION": "Lay a watermark over the default image",

    "SCHEMA": {
      "overlays": { "field": "overlay", "type": "string" }
    }
  }
}
