import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Group,
  IconArticle,
  IconCheckCircle,
  IconUpload,
  IconImageGallery,
  IconDownload,
  LoaderBar,
  Token,
  Tooltip,
  Typography,
} from '@screentone/core';

import useDownloader from '../../../node_module_resolver/react-use-downloader';

import Image from '../Image';
import {
  getAllPublishedIds,
  getImageUrl,
  getPlaced,
  getImageAspectRatio,
  getTagIcons,
  getAvailablePropertiesLabels,
} from '../../utils/helpers';
import useConfig from '../../hooks/useConfig';

import type { ImageType } from '../../types';

import styles from './Gallery.module.css';

type GalleryItemProps = {
  /** information about image. TODO: need to pull from standard type def once API is finalized */
  image: ImageType;
  /** whether this item shows "active" state */
  active?: boolean;
  /** whether this item has been selected */
  isSelected?: boolean;
  /** event handler when image is clicked */
  onClick(e: any): void;
  /** dimensions for displaying this item in the context of the Gallery component */
  dimensions: {
    width: number;
  };
  /** handler when selected */
  AddToLightboxEl?: React.ReactNode;
  /** handler when deselected */
  RemoveFromLightboxEl?: React.ReactNode;
};

/**
 * Given an array of images, shows a row of them
 */
const GalleryItem = ({
  image,
  dimensions,
  active = false,
  onClick,
  isSelected = false,
  AddToLightboxEl,
  RemoveFromLightboxEl,
}: GalleryItemProps) => {
  const {
    session: { property, IMAGE_DOMAINS, RELATED_PROPERTIES, SEARCH },
  } = useConfig();

  const imageUrl = getImageUrl({ image, property, defaultPreviewUrl: true, IMAGE_DOMAINS });
  const downloadUrl = image.secure_url;
  const downloadFileName = `${image.public_id}.${image.format}`;

  const {
    download: downloadImage,
    percentage: imageDownloadPercentage,
    isInProgress: downloadInProgress,
  } = useDownloader();

  const containerClasses = [styles.item];

  if (active) containerClasses.push(styles['item--active']);
  if (isSelected) containerClasses.push(styles['item--selected']);

  const imageCallback = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClick(e);
    },
    [onClick],
  );

  const allPublishedIds = getAllPublishedIds(image, property);
  const isPublished = allPublishedIds.length > 0;
  const hasMultiplePublished = allPublishedIds.length > 1;
  const isUpload = image?.metadata?.import_source_type === 'upload';
  const { tags } = SEARCH?.OPTIONS || {};
  const placed = getPlaced(image, property, RELATED_PROPERTIES);
  const ICONS = getTagIcons();

  const imageDetailsHREF = `/${property}/image/${image.asset_id}`;
  return (
    <div
      data-testid="gallery-item"
      className={containerClasses.join(' ')}
      style={{ width: `${dimensions.width}%`, aspectRatio: getImageAspectRatio(image, property) }}
    >
      <Link
        to={imageDetailsHREF}
        onClick={imageCallback}
        className={styles.item__a_img}
        style={{ '--im-preview-url': `url(${imageUrl})` } as React.CSSProperties}
      >
        <Image
          alt={image?.metadata?.caption || image?.metadata?.headline || 'Image without caption'}
          src={imageUrl}
          className={styles.item__img}
          draggable={!isSelected}
        />
      </Link>
      {AddToLightboxEl && RemoveFromLightboxEl && (
        <div className={styles.item__select}>
          <Tooltip>
            <Tooltip.Content position="left" id={`tooltip-${image.public_id}`}>
              {isSelected ? 'Remove from Lightbox' : 'Add to Lightbox'}
            </Tooltip.Content>
            <Tooltip.Trigger aria-describedby={`tooltip-${image.public_id}`}>
              {isSelected ? RemoveFromLightboxEl : AddToLightboxEl}
            </Tooltip.Trigger>
          </Tooltip>
        </div>
      )}
      <Group gap="none" className={styles.item__meta} fullWidth align="space-between">
        <Group gap="xs" margin={{ all: 'sm ' }}>
          {!isUpload && isPublished && <Token icon={IconCheckCircle} color="blurple" aria-label="Published" />}
          {isUpload && <Token icon={IconUpload} color="blurple" aria-label="Upload" title="Upload" />}
          {isPublished && hasMultiplePublished && (
            <Token color="tangerine" title="Multiple image sets" icon={IconImageGallery} />
          )}
          {tags &&
            Object.keys(tags).map((tagKey) => {
              const tag = tags[tagKey];
              if (image.tags?.includes(tag.key)) {
                return (
                  <Token
                    color={tag.color}
                    aria-label={tag.label}
                    title={tag.title}
                    icon={ICONS[tag.icon as keyof typeof ICONS]}
                  />
                );
              }
              return null;
            })}

          {placed &&
            (RELATED_PROPERTIES.length > 0 ? (
              <Tooltip>
                <Tooltip.Content position="bottom" id="tooltipContent">
                  <Typography weight="bold" size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                    Used By
                  </Typography>
                  {Object.keys(placed).map((item: string) => {
                    const [value] = item.split(':');
                    const PROPERTY_LABELS = getAvailablePropertiesLabels();
                    return (
                      <Typography key={item} size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                        {PROPERTY_LABELS[value as keyof typeof PROPERTY_LABELS] || value}
                      </Typography>
                    );
                  })}
                </Tooltip.Content>
                <Tooltip.Trigger aria-describedby="tooltipContent">
                  <Token icon={IconArticle} color="gray" aria-label="Used in Article" style={{ cursor: 'pointer' }} />
                </Tooltip.Trigger>
              </Tooltip>
            ) : (
              <Token icon={IconArticle} color="gray" aria-label="Used in Article" title="Used in Article" />
            ))}
        </Group>
        <Button
          data-testid="download-btn"
          className={styles.item__download_icon}
          margin={{ all: 'sm ' }}
          tertiary
          disabled={downloadInProgress}
          color="white"
          icon={IconDownload}
          componentEl="a"
          href={downloadUrl}
          onClick={(e: Event) => {
            e.preventDefault();
            downloadImage(downloadUrl, downloadFileName);
          }}
        />
      </Group>

      {downloadInProgress && (
        <LoaderBar
          style={{ width: '100%', position: 'absolute' }}
          percent={imageDownloadPercentage}
          displayPercent={false}
          text={null}
        />
      )}
    </div>
  );
};

GalleryItem.displayName = 'GalleryItem';
export default GalleryItem;
