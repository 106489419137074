import { Transformer } from "cloudinary-build-url";

export const getTransformerObj = (overlayOptions: { transformations: any[][]; }, selectedOverlays: string, overrides: { [x: string]: { [key: string]: any; } | { [x: string]: any; }; }) => {
  const overlayTransform = overlayOptions?.transformations?.map((segment: any[]) => {
    const chainedItems: { [key: string]: any } = {};

    segment.forEach((item) => {
      if (item.required) {
        chainedItems[item.field] = overrides?.[selectedOverlays]?.[item.field] || item.default;
      }
    });

    return chainedItems;
  });

  const transformerObj = Transformer.transform({
    chaining: overlayTransform,
  });

  const transformation = Transformer.toString(transformerObj).replace('fl_layer_apply:', 'fl_layer_apply,fl_');

  return [transformerObj, transformation];
}