import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  FormLabel,
  Grid,
  Group,
  Input,
  Modal,
  Overlay,
  Typography,
  Select,
  IconCode,
  Token,
} from '@screentone/core';
import { useHeaderData } from '@screentone/addon-auth-wrapper';
import iframeSources from '../../../config/iframe/iframe-sources.json';
import styles from './iframe.module.css';

type SavedImagesType = {
  [key: string]: {
    credit: string;
    caption: string;
    id: string;
    src: {
      baseUrl: string;
      imageId: string;
      path: string;
    };
    ref: string;
  };
};

type MessageData = {
  credit: string;
  caption: string;
  id: string;
  src: {
    baseUrl: string;
    imageId: string;
    path: string;
  };
  ref: string;
};

const useWindowEvent = (event: any, callback: any) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window?.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useGlobalMessage = (callback: any) => useWindowEvent('message', callback);

function Iframe() {
  const { state } = useHeaderData();
  const [url] = useState(window.location.origin);
  const [property, setProperty] = useState('wsj');
  const [startPage, setStartPage] = useState('');
  const [reference, setReference] = useState('im-block-39f3d554-a02b-43ca-9118-0cfbde7f7bbb');
  const [source, setSource] = useState('ng-sked');

  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [savedImages, setSavedImages] = useState<SavedImagesType>({});
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);

  const sourceArray = Object.entries(iframeSources);
  const handleImageMessage = useCallback(
    (event: { data: string }) => {
      if (event.data) {
        const data: MessageData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

        if (Array.isArray(data) && data[0].id) {
          console.log('handleImageMessage data: ', data); // eslint-disable-line no-console
          console.log('handleImageMessage data string: ', JSON.stringify(data)); // eslint-disable-line no-console

          const formattedData = {} as SavedImagesType;
          data.forEach((image: any) => {
            formattedData[image.ref] = image;
          });
          setSavedImages({ ...savedImages, ...formattedData });

          setIsIframeOpen(false);
        } else if (data.id) {
          setSavedImages({ ...savedImages, ...{ [data.ref]: data } });
          setIsIframeOpen(false);
        }
      }
    },
    [savedImages],
  );

  useEffect(() => {
    localStorage.setItem('IM-IFRAME_SOURCE', source.toLowerCase());
  }, [source]);

  useGlobalMessage(handleImageMessage);
  const iframeUrl = `${url}/${property}${startPage}?ref=${reference}&source=${source}`;
  return (
    <>
      <Typography variant="header1">iFrame - Examples</Typography>

      {!!isModalOpen && (
        <Dialog onDismiss={() => setIsModalOpen(null)} status={isModalOpen ? 'open' : 'closed'} size="xl">
          <Dialog.Title>Image Data</Dialog.Title>
          <Dialog.Content>
            <code>
              <pre>{JSON.stringify(savedImages[isModalOpen], null, 2)}</pre>
            </code>
          </Dialog.Content>
        </Dialog>
      )}
      <Grid>
        <Grid.Row>
          <Grid.Col xs={12} mdOffset={1} md={10} xlOffset={2} xl={8}>
            <Typography variant="bodytext">
              Test CMS integration for this current environment, select the property you wish to connect to:
            </Typography>
            <Group direction="column" align="stretch" gap="md" margin={{ bottom: 'lg' }}>
              <FormLabel label="Property" labelPosition="top">
                <Select
                  data-testid="property-dropdown"
                  value={property}
                  onChange={({ target }: any) => setProperty(target.value)}
                >
                  {state?.header?.properties &&
                    state.header.properties.map((p: any) => (
                      <option key={p.value} value={p.value}>
                        {p.name}
                      </option>
                    ))}
                </Select>
              </FormLabel>
              <FormLabel label="Start Page" labelPosition="top">
                <Select
                  data-testid="startPage-dropdown"
                  value={startPage}
                  onChange={({ target }: any) => {
                    setStartPage(target.value);
                  }}
                >
                  <option value="">Search</option>
                  <option value="/upload">Upload</option>
                </Select>
              </FormLabel>
              <FormLabel label="Source" labelPosition="top">
                <Select
                  data-testid="source-dropdown"
                  value={source}
                  onChange={({ target }: any) => setSource(target.value)}
                >
                  {sourceArray.map(([key, obj]) => (
                    <option key={key} value={key}>
                      {obj.description}
                    </option>
                  ))}
                </Select>
              </FormLabel>
              <FormLabel label="Reference" labelPosition="top">
                <Input value={reference} onChange={({ target }: any) => setReference(target.value)} />
              </FormLabel>
            </Group>
            <Group align="stretch" gap="md">
              {' '}
            </Group>
            {Object.keys(savedImages).length > 0 && (
              <Grid>
                <Grid.Row>
                  <Grid.Col xs>
                    <Grid.Row center="xs">
                      {Object.keys(savedImages).map((key) => {
                        const image = savedImages[key];
                        if (image) {
                          return (
                            <Grid.Col xs={4} key={image.id + key}>
                              <Box>
                                <Box.Title className={styles.image_ref_box}>
                                  <span>{image?.src?.imageId}</span>
                                  <Button
                                    tertiary
                                    icon={IconCode}
                                    onClick={() => {
                                      setIsModalOpen(image.ref);
                                    }}
                                  />
                                </Box.Title>
                                <img
                                  data-testid="added-image"
                                  alt={image?.src?.imageId}
                                  className={styles.image_ref}
                                  src={`${image?.src?.baseUrl}${image?.src?.imageId}?width=400`}
                                />
                              </Box>
                            </Grid.Col>
                          );
                        }
                        return null;
                      })}
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            )}

            <Button
              data-testid="addImage-btn"
              primary
              onClick={() => {
                setReference(`${reference}_${savedImages.length ? savedImages.length : ''}`);
                setIsIframeOpen(true);
              }}
            >
              Add Image
            </Button>

            {isIframeOpen && (
              <Overlay status={isIframeOpen ? 'open' : 'closed'}>
                <Modal className={styles.iframe_modal} fullWidth onDismiss={() => setIsIframeOpen(false)}>
                  <Modal.Title className={styles.iframe_modal_st_modal__title}>
                    Image Manager <Token>{iframeUrl}</Token>
                  </Modal.Title>
                  <iframe
                    id="im-iframe"
                    data-testid="im-iframe"
                    allow="clipboard-write"
                    className={styles.iframe}
                    title={`iframe ${iframeUrl}`}
                    src={iframeUrl}
                    sandbox="allow-scripts allow-forms allow-same-origin allow-popups"
                  />
                </Modal>
              </Overlay>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Iframe;
