{
  "default": {
    "key": "default",
    "description": "default (NO Source)",
    "allow_upload": true,
    "data_delivery": {
      "method": "iframePostMessage",
      "list": false,
      "format": "NP"
    },
    "button_label": "Add to Article"
  },
  "np-cms": {
    "key": "np-cms",
    "description": "NewsPress - Article Page (NP-CMS)",
    "allow_upload": true,
    "data_delivery": {
      "method": "iframePostMessage",
      "list": false,
      "format": "NP"
    },
    "button_label": "Add to Article"
  },
  "np-gws": {
    "key": "np-gws",
    "description": "NewsPress - Google WebStories (NP-GWS)",
    "allow_upload": true,
    "data_delivery": {
      "method": "iframePostMessage",
      "list": true,
      "format": "NP"
    },
    "button_label": "Add to Story"
  },
  "np-gallery": {
    "key": "np-gallery",
    "description": "NewsPress - Gallery (NP-GALLERY)",
    "allow_upload": true,
    "data_delivery": {
      "method": "iframePostMessage",
      "list": true,
      "format": "NP"
    },
    "button_label": "Add to Gallery"
  },
  "ng-sked": {
    "key": "ng-sked",
    "description": "NewsGrid Sked",
    "allow_upload": true,
    "data_delivery": {
      "method": "iframePostMessage",
      "list": true,
      "format": "default"
    },
    "button_label": "Add to Sked"
  }
}
