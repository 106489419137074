import React, { createContext, useContext, useEffect, useState } from 'react';

import useConfig from '../../hooks/useConfig';
import useImageDetail from '../../hooks/useImageDetail';
import useAssetManager from '../useAssetManager';

import { getCoordinateOffsets } from './editImage.utils';

import { ImageType } from '../../types';
import { getContextValue, parseToBoolIfNeeded } from '../../utils/helpers';
import { DEFAULT_SLUG } from '../../utils/constants';
import { constants } from '../../utils';
import useSearch from '../useSearch';

import CONTENT_COMPONENT_CONST from './contentComponent.const.json';

type SchemaType =
  | 'slug'
  | 'disable'
  | 'coordinates'
  | 'background'
  | 'arrayNumber'
  | 'quality'
  | 'gravity'
  | 'overlays';

type ContentComponentKeyType = 'SLUG' | 'FOCAL_AREA' | 'THUMB_FOCAL_AREA' | 'CROP' | 'BACKGROUND_COLOR';

type ContentComponentType = {
  [key: string]: {
    KEY: string;
    LABEL: string;
    SCHEMA: {
      [key: string]: {
        field: string;
        type: string;
        length?: number;
        value?: string;
        join?: string;
        regex?: {
          string: string;
          options: string;
        };
      };
    };
  };
};

const MAX_COORDINATES_KEY = ['x', 'y', 'w', 'h', 'cx', 'cy'];

const EditImageContext =
  createContext<
    | {
        image: ImageType;
        publishedId: string | undefined;
        CONTENT_COMPONENT_CONST: ContentComponentType;
        contentComponent: string | false;
        setContentComponent: (value: string | false) => void;
        getData: (type: SchemaType, key?: string) => any;
        setData: (type: SchemaType, data: any, key?: string) => void;
        resetData: (type?: SchemaType, key?: string) => void;
        isDirty: (key?: string) => any;
        ARLock: number | null;
        setARLock: (number: number | null) => void;
        publishData: () => Promise<any>;
        getCoordinateOffsets: (coordinates: number[]) => number[];
        previewType: string;
        setPreviewType: (value: string) => void;
        imageSrcUrl?: string;
        openFocalComponent: boolean;
        setOpenFocalComponent: (value: boolean) => void;
        isSaving?: boolean;
        setIsSaving: (value: boolean) => void;
        isSavingWait?: boolean;
        setIsSavingWait: (value: boolean) => void;
      }
    | undefined
  >(undefined);

/** Arguments for UploadProvider */
type EditImageProviderArguments = {
  children?: React.ReactNode;
};

/** Provider for context */
export const EditImageProvider = ({ children }: EditImageProviderArguments) => {
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const { image, publishedId, setImage: setDetailsImage, setLocalContext } = useImageDetail();
  const { setImage: setBatchFlowImage } = useAssetManager();
  const [contentComponent, setContentComponent] = useState<string | false>(false);
  const [data, setDataFn] = useState<any>(new Map());
  const [ARLock, setARLock] = useState<number | null>(null);
  const [previewType, setPreviewType] = useState(constants.DEFAULT_IMAGE_KEY);
  const [openFocalComponent, setOpenFocalComponent] = useState(false);
  const { setImage: setSearchImage } = useSearch();
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingWait, setIsSavingWait] = useState(false);

  const setImage = (image: ImageType) => {
    setDetailsImage(image);
    setBatchFlowImage(image);
    setSearchImage(image);
  };

  useEffect(() => {
    if (publishedId && image?.context) {
      const mapData = new Map();
      Object.keys(image.context).map((key: string) => {
        if (key.endsWith(publishedId)) {
          mapData.set(key.replace(`_${publishedId}`, ''), image.context?.[key]);
        }
      });
      setDataFn(mapData);
      setInitialLocalContext(mapData);
    }
  }, [publishedId, image]);

  const setInitialLocalContext = (newMap: any) => {
    Object.keys(CONTENT_COMPONENT_CONST).map((key) => {
      const { SCHEMA } = CONTENT_COMPONENT_CONST[(key || contentComponent) as ContentComponentKeyType];
      Object.keys(SCHEMA).map(() => {
        if (publishedId && (key || contentComponent)) {
          const localContext: { [key: string]: any } = {};
          newMap.forEach((value: string, key: string) => {
            localContext[`${key}_${publishedId}`] = value;
          });
          setLocalContext(localContext);
        }
      });
    });
  };

  const setData = (type: SchemaType, data: any, key?: string) => {
    const { SCHEMA } = CONTENT_COMPONENT_CONST[(key || contentComponent) as ContentComponentKeyType];
    const schema = SCHEMA[type];

    if (publishedId && (key || contentComponent)) {
      setDataFn((prevList: Iterable<readonly [unknown, unknown]> | null | undefined) => {
        const newMap = new Map(prevList);
        const localContext: { [key: string]: any } = {};
        const updatedData =
          data && schema.value
            ? schema.value.replace('{DATA}', data?.split(',', schema.length).join(schema.join))
            : data;
        newMap.set(schema.field, updatedData);

        newMap.forEach((value, key) => {
          localContext[`${key}_${publishedId}`] = value;
        });
        setLocalContext((prevLocalContext) => ({
          ...prevLocalContext,
          ...localContext,
        }));
        return newMap;
      });
    }
  };

  const resetData = (type?: SchemaType, key?: string) => {
    return (type ? [key] : Object.keys(CONTENT_COMPONENT_CONST)).map((key) => {
      const { SCHEMA } = CONTENT_COMPONENT_CONST[(key || contentComponent) as ContentComponentKeyType];
      return (type ? [type] : Object.keys(SCHEMA)).map((type) => {
        const schema = SCHEMA[type];
        if (publishedId && (key || contentComponent)) {
          setDataFn((prevList: Iterable<readonly [unknown, unknown]> | null | undefined) => {
            const newMap = new Map(prevList);
            const localContext: { [key: string]: any } = {};
            const imageData = getContextValue(image, schema.field, property, publishedId);
            newMap.set(schema.field, imageData);
            newMap.forEach((value, key) => {
              localContext[`${key}_${publishedId}`] = value;
            });
            setLocalContext(localContext);
            return newMap;
          });
        }
      });
    });
  };

  const getData = (type: SchemaType, key?: string) => {
    if (key || contentComponent) {
      const { SCHEMA } = CONTENT_COMPONENT_CONST[(key || contentComponent) as ContentComponentKeyType];
      const { field, regex, length, value, join, type: schemaType } = SCHEMA[type];

      if (field) {
        let newData = data.get(field);
        if (!newData) {
          return;
        }

        if (regex && length) {
          const newRegex = new RegExp(regex.string, regex.options);
          const regexMatch = newRegex.exec(newData);

          let i = 0;
          const res = [];
          while (i < length) {
            res.push(regexMatch?.groups?.[MAX_COORDINATES_KEY[i]]);
            i++;
          }
          newData = res;
        } else if (value) {
          const replacedData = newData.replace(value.replace('{DATA}', ''), '');
          newData = join ? replacedData.split(join) : join;
        }

        if (schemaType === 'arrayNumber') {
          newData = newData.map((item: string) => Number(item));
        }
        return newData;
      }
      return false;
    }
  };

  const isDirty = (key?: string) => {
    return (key ? [key] : Object.keys(CONTENT_COMPONENT_CONST))
      .map((key) => {
        const { SCHEMA } = CONTENT_COMPONENT_CONST[key as ContentComponentKeyType];
        return Object.keys(SCHEMA)
          .map((type) => {
            try {
              const { field } = SCHEMA[type];
              let localData = parseToBoolIfNeeded(data.get(field));
              let imageData = publishedId && parseToBoolIfNeeded(image?.context?.[`${field}_${publishedId}`]);
              if (
                field === 'published_label' &&
                (((imageData === null || imageData == undefined) && localData === DEFAULT_SLUG) || localData === '')
              ) {
                return false;
              }

              if (imageData === undefined || imageData === null) imageData = false;
              if (localData === undefined || localData === null) localData = false;

              return localData !== imageData;
            } catch (e) {
              console.error('Error checking dirty state: ', e);
              return false;
            }
          })
          .some(Boolean);
      })
      .some(Boolean);
  };

  const publishData = () => {
    return new Promise((resolve, reject) => {
      const encodedPublicId = encodeURIComponent(image.public_id);
      const metadataObj: any = {};

      data.forEach((value: any, key: any) => {
        metadataObj[`${key}_${publishedId}`] = value;
      });

      return authFetch(`/api/:property/${encodedPublicId}/update/${publishedId}`, {
        method: 'POST',
        body: JSON.stringify(metadataObj),
      })
        .then((data: any) => {
          setImage(data);
          setContentComponent(false);
          resolve(data);
        })
        .catch((err: Error) => {
          setContentComponent(false);
          console.error('Error updating image: ', err);
          reject(err);
        });
    });
  };

  const getImageSrcUrl = () => {
    let cropCoordinates = getData('coordinates', 'CROP');

    if (!cropCoordinates) {
      return;
    }

    const [cropX, cropY, cropWidth, cropHeight] = cropCoordinates;

    return image.secure_url.replace(
      `v${image.version}`,
      `c_crop,x_${cropX},y_${cropY},w_${cropWidth},h_${cropHeight}/`,
    );
  };

  return (
    <EditImageContext.Provider
      value={{
        image,
        publishedId,
        CONTENT_COMPONENT_CONST,
        contentComponent,
        setContentComponent,
        getData,
        setData,
        isDirty,
        ARLock,
        setARLock,
        publishData,
        resetData,
        getCoordinateOffsets: (coordinates: number[]) =>
          getCoordinateOffsets(coordinates, image, property, publishedId as string),
        previewType,
        setPreviewType,
        imageSrcUrl: getImageSrcUrl(),
        openFocalComponent,
        setOpenFocalComponent,
        isSaving,
        setIsSaving,
        isSavingWait,
        setIsSavingWait,
      }}
    >
      {children}
    </EditImageContext.Provider>
  );
};

export const useEditImage = () => {
  const context = useContext(EditImageContext);

  if (context === undefined) {
    throw new Error('useEditImage must be used within a AlertProvider');
  }

  return context;
};

export default useEditImage;
