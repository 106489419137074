import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  fallback: ReactNode;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
    const property = localStorage.getItem('currentProperty:searchOptions');
    let propertySearchOptions = {};
    if (property) {
      propertySearchOptions = JSON.parse(localStorage.getItem(`${property}:searchOptions`) || '{}');
    }

    const isFrame = window.self !== window.top;
    const pageOptions = {
      isFrame,
      url: isFrame ? document.referrer : document.location.href,
    };

    const headers = { 'Content-Type': 'application/json' };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        title: 'APP CRASH',
        error,
        errorInfo,
        pageOptions,
        oktaTokenStorage,
        property,
        propertySearchOptions,
      }),
      headers,
    };

    const postError = new Request('/api/error', requestOptions);
    const response = await fetch(postError);

    console.error('Uncaught error:', response.status, error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
