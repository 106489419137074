import React, { memo, useState } from 'react';
import { IconError, Loader, Typography, Group } from '@screentone/core';
import styles from './Image.module.css';

type ImageProps = {
  /** url of image */
  src: string;
  /** alt text for image */
  alt?: string;
  /** size of icons for error */
  width?: number;
  /** to pass to next/image */
  height?: number;
  /** CSS classes if any */
  className?: string;
  /** spread props, to be passed to next/Image component */
  [x: string]: any;
};

/**
 * Component to add loading and error UX
 * Wraps next/image and passes most props down to it
 * While showing the Loader component while iamge is loading
 * and Error componen while image has an error
 * It has only been tested with the use cases we need, so it is not
 * a generic replacement for next/image!!!!!!!!!!
 */

const Image = ({ src, alt, width, height, className, ar, errorMessage = true, ...otherProps }: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [imageErrorRetryCount, setImageErrorRetryCount] = useState(0);

  const loaderStyle: { aspectRatio?: number; backgroundColor?: string; inset: 0; position: 'absolute' } = {
    inset: 0,
    position: 'absolute',
  };
  if (width && height) loaderStyle.aspectRatio = width / height;
  const imageClassNames = [styles.image, styles.transparent_bg, className].join(' ');

  return (
    <>
      {!isError && (
        <img
          data-testid="image-preview"
          src={src}
          alt={alt || ''}
          onError={(e) => {
            if (imageErrorRetryCount < 3) {
              console.warn('imageErrorRetryCount: ', imageErrorRetryCount);
              setImageErrorRetryCount(imageErrorRetryCount + 1);
              e.currentTarget.src = src;
            } else {
              setImageErrorRetryCount(0);
              setIsLoaded(true);
            }
          }}
          onLoad={(e) => {
            setImageErrorRetryCount(0);
            setIsLoaded(true);
            setIsError(false);
          }}
          className={!isLoaded ? [styles.transparent, imageClassNames].join(' ') : imageClassNames}
          // width={width}
          // height={height}
          style={{
            maxWidth: width ? `${width}px` : '100%',
            maxHeight: height ? `${height}px` : '100%',
            aspectRatio: ar ? ar : undefined,
          }}
          {...otherProps}
        />
      )}
      {!isLoaded && (
        <div
          className={[styles.wrapper, styles['wrapper--loader'], styles[`wrapper--fill`]].join(' ')}
          style={loaderStyle}
        >
          <Loader size="md" />
        </div>
      )}
      {isError && (
        <div
          className={[styles.wrapper, styles['wrapper--error'], styles[`wrapper--fill`]].join(' ')}
          style={loaderStyle}
        >
          <Group direction="column" align="center" gap="sm" margin={{ all: 'sm' }}>
            <IconError size="md" color="lava" />
            {errorMessage && (
              <Typography color="lava" size="sm" align="center" className={styles.breakword}>
                <strong>Error Loading Image:</strong> {src}
              </Typography>
            )}
          </Group>
        </div>
      )}
    </>
  );
};
export default memo(Image);
