import React from 'react';
import { Group, IconArticle, IconDisabled, IconMinimize, Token, Tooltip, Typography } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

import useAlert from '../../hooks/useAppAlerts';
import useConfig from '../../hooks/useConfig';
import AddKeywords from './AddKeyword';
import { getPlaced, getResizeOnlyFlag, getTagIcons, getAvailablePropertiesLabels } from '../../utils/helpers';

import type { ImageType } from '../../types';

type ImageTokenListProps = {
  /** image */
  image: ImageType;
  /** mode */
  mode?: 'upload' | 'details';
  setImage?: (image: ImageType) => void;
  hideLabels?: boolean;
};

const ImageTokenList = ({ image, mode, setImage, hideLabels = false }: ImageTokenListProps) => {
  const { userAccess } = useAuth();
  const { setAlert } = useAlert();
  const {
    authFetch,
    session: { property, RELATED_PROPERTIES, SEARCH },
  } = useConfig();

  const placed = getPlaced(image, property, RELATED_PROPERTIES);
  const oneTimeUse = image.tags?.includes('one_time_use') || false;
  const isResizeOnly = getResizeOnlyFlag(image, property);
  const { tags } = SEARCH?.OPTIONS || {};
  const ICONS = getTagIcons();

  return (
    <Group gap="sm">
      {isResizeOnly && (
        <Token
          icon={IconMinimize}
          data-testid="resize-only-token-tag"
          color="lava"
          aria-label="Resize Only"
          title="Resize Only can not be cropped"
        >
          RESIZE ONLY
        </Token>
      )}
      {oneTimeUse && (
        <Token
          icon={IconDisabled}
          data-testid="onetime-use-token"
          color="lava"
          aria-label="One Time Use"
          title="This image has been fagged as One Time Use and can not be used again"
        >
          One Time Use
        </Token>
      )}
      {!hideLabels &&
        tags &&
        Object.keys(tags).map((tagKey) => {
          const tag = tags[tagKey];
          if (image.tags?.includes(tag.key)) {
            return (
              <Token
                color={tag.color}
                aria-label={tag.label}
                title={tag.title}
                icon={ICONS[tag.icon as keyof typeof ICONS]}
                // TODO: Add delete tag functionality
                //  -- This out of scope for this task but would be a good feature to add
                onDismiss={
                  userAccess('edit.wires') && tag.editable
                    ? () => {
                        const encodedPublicId = encodeURIComponent(image.public_id);
                        authFetch(`/api/:property/${encodedPublicId}/tags`, {
                          method: 'DELETE',
                          body: JSON.stringify({
                            tags: [tag.key],
                          }),
                        })
                          .then((image: any) => {
                            setImage && setImage(image);
                          })
                          .catch((err: Error) => {
                            console.error('handleDeleteTags Error: ', err);
                            setAlert(err.message || 'Error removing keywords', { type: 'error' });
                          });
                      }
                    : undefined
                }
              >
                {tag.label}
              </Token>
            );
          }
          return null;
        })}

      {placed &&
        (RELATED_PROPERTIES.length > 0 ? (
          <Tooltip>
            <Tooltip.Content position="bottom" id="tooltipContent">
              <Typography weight="bold" size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                Used By
              </Typography>
              {Object.keys(placed).map((item: string) => {
                const [value] = item.split(':');
                const PROPERTY_LABELS = getAvailablePropertiesLabels();
                return (
                  <Typography key={item} size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                    {PROPERTY_LABELS[value as keyof typeof PROPERTY_LABELS] || value}
                  </Typography>
                );
              })}
            </Tooltip.Content>
            <Tooltip.Trigger aria-describedby="tooltipContent" style={{ cursor: 'pointer' }}>
              <Token icon={IconArticle} color="gray" aria-label="Used in Article">
                In Use
              </Token>
            </Tooltip.Trigger>
          </Tooltip>
        ) : (
          <Token icon={IconArticle} color="gray" aria-label="Used in Article" title="Used in Article">
            In Use
          </Token>
        ))}
      {!hideLabels && mode !== 'upload' && setImage && <AddKeywords image={image} setImage={setImage} />}
    </Group>
  );
};

export default ImageTokenList;
