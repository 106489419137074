import React, { useEffect } from 'react';
import { Box, Button, Divider, Group, IconCr, IconEye, useModalState } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { useNavigate, useParams } from 'react-router-dom';

import { handleDelete, handleMetadataUpdates } from './Panels.handles';
import AiCaptioning from '../AiCaptioning';
import DeleteImage from '../Delete';

import MetadataDescription from '../MetadataDescription';
import EditImagePanels from '../../EditImage/Panels';

import InnerBrowserModel from '../../InnerBrowserModel';
import DeveloperButtons from '../../ImageDetails/DeveloperButtons';
import MetadataModal from '../../Metadata';

import EditMetadataSidebar from '../../EditMetadataSidebar';
import EditWireMetadataSidebar from '../../EditWireMetadataSidebar';

import useAlert from '../../../hooks/useAppAlerts';
import useConfig from '../../../hooks/useConfig';
import useEditImage from '../../../hooks/useEditImage';
import useImageDetail from '../../../hooks/useImageDetail';
import useSearch from '../../../hooks/useSearch';

import { constants } from '../../../utils';

import type { PropertyType, ImageType } from '../../../types';

const Panels = () => {
  const { user, userAccess } = useAuth();
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const { image, publishedId, setImage: setDetailsImage } = useImageDetail();
  const { setContentComponent, openFocalComponent, CONTENT_COMPONENT_CONST, previewType } = useEditImage();

  const { setImage: setSearchImage, triggerRefresh } = useSearch();
  const { mode: pageMode = constants.IMAGE_PAGE_MODE.DETAILS, id: assetId } = useParams();
  const navigate = useNavigate();

  const { setAlert } = useAlert();

  const { open: isMetadataOpen, openModal: openMetadataModal, closeModal: closeMetadataModal } = useModalState();

  const setImage = (image: ImageType) => {
    setDetailsImage(image);
    setSearchImage(image);
  };

  useEffect(() => {
    setContentComponent(false);
  }, [pageMode]);

  useEffect(() => {
    if (openFocalComponent) {
      setContentComponent(CONTENT_COMPONENT_CONST.FOCAL_AREA.KEY);
    }
  }, [openFocalComponent]);

  const isWireImage = image?.metadata.import_source_type === 'wires';
  // const isDeveloper = userAccess(['developer', 'app_admin']);
  const isAdmin = userAccess('app_admin');
  const isDeveloper = isAdmin || userAccess('developer');
  const uploadedByCurrentUser = user?.dj_user_id.toLowerCase() === image?.metadata?.dj_user_id;

  const canDelete =
    (userAccess(`${property}.delete`) || uploadedByCurrentUser) && !isWireImage && !(image.isChart || image.isDynamic);

  const canSeeAiCaptioning = userAccess(`${property}.ai_captioning`) || isDeveloper;

  const MetadataSidebar = isWireImage ? EditWireMetadataSidebar : EditMetadataSidebar;

  return (
    <>
      <Box padding={{ top: 'xs' }} attached="bottom">
        {pageMode === constants.IMAGE_PAGE_MODE.DETAILS && (
          <Group direction="column" margin={{ all: 'md' }} fullHeight gap="md">
            <MetadataDescription
              image={image}
              direction="column"
              setImage={setImage}
              previewType={previewType}
              property={property}
              publishedId={publishedId}
            />
            {canSeeAiCaptioning && <AiCaptioning setImage={setImage} />}
            {/* Content Credentials reveal helpful information that's usually hidden. */}
            <Group direction="column" fullWidth fullHeight gap="xs">
              {image.image_metadata?.Provenance && (
                <InnerBrowserModel
                  trigger="Content Credential"
                  icon={IconCr}
                  href={`https://contentcredentials.org/verify?source=${image.secure_url}`}
                />
              )}

              <Button
                tertiary
                icon={IconEye}
                onClick={openMetadataModal}
                // disabled={disabled}
                data-testid="view-all-data"
              >
                View All Data
              </Button>
            </Group>
          </Group>
        )}

        {pageMode === constants.IMAGE_PAGE_MODE.EDIT && <EditImagePanels />}

        {pageMode === constants.IMAGE_PAGE_MODE.METADATA && (
          <>
            <MetadataSidebar
              onApplyMetadata={async (public_id, metadataObj, currentPublishedId) => {
                await handleMetadataUpdates(authFetch, public_id, metadataObj, currentPublishedId)
                  .then((data) => {
                    setImage(data as ImageType);
                    setAlert('Metadata has been updated.', { type: 'success' });
                    if (image.isDynamic) {
                      navigate(`/${property}/image/${assetId}/${constants.IMAGE_PAGE_MODE.DETAILS}/${publishedId}`);
                    } else {
                      navigate(`/${property}/image/${assetId}`);
                    }
                  })
                  .catch(() => {
                    setAlert("Error updating image's metadata, try again.", { type: 'error' });
                  });
              }}
              onCancel={() => {}}
            />

            <Group gap="sm" direction="column" margin={{ all: 'md' }}>
              <Divider flexItem />

              <DeleteImage
                data-testid="delete-img-btn"
                imageId={assetId as string}
                onDelete={() =>
                  handleDelete(authFetch, image.public_id)
                    .then(() => {
                      setAlert('Image has been successfully deleted.', { type: 'success' });
                      triggerRefresh();
                      navigate(`/${property}`);
                    })
                    .catch(() => {
                      setAlert(`There was an Error deleting this image, please try again.`, { type: 'error' });
                    })
                }
                isDisabled={!canDelete}
              />
            </Group>
          </>
        )}
      </Box>
      {isDeveloper && <DeveloperButtons />}
      {isMetadataOpen && (
        <MetadataModal onDismiss={closeMetadataModal} isAdmin={isAdmin} property={property as PropertyType} />
      )}
    </>
  );
};

export default Panels;
