import React, { useEffect, useState } from 'react';
import { IconSearch, Input } from '@screentone/core';

import type { ActivityUserType, CldUserState } from '../../../types';

import styles from './UserFilter.module.css';

interface UserFilterInputProps {
  updateUserState: (setCldUsers: any) => void | React.Dispatch<React.SetStateAction<CldUserState>>;
  userState: CldUserState;
}

function filterUsersByName(userState: CldUserState, inputUserName: string) {
  const { filteredUsers, userList, selectedUser } = userState;
  const lowerCasedInput = inputUserName.toLowerCase();

  const filterFunction = (user: ActivityUserType) =>
    user &&
    (user.given_name?.toLowerCase().includes(lowerCasedInput) ||
      user.family_name?.toLowerCase().includes(lowerCasedInput) ||
      user.display_name?.toLowerCase().includes(lowerCasedInput));

  const filteredUsersByName = inputUserName
    ? filteredUsers.filter(filterFunction)
    : userList.filter((user) => user.dj_user_id !== selectedUser?.dj_user_id);

  return filteredUsersByName.length > 0 ? filteredUsersByName : userList.filter(filterFunction);
}

export default function UserFilterInput({ updateUserState, userState }: UserFilterInputProps) {
  const [inputUserName, setInputUserName] = useState('');

  useEffect(() => {
    const usersFilteredByName = filterUsersByName(userState, inputUserName);
    updateUserState((prevState: any) => ({
      ...prevState,
      filteredUsers: usersFilteredByName,
    }));
  }, [inputUserName]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputUserName(e.target.value);
  };

  return (
    <div className={styles.inputWrapper}>
      <Input
        icon={IconSearch}
        onChange={handleInputChange}
        data-testid="user-filter-input"
        placeholder="Search users"
        value={inputUserName}
      />
    </div>
  );
}
