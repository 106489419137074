import { format } from '@screentone/addon-calendar';

import {
  Input,
  FormLabel,
  Select,
  IconPlusCircle,
  Group,
  Textarea,
  Button,
  Wrapper,
  Box,
  IconTrash,
  Typography,
  Grid,
  FormHelperText,
} from '@screentone/core';
import React, { useEffect } from 'react';

import useConfig from '../../../../hooks/useConfig';
import useUpload from '../../../../hooks/useUpload';

import PaddingColor from '../../../PaddingColor/PaddingColor.component';

import { constants, helpers } from '../../../../utils';
import { requiredFieldsForPublisher } from '../../../../utils/helpers';

import type { GraphicType } from '../../../../types';

const IMAGE_WIDTH = 340;

type UploadFormProps = {
  /** function supplied by react-dropzone to handle opening the file system */
  handleBrowseFiles: React.MouseEventHandler<HTMLElement> | undefined;
};

const UploadDynamicForm = ({ handleBrowseFiles }: UploadFormProps) => {
  const { assetsToUpload, bulk, type, bulkUpdate, assetMetadataUpdate, removeAsset, selectForBulkUpdate } = useUpload();
  // as the bulk object does not have the validation object, we need to get it from the first asset
  const validation = assetsToUpload[0]?.validation;

  // console.groupCollapsed('GROUP UploadDynamicForm Data');
  // console.log('bulk.metadata: ', JSON.stringify(bulk.metadata, null, 2));
  // console.log('validation: ', JSON.stringify(validation, null, 2));
  // console.log('assetsToUpload: ', JSON.stringify(assetsToUpload, null, 2));
  // console.groupEnd();

  const {
    session: { property, SEARCH },
  } = useConfig();

  const requiredFields = requiredFieldsForPublisher(property, SEARCH?.OPTIONS?.FORM_VALIDATION);

  const handleChange = (data: any = {}) => {
    bulk.selectedAssetsIndexes.forEach((index) => {
      assetMetadataUpdate({ index, metadata: { [data.key]: data.value }, validate: index === 0 ? true : false });
    });
    bulkUpdate({ metadata: { [data.key]: data.value } });
  };
  useEffect(() => {
    assetsToUpload.forEach((_, i) => {
      selectForBulkUpdate({ isSelected: true, index: i }); // this will pre-populate the metadata for all fields with the same values
    });
    handleChange({ key: 'datePhotographed', value: format(new Date(), constants.DATE_FORMATS.CLOUDINARY) });
  }, []);

  return (
    <Wrapper data-testid="metadata-card" margin={{ bottom: 'md', top: 'md' }}>
      <Typography variant="header3">Promo Image Upload</Typography>
      <Box padding={{ all: 'md' }}>
        <Group fullWidth direction="column">
          <FormLabel fullWidth label="Headline" required={requiredFields.headline}>
            <Input
              placeholder={`Headline ${requiredFields.headline ? 'Required' : ''}`}
              data-testid="headline-input"
              onBlur={({ target }: { target: HTMLInputElement }) => {
                handleChange({ key: 'headline', value: target.value.trimEnd() });
              }}
              onChange={({ target }: { target: HTMLInputElement }) =>
                handleChange({ key: 'headline', value: target.value.trimStart() })
              }
              value={bulk.metadata.headline}
              error={validation?.headline}
            />
            {validation?.headline && <FormHelperText error>{validation?.headline}</FormHelperText>}
          </FormLabel>

          <FormLabel fullWidth label="Caption" required={requiredFields.caption}>
            <Textarea
              placeholder={`Caption ${requiredFields.caption ? 'Required' : ''}`}
              data-testid="caption-input"
              onBlur={({ target }: { target: HTMLInputElement }) => {
                handleChange({ key: 'caption', value: target.value.trimEnd() });
              }}
              onChange={({ target }: { target: HTMLInputElement }) =>
                handleChange({ key: 'caption', value: target.value.trimStart() })
              }
              value={bulk.metadata.caption}
              error={validation?.caption}
            />
            {validation?.caption && <FormHelperText error>{validation?.caption}</FormHelperText>}
          </FormLabel>
          <Group.Item fullWidth>
            <Grid>
              <Grid.Row>
                <Grid.Col xs={12} sm={6}>
                  <FormLabel fullWidth label="Credit" required={requiredFields.credit}>
                    <Input
                      placeholder={`Credit ${requiredFields.credit ? 'Required' : ''}`}
                      data-testid="credit-input"
                      onBlur={({ target }: { target: HTMLInputElement }) => {
                        handleChange({ key: 'credit', value: target.value.trimEnd() });
                      }}
                      onChange={({ target }: { target: HTMLInputElement }) =>
                        handleChange({ key: 'credit', value: target.value.trimStart() })
                      }
                      value={bulk.metadata.credit}
                      error={validation?.credit}
                    />
                    {validation?.credit && <FormHelperText error>{validation?.credit}</FormHelperText>}
                  </FormLabel>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                  <FormLabel fullWidth label="Contact" required={requiredFields.contact}>
                    <Input
                      placeholder={`Contact ${requiredFields.contact ? 'Required' : ''}`}
                      data-testid="contact-input"
                      onBlur={({ target }: { target: HTMLInputElement }) => {
                        handleChange({ key: 'contact', value: target.value.trimEnd() });
                      }}
                      onChange={({ target }: { target: HTMLInputElement }) =>
                        handleChange({ key: 'contact', value: target.value.trimStart() })
                      }
                      value={bulk.metadata.contact}
                      error={validation?.contact}
                    />
                    {validation?.contact && <FormHelperText error>{validation?.contact}</FormHelperText>}
                  </FormLabel>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row bottom>
                <Grid.Col xs={12} sm={6}>
                  <FormLabel fullWidth label="Type" margin={{ top: 'md' }}>
                    <Select
                      data-testid="type-select"
                      name="Type"
                      onChange={({ target }: { target: HTMLInputElement }) =>
                        handleChange({ key: 'graphicType', value: target.value as GraphicType })
                      }
                      value={bulk.metadata.graphicType || 'Illustration'}
                    >
                      {constants.GRAPHIC_TYPES.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Select>
                  </FormLabel>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                  <PaddingColor
                    margin={{ top: 'md' }}
                    background={bulk.metadata.background}
                    onChange={(target: any) => {
                      handleChange({ key: 'background', value: target.background.trimStart() });
                    }}
                    position="right"
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Group.Item>
        </Group>
      </Box>
      <Grid>
        <Grid.Row>
          {assetsToUpload.map((asset, i) => {
            const xScale = asset.metadata?.xScale || 0;
            return (
              <Grid.Col key={asset.file.name} xs={12} sm={6} md={4} lg={3}>
                <Box data-testid="promo-card" attached="top" margin={{ top: 'md' }}>
                  <Box.Title padding={{ vertical: 'sm', horizontal: 'smd' }}>
                    <Group fullWidth gap="xs">
                      <Group.Item data-testid="promo-image-file-name">{asset.file.name.split('.')[0]}</Group.Item>
                      <Group.Item flex style={{ textAlign: 'right' }}>
                        <Button
                          data-testid="promo-image-delete-button"
                          tertiary
                          icon={IconTrash}
                          onClick={() => {
                            removeAsset({ index: i });
                          }}
                        />
                      </Group.Item>
                    </Group>
                  </Box.Title>
                  <Box.Content>
                    <picture>
                      <img
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                        alt={asset.file.name}
                        src={asset.renderData.secure_url}
                        width={IMAGE_WIDTH}
                      />
                    </picture>
                  </Box.Content>
                  <Box fullWidth>
                    <Typography align="right" size="sm" margin={{ vertical: 'sm', horizontal: 'smd' }}>
                      {helpers.formatBytes(asset.file.size)}
                      {' • '}
                      {asset.renderData.width / xScale} x {asset.renderData.height / xScale} px
                      {xScale > 1 ? ` @${xScale}x` : ''}
                      {' • '}
                      {asset.file.type.replace('image/', '').toUpperCase().replace('+XML', '')}
                    </Typography>
                  </Box>
                </Box>
              </Grid.Col>
            );
          })}
        </Grid.Row>
      </Grid>
      <Group direction="column" gap="md" margin={{ top: 'md' }}>
        <Typography variant="note" margin={{ horizontal: 'md' }}>
          Required fields are marked with an asterisk{' '}
          <Typography inline variant="note" color="lava">
            *
          </Typography>
        </Typography>
        <Typography data-testid="max-img-text" variant="note" margin={{ horizontal: 'md' }}>
          Maximum of {constants.MAX_FILES_IN_UPLOADER[type]} images can be uploaded at a one time. (
          {assetsToUpload.length} / {constants.MAX_FILES_IN_UPLOADER[type]})
        </Typography>
      </Group>
      <Group fullWidth align="end">
        <Button
          data-testid="promo-images-upload-more-images-button"
          type="button"
          icon={IconPlusCircle}
          secondary
          margin={{ top: 'md', bottom: 'lg' }}
          onClick={handleBrowseFiles}
          disabled={assetsToUpload.length === constants.MAX_FILES_IN_UPLOADER[type]}
        >
          Upload More Images
        </Button>
      </Group>
    </Wrapper>
  );
};

export default UploadDynamicForm;
