import React, { useCallback, useState } from 'react';
import {
  Sidebar,
  Button,
  Group,
  IconMinusCircle,
  Typography,
  IconCaretRight,
  IconLoader,
  IconPlus,
  IconWarning,
  Alert,
  IconLightbox,
  Token,
  IconTrash,
} from '@screentone/core';

import useAlert from '../../hooks/useAppAlerts';
import useAssetManager, { LightboxObjType } from '../../hooks/useAssetManager';
import useConfig from '../../hooks/useConfig';
import useSearch from '../../hooks/useSearch';

import Image from '../Image';

import { constants, iframe } from '../../utils';
import { getImageAspectRatio, getImageUrl, getLastPublished, getPublishedLabel } from '../../utils/helpers';

import type { ImageType } from '../../types';

import styles from './Lightbox.module.css';

type LightboxProps = {
  /** current status of sidebar (open/collapsed) */
  status: 'open' | 'opening' | 'collapsed' | 'collapsing';
  /** callback function when we need to open Sidebar, usually provided by `useSidebar` hook */
  openSidebar: () => void;
  /** callback function when we need to collapse Sidebar, usually provided by `useSidebar` hook */
  collapseSidebar: () => void;
  /** props to pass down to Sidebar component */
  sidebarProps?: any;
};

function Lightbox({ status, openSidebar, collapseSidebar, sidebarProps = {} }: LightboxProps) {
  const {
    authFetch,
    session: { property, IMAGE_DOMAINS },
  } = useConfig();
  const {
    setImage,
    addAssetToLightbox,
    getAllAssetsFromLightbox,
    removeAssetFromLightbox,
    removeAllAssetsFromLightbox,
  } = useAssetManager();
  const { setImage: setSearchImage } = useSearch();
  const { setAlert } = useAlert();
  const [isAdding, setIsAdding] = useState(false);

  const lightboxImages = getAllAssetsFromLightbox();

  const toggleSidebar = useCallback(() => {
    if (status === 'open') collapseSidebar();
    else openSidebar();
  }, [openSidebar, collapseSidebar, status]);

  const onAddToSourceHandler = async (assets: LightboxObjType[]) => {
    try {
      const publishedImages: Map<string, LightboxObjType> = new Map();
      const unpublishedImages: ImageType[] = [];

      // split assets into published and unpublished
      assets.forEach((asset: LightboxObjType) => {
        if (asset.publishedId) {
          publishedImages.set(asset.assetId, { ...asset });
        } else {
          unpublishedImages.push(asset.image);
        }
      });

      // auto-publish unpublished images
      const newlyPublishedImages = await Promise.all(
        unpublishedImages.map(
          (image): Promise<LightboxObjType> =>
            authFetch(`/api/:property/${encodeURIComponent(image.public_id)}/publish`, {
              method: 'POST',
            })
              .then((newImage) => {
                const publishedId = getLastPublished(newImage, property) || undefined;
                const slug = (publishedId && getPublishedLabel(newImage, publishedId, property)) || undefined;
                setImage(newImage);
                addAssetToLightbox(newImage.asset_id, publishedId);
                setSearchImage(newImage);

                return {
                  assetId: newImage.asset_id,
                  publishedId,
                  image: newImage,
                  slug,
                };
              })
              .catch((error: any) => {
                console.error('error: ', error);
                throw new Error(error.message || 'Error publishing image');
              }),
        ),
      );

      // add the newly published images to the publishedImages map
      await newlyPublishedImages.forEach((newImage) => {
        publishedImages.set(newImage.publishedId as string, newImage);
      });

      // send the published images to the source
      iframe.sendToSource({
        image: Array.from(publishedImages.values()).map((image) => image.image),
        property,
        authFetch,
        ids: Array.from(publishedImages.keys()),
      });
      removeAllAssetsFromLightbox();
      setIsAdding(false);
    } catch (error: any) {
      console.error('publishImage error: ', error);
      setAlert(error.message || 'Error publishing image', { type: 'error' });
      setIsAdding(false);
    }
  };

  return (
    <Sidebar {...sidebarProps} position="right" data-testid="lightbox-panel">
      {(status === 'open' || status === 'opening') && (
        <>
          <Sidebar.Header padding={{ all: 'none' }}>
            <Button
              color="gray"
              tertiary
              onClick={toggleSidebar}
              aria-label="open Lightbox"
              margin={{ all: 'none' }}
              fullWidth
              fullHeight
              style={{ height: 'var(--st-spacer-xl)' }}
            >
              <Group direction="row" gap="sm" align="space-between" fullWidth>
                <Group gap="sm" justify="center">
                  <IconLightbox margin={{ left: 'sm' }} /> Lightbox
                  <Token color="blurple">{lightboxImages?.length}</Token>
                </Group>
                <IconCaretRight size="mlg" color="asphalt" />
              </Group>
            </Button>
          </Sidebar.Header>
          <Sidebar.Section padding={{ vertical: 'smd', horizontal: 'md' }}>
            <div style={{ height: '100%' }}>
              <Group direction="column" gap="md" valign="start" data-testid="lightbox-img-group">
                {lightboxImages?.map((asset) => {
                  return (
                    <Group
                      className={styles.image}
                      key={asset.assetId}
                      gap="xs"
                      style={{
                        aspectRatio: getImageAspectRatio(asset.image, property, asset.publishedId),
                      }}
                    >
                      <Group.Item fullWidth>
                        <Image
                          src={getImageUrl({
                            publishedId: asset.publishedId,
                            image: asset.image,
                            property,
                            defaultPreviewUrl: true,
                            IMAGE_DOMAINS,
                          })}
                          alt={asset.image?.metadata?.headline || 'Image without caption'}
                        />
                      </Group.Item>
                      {asset.publishedId ? (
                        <>
                          <Group.Item fullWidth>
                            <Typography weight="med">{asset.slug || constants.DEFAULT_SLUG}</Typography>
                          </Group.Item>
                          <Group gap="xs" align="space-between" fullWidth>
                            <Token>{asset.publishedId}</Token>
                            <Button
                              data-testid="remove-btn"
                              tertiary
                              color="lava"
                              icon={IconTrash}
                              disabled={isAdding}
                              onClick={() => removeAssetFromLightbox(asset.assetId)}
                            />
                          </Group>
                        </>
                      ) : (
                        <Group gap="xs" align="space-between" fullWidth>
                          <Alert inline type="warning" icon={IconWarning}>
                            Unpublished
                            {/* TODO: as part of a future update we will allow users to publish an image from here */}
                            {/* {' - '}
                            <Alert.Action componentEl={Link} to={`/${property}/batch/${asset.assetId}/edit/new`}
                              disabled={isAdding}
                            >
                              Publish
                            </Alert.Action> */}
                          </Alert>
                          <Button
                            data-testid="remove-btn"
                            disabled={isAdding}
                            tertiary
                            color="lava"
                            icon={IconTrash}
                            onClick={() => removeAssetFromLightbox(asset.assetId)}
                          />
                        </Group>
                      )}
                    </Group>
                  );
                })}
              </Group>
            </div>
          </Sidebar.Section>
          <Sidebar.Footer padding={{ top: 'md', horizontal: 'md', bottom: 'lg' }}>
            <Group direction="column" align="center" gap="sm">
              {iframe.sendImageArray() && (
                <Button
                  data-testid="addToArticle-btn"
                  icon={isAdding ? IconLoader : IconPlus}
                  primary
                  fullWidth
                  onClick={async () => {
                    setIsAdding(true);
                    await onAddToSourceHandler(lightboxImages);
                  }}
                  disabled={isAdding || !lightboxImages || lightboxImages.length === 0}
                >
                  {iframe.getSendToSourceLabel(true)}
                </Button>
              )}

              <Button
                data-testid="removeAll-btn"
                tertiary
                icon={IconMinusCircle}
                onClick={() => removeAllAssetsFromLightbox()}
                disabled={isAdding || !lightboxImages || lightboxImages.length === 0}
              >
                Remove all from list
              </Button>
            </Group>
          </Sidebar.Footer>
        </>
      )}
      {['collapsed', 'closed'].includes(status) && (
        <Sidebar.Header padding={{ all: 'none' }}>
          <Group gap="sm" align="center">
            <Button
              icon={IconLightbox}
              color="gray"
              tertiary
              onClick={toggleSidebar}
              aria-label="open Lightbox"
              margin={{ all: 'none' }}
              fullWidth
              fullHeight
              justified="center"
              style={{ height: 'var(--st-spacer-xl)' }}
            />
          </Group>
        </Sidebar.Header>
      )}
    </Sidebar>
  );
}

export default Lightbox;
