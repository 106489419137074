import React, { useEffect, useState } from 'react';
import {
  Divider,
  Button,
  Dropdown,
  useDropdownState,
  IconUser,
  IconCaretDown,
  IconUserCross,
  Textblock,
} from '@screentone/core';
import { LoadingPage, useAuth, User } from '@screentone/addon-auth-wrapper';
import { useLocation } from 'react-router-dom';
import {
  createDefaultOptions,
  getSessionUserInUsers,
  isSessionUserInUsers,
  displayIfNotCurrentUserOrMyUploads,
  filterUsers,
  getUserFromQueryParam,
  getSessionUserFromQueryParam,
} from './utils';

import { useSearch } from '../../../hooks/useSearch';
import useConfig from '../../../hooks/useConfig';
import UserFilterInput from './UserFilterInput';
import { ALL_USERS, ERRORS, MY_IMAGES } from '../../../utils/constants';

import type { ActivityUserType, CldUserState } from '../../../types';

import styles from './UserFilter.module.css';

const initialCldUsersState: CldUserState = {
  selectedUser: null,
  userList: [],
  filteredUsers: [],
};

function UserFilter() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userFromParams = searchParams.get('user');

  const { open: openUser, setOpen: setOpenUser, componentRef: userComponentRef } = useDropdownState();
  const { user: sessionUser } = useAuth();
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const { options, updateSearchOptions } = useSearch();
  const [cldUsers, setCldUsers] = useState<CldUserState>(initialCldUsersState);
  const { userList = [], selectedUser: storedUser, filteredUsers } = cldUsers;
  const [activeSelectedUser, setActiveSelectedUser] = useState(false);
  const { query, user } = options;

  useEffect(() => {
    authFetch(`/api/:property/users`)
      .then((fetchedUsers: any) => {
        setCldUsers((prevState) => ({
          ...prevState,
          userList: fetchedUsers,
          filteredUsers: fetchedUsers,
        }));
      })
      .catch((error: Error) => {
        console.error('Error fetching users:', error);
      });
  }, [property]);

  // reset selected user when user options.user is updated
  useEffect(() => {
    let userInLocal: ActivityUserType = {
      dj_user_id: user || '',
      display_name: '',
    };

    if (user && user !== (sessionUser && sessionUser.dj_user_id && sessionUser.dj_user_id.toLowerCase())) {
      userInLocal =
        typeof user === 'string' ? (userList.find((obj) => obj.dj_user_id === user) as ActivityUserType) : user;
    }

    setCldUsers((prevState) => ({
      ...prevState,
      selectedUser: userInLocal ? userInLocal : storedUser,
    }));
  }, [user]);

  const usersFilteredWithoutCurrentUser = filteredUsers.filter(
    (users) => users.dj_user_id?.toLowerCase() !== sessionUser?.dj_user_id?.toLowerCase(),
  );

  // todo when the cache clear on logout tickt is done  we can expect a string
  const selectedUser =
    typeof storedUser === 'string' ? userList.find((obj) => obj.dj_user_id === storedUser) : storedUser;

  let selectedUserDisplayName = (selectedUser && selectedUser.display_name) || ALL_USERS;

  if (selectedUser && selectedUser.family_name && selectedUser.given_name) {
    selectedUserDisplayName = `${selectedUser.family_name},  ${selectedUser.given_name}`;
  }

  if (
    selectedUser &&
    selectedUser.dj_user_id === (sessionUser && sessionUser.dj_user_id && sessionUser.dj_user_id.toLowerCase())
  ) {
    selectedUserDisplayName = MY_IMAGES;
  }
  useEffect(() => {
    const queryParamUser = Array.isArray(userFromParams)
      ? userFromParams[0]?.toLowerCase()
      : userFromParams?.toLowerCase();
    const userFromQueryParams = getUserFromQueryParam(
      userList,
      queryParamUser as unknown as string,
    ) as ActivityUserType;
    if (userFromQueryParams) {
      const sessionUserMatchUserFromQueryParam = getSessionUserFromQueryParam(
        userFromQueryParams,
        sessionUser?.dj_user_id,
      );
      if (sessionUserMatchUserFromQueryParam) {
        userFromQueryParams.display_name = MY_IMAGES;
      }

      const filteredUsersData = filterUsers(userList, userFromQueryParams, query as string, sessionUser as User);
      setCldUsers((prev) => ({ ...prev, selectedUser: userFromQueryParams, filteredUsers: filteredUsersData }));
      updateSearchOptions({ ...options, user: userFromQueryParams.dj_user_id }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  useEffect(() => {
    if (selectedUserDisplayName !== ALL_USERS && selectedUserDisplayName !== '') {
      setActiveSelectedUser(true);
    } else {
      setActiveSelectedUser(false);
    }
  }, [selectedUserDisplayName]);

  useEffect(() => {
    const storedUserInLocal = JSON.parse(localStorage.getItem(`${property}:searchOptions`) as string) as {
      user: ActivityUserType;
    };
    if (storedUserInLocal) {
      setCldUsers((prevUsersState) => ({
        ...prevUsersState,
        selectedUser: storedUserInLocal.user,
      }));
    }
  }, []);
  const getDefaultActive = (displayName: string) => {
    if (
      (selectedUser && selectedUser.display_name && selectedUser.display_name === displayName) ||
      (displayName === ALL_USERS && selectedUser === null) ||
      (displayName === ALL_USERS && selectedUser?.dj_user_id === '')
    ) {
      return true;
    }
    return false;
  };
  const onClickUserOption = (currentUser: ActivityUserType) => {
    setOpenUser(!openUser);
    setCldUsers((prevUsersState) => ({ ...prevUsersState, selectedUser: currentUser }));

    if (MY_IMAGES === currentUser.display_name && sessionUser && isSessionUserInUsers(userList, sessionUser)) {
      const sessionUserInUsers = getSessionUserInUsers(userList, sessionUser);

      if (sessionUserInUsers) {
        updateSearchOptions({ ...options, user: currentUser.dj_user_id }, true);
      }
    } else if (ALL_USERS !== currentUser.dj_user_id) {
      updateSearchOptions({ ...options, user: currentUser.dj_user_id }, true);
    } else {
      updateSearchOptions({ ...options, user: null }, true);
    }
  };

  if (!sessionUser) {
    return <LoadingPage />;
  }
  const DEFAULT_OPTIONS = createDefaultOptions(userList, sessionUser);
  return (
    <Dropdown
      data-testid="user-filter-btn"
      open={openUser}
      padding={{ all: 'none' }}
      componentRef={userComponentRef}
      onToggle={() => setOpenUser(!openUser)}
    >
      <Dropdown.Trigger>
        <Button active={openUser || activeSelectedUser} tertiary icon={IconUser}>
          {selectedUserDisplayName}
          <IconCaretDown className={styles.search__caret__icon} />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <div className={styles.container}>
          <UserFilterInput updateUserState={setCldUsers} userState={cldUsers} />
          <div className={styles.user__filter__options}>
            <div className={styles.user__filter__options__header}>
              {DEFAULT_OPTIONS.map((defaultOption) => (
                <Button
                  data-testid="myuploads-allusrs-btn"
                  key={defaultOption.dj_user_id}
                  active={getDefaultActive(defaultOption.display_name)}
                  tertiary
                  fullWidth
                  onClick={() => onClickUserOption(defaultOption)}
                >
                  {defaultOption.display_name}
                </Button>
              ))}
              {displayIfNotCurrentUserOrMyUploads(selectedUser as ActivityUserType, sessionUser) && (
                <Button active tertiary fullWidth>
                  {selectedUser && selectedUser.family_name
                    ? `${selectedUser.family_name},  ${selectedUser.given_name}`
                    : selectedUser && selectedUser.display_name}
                </Button>
              )}
            </div>
            <Divider margin={{ vertical: 'sm' }} />
            <div className={styles.user__filter__options__body}>
              {usersFilteredWithoutCurrentUser.map((currentUser) => (
                <Button
                  data-testid="user-filter-users"
                  key={currentUser.dj_user_id}
                  active={selectedUser && selectedUser.email === currentUser.email}
                  tertiary
                  fullWidth
                  onClick={() => onClickUserOption(currentUser)}
                >
                  {currentUser.family_name
                    ? `${currentUser.family_name},  ${currentUser.given_name}`
                    : currentUser.display_name}
                </Button>
              ))}
              {!filteredUsers.length && (
                <div className={styles.errorContainer}>
                  <IconUserCross size="lg" className={styles.errorIcon} />
                  <Textblock data-testid="users-error-message">
                    <strong className={styles.errorText}>{ERRORS.USERS_NOT_FOUND}</strong>
                    <p className={styles.errorText}>Please try a different name.</p>
                  </Textblock>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dropdown.Content>
    </Dropdown>
  );
}

export default UserFilter;
