import { parse } from '@screentone/addon-calendar';
import config from './config';

export const removeEmptyKeys = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      }
    }
  }
  return obj;
};

const capitalizeFirst = (s: string) => s && String(s[0]).toUpperCase() + String(s).slice(1);

const validateMaxLen = (field: string, length: number, max?: number) => {
  if (max && length > max) {
    return `${capitalizeFirst(field)} exceeds maximum length (${length}/${max}).`;
  }
  return false;
};

export const firstLoadValidation = (field: string = '', value: string = '') => {
  const { max } = config[field];
  // the only check on load for now
  return validateMaxLen(field, value.length, max);
};

export const fieldValidation = ({
  field,
  value = '',
  isRequired,
}: {
  field: string;
  value: string;
  isRequired: boolean;
}) => {
  const { type } = config[field] || {};

  if (!value && isRequired) {
    return `${capitalizeFirst(field)} is required.`;
  }

  if (type === 'text') {
    const { max, min } = config[field];
    // errors
    if (min && value.length < min && isRequired) {
      return `${capitalizeFirst(field)} requires a minimum of (${min}) characters.`;
    }
    if (max && value.length > max) {
      return validateMaxLen(field, value.length, max);
    }
    return false;
  } else if (type === 'date' && isRequired) {
    const { format } = config[field];

    // state.datePhotographed may contain error text - check for that error
    const testDate = value?.length ? parse(value, format, new Date()) : new Date();

    // error - error has been passed instead of date
    if (Number.isNaN(testDate.getTime())) {
      return value as string;
    }

    return false;
  }
};

export default fieldValidation;
