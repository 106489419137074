import React, { LegacyRef, useRef } from 'react';
import { Alert, Button, Group, IconError, Typography, IconUpload } from '@screentone/core';
import uniq from 'lodash/uniq';

import AddToSourceButton from '../../Buttons/AddToSource';
import ImageCard from '../../ImageCard';

import useAssetManager from '../../../hooks/useAssetManager';
import useUpload from '../../../hooks/useUpload';
import useConfig from '../../../hooks/useConfig';

import { iframe } from '../../../utils';

import type { StatusType, ImageType, UploadImageFormats } from '../../../types';

import styles from './UploadStaging.module.css';

const UploadStaging = () => {
  const {
    session: { property },
  } = useConfig();
  const { assetsToUpload, type, resetUploadState, retryPublishAssets } = useUpload();
  const { getLastUploadedImages, getImage } = useAssetManager();
  const latestUploads = getLastUploadedImages();
  const images = latestUploads.map((assetId) => getImage(assetId));
  const numberOfUploads = assetsToUpload.length;
  const numberOfUploadsCompleted = assetsToUpload.filter((asset) => asset.state === 'complete').length;
  const additionalIframeActions = (image: ImageType) => {
    return iframe.isFrame() && <AddToSourceButton image={image} />;
  };

  // used to get the width of the page wrapper for the error alert container
  const pageWrapperRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const imagesToRender = type === 'dynamic' ? [assetsToUpload[0]] : assetsToUpload;
  // unique errors

  const errors = uniq(assetsToUpload)
    .filter((asset) => asset.state === 'rejected')
    .map((asset) => asset.error);

  return (
    <div className={`st_pagewrapper ${styles.pageWrapper}`} ref={pageWrapperRef}>
      {errors && (
        <div className={styles.errorContainer} style={{ width: pageWrapperRef.current?.clientWidth || 'inherit' }}>
          {errors.map((error) => (
            <Alert key={error} icon={IconError} margin={{ bottom: 'sm' }} type="error">
              {error}
              <Alert.Action
                onClick={() => {
                  retryPublishAssets();
                }}
              >
                Retry?
              </Alert.Action>
            </Alert>
          ))}
        </div>
      )}

      <Typography margin={{ bottom: 'none', top: 'lg' }} variant="header3">
        Upload
      </Typography>
      {numberOfUploads !== numberOfUploadsCompleted ? (
        <Typography margin={{ bottom: 'lg' }}>Please wait while we upload the images.</Typography>
      ) : (
        <Typography margin={{ bottom: 'lg' }}>All Images uploaded.</Typography>
      )}

      <Group align="center" margin={{ bottom: 'xl' }}>
        {!imagesToRender.length &&
          latestUploads.length > 0 &&
          images.map((image, i) => {
            return (
              <ImageCard
                key={image.secure_url}
                image={image}
                additionalActions={additionalIframeActions(image)}
                status={assetsToUpload[i].state as StatusType}
              />
            );
          })}
        {property &&
          imagesToRender.map((asset, i) => {
            return (
              <ImageCard
                key={asset.file.name + i}
                image={
                  asset?.response?.data ||
                  asset?.response || {
                    bytes: asset.response?.size,
                    access_mode: 'local-upload',
                    asset_id: `upload_${i}`,
                    isDynamic: type === 'dynamic',
                    format: asset.file?.type.replace('image/', '').replace('jpeg', 'jpg') as UploadImageFormats,
                    metadata: {
                      ...asset.metadata,
                      date_photographed: asset.metadata?.datePhotographed,
                      graphic_type: asset.metadata?.graphicType,
                    },
                    secure_url: asset.renderData?.secure_url,
                    width: asset.renderData?.width,
                    height: asset.renderData?.height,
                    tags: [],
                    aspect_ratio: asset.renderData?.width / asset.renderData?.height,
                    context: {
                      transformation_type: asset.metadata?.resizeOnly ? 'resize' : null,
                    },
                  }
                }
                additionalActions={asset.response?.data && additionalIframeActions(asset.response?.data)}
                status={asset.state as StatusType}
              />
            );
          })}
        <Group fullWidth align="end">
          <Button
            type="button"
            icon={IconUpload}
            secondary
            margin={{ top: 'md', bottom: 'lg' }}
            onClick={() => {
              resetUploadState();
            }}
            disabled={numberOfUploads !== numberOfUploadsCompleted}
          >
            Upload More Images
          </Button>
        </Group>
      </Group>
    </div>
  );
};

export default UploadStaging;
