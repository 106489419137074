import React from 'react';
import { Illustration, Typography, Button, Box, Grid, Wrapper } from '@screentone/core';

import useUpload from '../../../hooks/useUpload';
import { constants } from '../../../utils';

import styles from '../../../layouts/Upload/Upload.module.css';

type UploadBoxProps = {
  /** function supplied by react-dropzone to handle opening the file system */
  handleBrowseFiles?: React.MouseEventHandler<HTMLElement> | undefined;
};

function UploadBox({ handleBrowseFiles }: UploadBoxProps) {
  const { type } = useUpload();

  return (
    <Grid>
      {!!handleBrowseFiles && (
        <Grid.Row>
          <Grid.Col md={8} xs={12} mdOffset={2}>
            <Wrapper margin={{ bottom: 'mlg' }}>
              <Typography
                margin={{ bottom: 'sm', top: 'lg' }}
                variant="header3"
                data-testid="promo-image-upload-msg-header"
              >
                Upload {type === 'dynamic' ? 'Promo Image' : ''}
              </Typography>
              <Typography data-testid="promo-image-upload-msg-txt">
                {type === 'dynamic'
                  ? 'Promo images are dynamic images, featuring various-sized images grouped under one ID. When requested, the relevant size is delivered with extra padding. '
                  : ''}
              </Typography>

              <Typography data-testid="promo-image-upload-limit">
                Select or drag up to {constants.MAX_FILES_IN_UPLOADER[type]} images to begin.
              </Typography>
            </Wrapper>
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row center="xs">
        <Grid.Col xs={12} md={8}>
          <Box className={styles.upload__panel_box}>
            <Illustration name="image-manager" className={styles.upload__panel_box_img} />

            <Typography variant="header2" margin={{ bottom: 'none' }}>
              Drag and drop images
            </Typography>

            {!!handleBrowseFiles && (
              <>
                <Typography variant="label2" margin={{ vertical: 'sm' }}>
                  or
                </Typography>

                <Button
                  data-testid="browse-file-btn"
                  type="button"
                  margin={{ top: 'sm', bottom: 'none' }}
                  primary
                  onClick={(e: Event) => {
                    handleBrowseFiles(e);
                  }}
                >
                  Browse Files
                </Button>
              </>
            )}
            <Typography componentEl="p" size="sm" color="asphalt" margin={{ top: 'lg' }}>
              Allowed file types: {type === 'dynamic' ? '.svg ' : ''}.jpeg, .png, .gif
            </Typography>
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}

export default UploadBox;
