/**
 * The maximum number of characters allowed in a slug Is 32
 * The maximum number of character  accpeted on a cloudinary field es 1024
 */

import { CLOUDINARY_MAXIMUM_STRING_LENGTH, DATE_FORMATS } from '../constants';

type ConfigType = {
  [key: string]: {
    min?: number;
    max?: number;
    type: string;
    format?: string;
  };
};

const config: ConfigType = {
  slug: {
    max: 32,
    min: 3,
    type: 'text',
  },
  headline: {
    max: CLOUDINARY_MAXIMUM_STRING_LENGTH,
    type: 'text',
  },
  caption: {
    max: CLOUDINARY_MAXIMUM_STRING_LENGTH,
    type: 'text',
  },
  credit: {
    max: CLOUDINARY_MAXIMUM_STRING_LENGTH,
    type: 'text',
  },
  contact: {
    max: CLOUDINARY_MAXIMUM_STRING_LENGTH,
    type: 'text',
  },
  datePhotographed: { type: 'date', format: DATE_FORMATS.CLOUDINARY },
  altText: {
    max: CLOUDINARY_MAXIMUM_STRING_LENGTH,
    type: 'text',
  },
};

export default config;
