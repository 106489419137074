import { StructuredMetadataProps, ContextualMetadataProps } from '../types';
import { slugValidate } from '../utils/regex';

/**
 * Configuration used to validate upload payload request. Get Cloudinary external_id to build the
 * expression
 */
export const structuredMetadata: StructuredMetadataProps = {
  caption: {
    cloudinaryId: 'caption',
    label: 'Caption',
    type: 'string',
    required: false,
    isTag: false,
  },
  credit: {
    cloudinaryId: 'credit',
    label: 'Credit',
    type: 'string',
    required: false,
    isTag: false,
  },
  contact: {
    cloudinaryId: 'contact',
    label: 'Contact',
    type: 'string',
    required: false,
    isTag: false,
  },
  datePhotographed: {
    cloudinaryId: 'date_photographed',
    label: 'Date Photographed',
    type: 'date',
    required: false,
    isTag: false,
  },
  headline: {
    cloudinaryId: 'headline',
    label: 'Headline',
    type: 'string',
    required: true,
    isTag: false,
  },
  graphicType: {
    cloudinaryId: 'graphic_type',
    label: 'Graphic Type',
    type: 'string',
    required: true,
    isTag: false,
    invalidateCache: true,
  },
  oneTimeUse: {
    cloudinaryId: 'one_time_use',
    label: 'One Time Use',
    type: 'boolean',
    required: false,
    isTag: false,
  },
  djUserId: {
    cloudinaryId: 'dj_user_id',
    label: 'DJ User Id',
    type: 'string',
    required: false,
    isTag: false,
  },
  specialInstructions: {
    cloudinaryId: 'special_instructions',
    isTag: false,
    label: 'Special Instructions',
    required: false,
    type: 'string',
  },
  category: {
    cloudinaryId: 'category',
    isTag: false,
    label: 'Category',
    required: false,
    type: 'string',
  },
  state: {
    cloudinaryId: 'state',
    isTag: false,
    label: 'State',
    required: false,
    type: 'string',
  },
  region: {
    cloudinaryId: 'region',
    isTag: false,
    label: 'Region',
    required: false,
    type: 'string',
  },
  subCategories: {
    cloudinaryId: 'subcategories',
    isTag: false,
    label: 'subCate',
    required: false,
    type: 'string',
  },
  country: {
    cloudinaryId: 'country',
    isTag: false,
    label: 'Country',
    required: false,
    type: 'string',
  },
  city: {
    cloudinaryId: 'city',
    isTag: false,
    label: 'City',
    required: false,
    type: 'string',
  },
  uncredited: {
    cloudinaryId: 'uncredited',
    label: '',
    type: 'boolean',
    required: false,
    isTag: true,
  },
  importSource: {
    cloudinaryId: 'import_source',
    label: 'Import Source',
    type: 'string',
    required: false,
    isTag: false,
  },
  importSourceId: {
    cloudinaryId: 'import_source_id',
    label: 'Import Source Id',
    type: 'string',
    required: false,
    isTag: false,
  },
  importSourceName: {
    cloudinaryId: 'import_source_name',
    label: 'Import Source Name',
    type: 'string',
    required: false,
    isTag: false,
  },
  searchable: {
    cloudinaryId: 'searchable',
    label: 'Searchable',
    type: 'boolean',
    required: false,
    isTag: false,
  },
  keywords: {
    cloudinaryId: 'keywords',
    label: 'Keywords',
    type: 'string',
    required: false,
    isTag: false,
  },
  altText: {
    cloudinaryId: 'alt_text',
    label: 'Alt Text',
    type: 'string',
    required: false,
    isTag: false,
  },
};

export const contextualMetadata: ContextualMetadataProps = {
  title: {
    cloudinaryId: 'title',
    label: 'Title',
    type: 'string',
    required: false,
  },
  description: {
    cloudinaryId: 'alt',
    label: 'Description',
    type: 'string',
    required: false,
  },
  transformationType: {
    cloudinaryId: 'transformation_type',
    label: 'Transformation Type',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  background: {
    cloudinaryId: 'background',
    label: 'Background',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  disableThumbnail: {
    cloudinaryId: 'disable_thumbnails_crop',
    label: 'Disable Thumbnail',
    type: 'boolean',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  resizeOnly: {
    cloudinaryId: 'resize_only',
    label: 'Resize Only',
    type: 'boolean',
    required: false,
    invalidateCache: true,
    appendProperty: true,
  },
  slug: {
    cloudinaryId: 'published_label',
    label: 'slug',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
    format: slugValidate,
  },
  xScale: {
    cloudinaryId: 'x_scale',
    label: 'x Scale',
    type: 'number',
    required: false,
    appendId: false,
    invalidateCache: true,
  },
  dynamicScale: {
    cloudinaryId: 'dynamic_scale',
    label: 'dynamic scale',
    type: 'number',
    required: false,
    appendId: false,
    invalidateCache: true,
  },
  dj_smart_crop: {
    cloudinaryId: 'dj_smart_crop',
    label: 'DJ Smart Crop',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: false,
  },
  linked_prd_asset_id: {
    cloudinaryId: 'linked_prd_asset_id',
    label: 'linked production asset id',
    type: 'string',
    required: false,
    appendId: false,
    invalidateCache: false,
  },
  coordinates: {
    cloudinaryId: 'coordinates',
    label: 'coordinates',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  gravity_thumb: {
    cloudinaryId: 'gravity_thumb',
    label: 'gravity thumb',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  crop: {
    cloudinaryId: 'crop',
    label: 'crop',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  published_label: {
    cloudinaryId: 'published_label',
    label: 'published label',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: false,
  },
  quality: {
    cloudinaryId: 'quality',
    label: 'quality',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  gravity: {
    cloudinaryId: 'gravity',
    label: 'gravity',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
  overlay: {
    cloudinaryId: 'overlay',
    label: 'overlay',
    type: 'string',
    required: false,
    appendId: true,
    invalidateCache: true,
  },
};

export default structuredMetadata;
