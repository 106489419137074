import React, { useRef, useState, useEffect } from 'react';
import {
  PageWrapper,
  Loader,
  Button,
  Group,
  Sidebar,
  useSidebarState,
  IconUpload,
  FabButton,
  IconPin,
  IconImageGallery,
} from '@screentone/core';
import { useAuth, User } from '@screentone/addon-auth-wrapper';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import Searchbar from '../../components/Searchbar/Searchbar';
import SearchTitle from '../../components/SearchTitle/SearchTitle';
import ImageList from '../../components/ImageList/ImageList';
import Gallery from '../../components/Gallery/Gallery';
import Pagination from '../../components/Pagination/Pagination';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import NoResults from '../../components/NoResults/NoResults';
import Lightbox from '../../components/Lightbox';

import ButtonAddToLightbox from '../../components/Buttons/AddToLightbox';
import ButtonRemoveFromLightbox from '../../components/Buttons/RemoveFromLightbox';
import AddToSourceButton from '../../components/Buttons/AddToSource';

import useConfig from '../../hooks/useConfig';
import useUpload from '../../hooks/useUpload';
import useAlert from '../../hooks/useAppAlerts';
import useAssetManager from '../../hooks/useAssetManager';
import useSearch from '../../hooks/useSearch';

import { iframe, regex } from '../../utils';
import { checkIfPublished, createPublishedIdsObj } from '../../utils/helpers';

import type { ImageType, SearchViewOptionsType } from '../../types';

function SearchLayoutWrapper({ children }: { children: React.ReactNode }) {
  const { getAllAssetsFromLightbox } = useAssetManager();
  const {
    session: { SHOW_LIGHTBOX: showLightbox },
  } = useConfig();

  const headerElement = document.querySelectorAll('[data-header]')[0];
  const headerClientRect = headerElement?.getBoundingClientRect();
  let headerHight = `${headerClientRect ? headerClientRect.height : 0}px`;

  const {
    status: lightboxStatus,
    openSidebar: openLightbox,
    collapseSidebar: collapseLightbox,
    sidebarProps: lightboxSidebarProps,
    pageProps: lightboxPageProps,
  } = useSidebarState({
    type: 'slide',
    position: 'right',
    status: iframe.isFrame() ? 'open' : 'collapsed',
  });

  const lightboxImages = getAllAssetsFromLightbox();
  useEffect(() => {
    if (lightboxImages?.length && (lightboxStatus === 'collapsed' || lightboxStatus === 'collapsing')) {
      openLightbox();
    }
  }, [lightboxImages]);

  // listen to headerClientRect for height changes
  useEffect(() => {
    if (!headerElement) return;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        headerHight = `${height}px`;
      }
    });
    observer?.observe(headerElement);
    return () => observer?.disconnect();
  }, [headerElement, headerHight, window.location]);

  lightboxSidebarProps.top = headerHight;

  if (!showLightbox) {
    return children;
  }

  return (
    <>
      <Lightbox
        openSidebar={openLightbox}
        collapseSidebar={collapseLightbox}
        status={lightboxStatus}
        sidebarProps={lightboxSidebarProps}
      />
      <Sidebar.Page {...lightboxPageProps} style={{ height: `calc(100vh - ${headerHight}px)` }}>
        {children}
      </Sidebar.Page>
    </>
  );
}

const additionalActions = (image: ImageType) => {
  const buttons: any = [];

  const { setImage, addAssetToLightbox, lightboxHasAsset, removeAssetFromLightbox } = useAssetManager();

  const {
    session: { property, PREVIEW_SIZES, IMAGE_DOMAINS, SHOW_LIGHTBOX },
  } = useConfig();

  const publishedIdsObj = createPublishedIdsObj(image as ImageType, IMAGE_DOMAINS, PREVIEW_SIZES, property);
  const imageInLightbox = lightboxHasAsset(image.asset_id);

  if (!image) return;

  if (SHOW_LIGHTBOX) {
    if (imageInLightbox) {
      buttons.push(
        <ButtonRemoveFromLightbox
          onClick={() => {
            removeAssetFromLightbox(image.asset_id);
          }}
        />,
      );
    } else {
      buttons.push(
        <ButtonAddToLightbox
          publishedIdsObj={publishedIdsObj}
          onClick={(e: any, info: any) => {
            setImage(image);
            addAssetToLightbox(image.asset_id, info?.id || Object.keys(publishedIdsObj)[0]);
          }}
        />,
      );
    }
  }
  const isPublished = checkIfPublished(image, property);
  if (!iframe.sendImageArray() && iframe.isFrame() && isPublished) {
    return <AddToSourceButton image={image} />;
  }

  return buttons;
};

// the layout component that also calls the different components
// since we can't call the hooks from the Property() component
// TBD whether it gets broken out into its own page
function SearchLayout() {
  const { alertComponent } = useAlert();
  const { user, userAccess, authState } = useAuth();
  const { resetUploadState } = useUpload();
  const navigate = useNavigate();
  const {
    session: { property, SEARCH, SHOW_LIGHTBOX },
  } = useConfig();

  const showAdvanced = SEARCH?.OPTIONS?.show_advanced || false;
  const {
    state,
    options,
    hasAppliedOptions,
    triggerRefresh,
    resetOptions,
    openImage,
    setOpenImage,
    setImage,
    setLayout,
  } = useSearch();

  const [view, setView] = useState<SearchViewOptionsType>('grid');
  const { state: routerState = {} } = useLocation();

  if (authState?.accessToken?.accessToken) setLayout(`search_${property}`);

  const { query } = options;
  const { resources: images = [] } = state?.searchResults || [];

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [showOneTimeUse] = useState(true);

  const toggleView = (nextView: SearchViewOptionsType) => {
    localStorage.setItem('lastView', nextView);
    setView(nextView);
  };

  useEffect(() => {
    const storedView = localStorage.getItem('lastView') as SearchViewOptionsType;
    if (storedView) {
      setView(storedView);
    }
  }, []);

  useEffect(() => {
    if (routerState && routerState.from === 'header-app-name' && state.status !== 'loading') {
      resetOptions();
      navigate(`/${property}`, { replace: true });
    }
  }, [routerState, state.status]);

  return (
    <>
      {alertComponent}
      <SearchLayoutWrapper showLightbox={SHOW_LIGHTBOX}>
        <PageWrapper>
          <Searchbar showOneTimeUse={showOneTimeUse} showAdvanced={showAdvanced} />
          <SearchTitle
            showReset={hasAppliedOptions()}
            onRefresh={triggerRefresh}
            onReset={() => {
              if (searchInputRef.current) {
                searchInputRef.current.value = '';
              }
              resetOptions();
            }}
            onSwitchView={toggleView}
            query={query}
            view={view}
          />
        </PageWrapper>

        {state.status === 'loading' && (
          <Group margin={{ all: 'xl' }} align="center">
            <Group.Item padding={{ all: 'xl' }}>
              <Loader size="lg" />
            </Group.Item>
          </Group>
        )}

        {state.status === 'ready' && images.length === 0 && (
          <PageWrapper>
            <NoResults />
          </PageWrapper>
        )}

        {state.status === 'error' && (
          <PageWrapper>
            <CustomErrorMessage
              title="Something went wrong"
              message={`We couldn't load the results, please try again. ${state.error?.message || 'unknown error'}. `}
              illustration="blank"
              Action={
                <Button data-testid="search-button" primary onClick={triggerRefresh}>
                  Retry
                </Button>
              }
            />
          </PageWrapper>
        )}

        {state.status === 'ready' && view === 'grid' && images.length > 0 && (
          <>
            <Gallery
              images={images}
              fullWidth
              setImage={setImage}
              openImage={openImage}
              setOpenImage={setOpenImage}
              publishedId={regex.id.im.test(query || '') ? query : undefined}
              additionalActions={additionalActions}
            />
          </>
        )}

        {state.status === 'ready' && view === 'list' && images.length > 0 && (
          <PageWrapper>
            <ImageList
              images={images}
              user={user as User}
              setImage={setImage}
              publishedId={regex.id.im.test(query || '') ? query : undefined}
              additionalActions={additionalActions}
            />
          </PageWrapper>
        )}

        {state.status === 'ready' && <Pagination />}

        {iframe.isUploadEnabled() && userAccess(`${property}.upload`) ? (
          <FabButton
            data-testid="upload-btn"
            icon={IconUpload}
            componentEl={Link}
            to="./upload"
            onClick={() => {
              resetUploadState();
            }}
            relative="path"
            toolTipContent="Upload Images"
          >
            <FabButton.FabAdditional>
              <FabButton
                data-testid="upload-promo-images-btn"
                simple
                inverted
                icon={IconImageGallery}
                toolTipContent="Upload Promo Image"
                componentEl={Link}
                relative="path"
                to="./upload/dynamic"
                onClick={() => {
                  resetUploadState();
                }}
              />
            </FabButton.FabAdditional>

            {userAccess('app_admin') && (
              <FabButton.FabAdditional>
                <FabButton
                  data-testid="upload-static-images-btn"
                  simple
                  inverted
                  icon={IconPin}
                  toolTipContent="Upload Static Images"
                  componentEl={Link}
                  relative="path"
                  to={`/${property}/static`}
                />
              </FabButton.FabAdditional>
            )}
          </FabButton>
        ) : null}
      </SearchLayoutWrapper>
    </>
  );
}

export default SearchLayout;
