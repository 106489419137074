import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  IconCopy,
  Loader,
  Token,
  Table,
  IconSort,
  IconSortReverse,
  IconBarrons,
  IconMansionglobal,
  IconMarketwatch,
  IconFnlondon,
  IconWsj,
  IconWsjopinion,
  IconWsjcommerce,
  IconDowjones,
} from '@screentone/core';
import useConfig from '../../../hooks/useConfig';

import useCopyToClipboard from '../../../hooks/useCopyToClipboard';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

type SortRow = {
  time_stamp: number;
  totalTime: number;
  cldTime: number;
  userId: string;
  expression: string;
};

const getData = (rowCount: number): SortRow[] => {
  const data: SortRow[] = [];
  for (let i = 0; i < rowCount; i++) {
    data.push({
      time_stamp: Date.now(),
      totalTime: Math.random() * 100,
      cldTime: Math.random() * 100,
      userId: `userId-${i}`,
      expression: `expression-${i}`,
    });
  }
  return data;
};
// //custom sorting logic for one of our enum columns
// const sortStatusFn: SortingFn<SortRow> = (rowA, rowB, _columnId) => {
//   const statusA = rowA.original.timeStamp;
//   const statusB = rowB.original.status;
//   const statusOrder = ['single', 'complicated', 'relationship'];
//   return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
// };

const UserSearchLogs = () => {
  // const rerender = React.useReducer(() => ({}), {})[1];

  const [sorting, setSorting] = useState<SortingState>([{ id: 'time_stamp', desc: true }]);

  const { authFetch } = useConfig();

  const getColorCode = (totalTime: number) => {
    if (totalTime < 2) return 'emerald';
    if (totalTime >= 2 && totalTime < 5) return 'sunshine';
    if (totalTime >= 5 && totalTime < 10) return 'tangerine';
    return 'gray';
  };
  const columns = React.useMemo<ColumnDef<SortRow>[]>(
    () => [
      {
        accessorKey: 'property',
        header: '',
        cell: ({ cell, row }) => {
          const property = row.original.property;
          switch (property) {
            case 'barrons':
              return <IconBarrons size="mlg" />;
            case 'mansionglobal':
              return <IconMansionglobal size="mlg" />;
            case 'marketwatch':
              return <IconMarketwatch size="mlg" />;
            case 'fnlondon':
              return <IconFnlondon size="mlg" />;
            case 'wsj':
              return <IconWsj size="mlg" />;
            case 'wsjopinion':
              return <IconWsjopinion size="mlg" />;
            case 'wsjcommerce':
              return <IconWsjcommerce size="mlg" />;
            default:
              return <IconDowjones size="mlg" />;
          }
        },
      },
      {
        accessorKey: 'time_stamp',
        header: 'timeStamp',
        cell: ({ cell, row }) => {
          const date = new Date(row.original.time_stamp);
          return (
            <>
              <Token>{date.toDateString()}</Token>
              <Token>{date.toLocaleTimeString()}</Token>
            </>
          );
        },
      },
      {
        accessorKey: 'totalTime',
        header: 'Total Time',
        cell: ({ cell, row }) => {
          return <Token color={getColorCode(row.original.totalTime)}>{row.original.totalTime}</Token>;
        },
      },
      {
        accessorKey: 'cldTime',
        header: 'CLD Time',
        cell: ({ cell, row }) => {
          const time = Number((row.original.cldTime / 1000).toFixed(2));
          return <Token color={getColorCode(time)}>{time}</Token>;
        },
      },
      {
        accessorKey: 'userId',
        header: 'userId',
      },

      {
        accessorKey: 'expression',
        header: 'expression',
        cell: ({ cell, row }) => {
          const exp = row.original.expression.replace('  ', ' ').split(') AND (');
          const searchOptions = exp.map((e, i) => {
            let headlineCount = 0;
            e = e.replace('(', '').replace('(', '');
            // if (e.startsWith(`folder`)) {
            //   const folders = e.split(' OR ');
            //   return (
            //     <Typography variant="label1">
            //       Folders:{' '}
            //       {folders.map((folder) => (
            //         <Token>{folder.replace('folder:', '')}</Token>
            //       ))}
            //     </Typography>
            //   );
            // } else
            if (e.startsWith(`metadata.dj_user_id`)) {
              const metadata = e.replace('metadata.dj_user_id:"', '').split('" OR ');
              return (
                <Typography variant="label1">
                  USER: <Token> {metadata[0]}</Token>
                </Typography>
              );
            } else if (e.startsWith(`metadata.headline`)) {
              const metadata = e.replace('metadata.headline:"', '').split('" OR ');
              const label = headlineCount === 0 ? 'Text' : 'AND';
              console.log('label: ', label);

              headlineCount = i;
              console.log('i: ', i);
              console.log('headlineCount: ', headlineCount);

              return (
                <Typography variant="label1">
                  {label}: <Token>{metadata[0]}</Token>
                </Typography>
              );
            }

            // (folder:wires/AP/* OR folder:wires/EPA/*)
            // (resource_type: image)
            // (metadata.dj_user_id: "osbornp" OR context."published_by_wsj": "osbornp")
            // (context.wsj_published_id: im * OR context.published_id_wsj: im * OR metadata.published_id: im *)
            // (metadata.graphic_type = Photo)
            // (NOT tags: WSJSelects)
            // (metadata.region: GNY AND tags: TopWires AND metadata.category: Sport)
            // (-tags=im-search-exclude)
            // ((metadata.headline: "Kangaroo*" OR metadata.caption: "Kangaroo*" OR metadata.credit: "Kangaroo*" OR metadata.contact: "Kangaroo*" OR context."tags_wsj": "Kangaroo*")
            // (metadata.headline: "Mothers*" OR metadata.caption: "Mothers*" OR metadata.credit: "Mothers*" OR metadata.contact: "Mothers*" OR context."tags_wsj": "Mothers*")
            // (metadata.headline: "Unity*" OR metadata.caption: "Unity*" OR metadata.credit: "Unity*" OR metadata.contact: "Unity*" OR context."tags_wsj": "Unity*"))
          });

          const [value, copy, supported, error] = useCopyToClipboard();

          return (
            <>
              {searchOptions}
              <Button
                tertiary
                icon={IconCopy}
                onClick={(e: Event) => {
                  e.preventDefault();
                  copy(row.original.expression);
                }}
              >
                Copy full expression
              </Button>
            </>
          );
        },
      },
    ],
    [],
  );

  const [data, setData] = useState(() => getData(1_000));
  const [fetchedData, setFetchedData] = useState(false);
  // const refreshData = () => setData(() => getData(100_000)); //stress test with 100k rows

  useEffect(() => {
    authFetch(`/api/searchUserLog`, {
      method: 'POST',
      // body: JSON.stringify({ userID: selectedUser, date: newDate }),
    })
      .then((logs) => {
        setFetchedData(true);
        setData(logs);
      })
      .catch((err: Error) => {
        throw err;
      });
  }, []);

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    // sortingFns: {
    //   sortStatusFn, //or provide our custom sorting function globally for all columns to be able to use
    // },
    //no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    state: {
      sorting,
    },
    // autoResetPageIndex: false, // turn off page index reset when sorting or filtering - default on/true
    // enableMultiSort: false, //Don't allow shift key to sort multiple columns - default on/true
    // enableSorting: false, // - default on/true
    // enableSortingRemoval: false, //Don't allow - default on/true
    // isMultiSortEvent: (e) => true, //Make all clicks multi-sort - default requires `shift` key
    // maxMultiSortColCount: 3, // only allow 3 columns to be sorted at once - default is Infinity
  });

  if (!fetchedData) {
    return <Loader />;
  }

  //access sorting state from the table instance

  return (
    <div className="p-2">
      <div className="h-2" />
      <Table>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Cell scope="col" key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                              ? 'Sort descending'
                              : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <IconSort color="blurple" />,
                          desc: <IconSortReverse color="blurple" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table
            .getRowModel()
            .rows.slice(0, 100)
            .map((row) => {
              return (
                <Table.Row key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default UserSearchLogs;
