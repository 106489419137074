import React, { useEffect, useContext, useReducer, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import { format } from '@screentone/addon-calendar';

import { constants } from '../../utils';
import Queue from '../../utils/dataStructures/queue';
import {
  cleanOptions,
  getNextPageCursor,
  mergeReducer,
  generateUuid,
  wait,
  localStorageHelper,
} from '../../utils/helpers';

import useConfig from '../useConfig';
import useInitialSearchOptions from '../useSearch/useInitialSearchOptions';

import type {
  ImageType,
  IUseSearchResults,
  SearchOptionsType,
  SearchResultProps,
  TSearchProviderArguments,
  TTriggerSearchProps,
} from '../../types';

const SearchContext = React.createContext<IUseSearchResults | undefined>(undefined);
const paginationInitialState = { currentPage: 0, pages: [null] };

const getLocalStorageOptions = (property: string) => {
  const storedSearchOptions = localStorage.getItem(`${property}:searchOptions`);

  let localSearchOptions = null;
  if (storedSearchOptions) {
    // prevent ref and source loading from localStorage
    const { ref, source, ...parsedSearchOptions } = JSON.parse(storedSearchOptions);
    localSearchOptions = parsedSearchOptions;
    // console.log('localSearchOptions: ', localSearchOptions);
  }
  return localSearchOptions;
};

const getLocalStoragePagination = (property: string) => {
  const storedSearchPagination = localStorage.getItem(`${property}:pagination`);
  return storedSearchPagination ? JSON.parse(storedSearchPagination) : paginationInitialState;
};

const emptyOptions = {
  query: '',
  user: null,
  date: {
    since: '',
    until: '',
    range: '',
  },
  oneTimeUse: false,
  statusFilter: null,
  advancedFilter: {},
  sourceFilter: [],
  graphicTypesFilter: {},
};

/** Provider for context */
export const SearchProvider = ({ children }: TSearchProviderArguments) => {
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const location = useLocation();

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  const lastMonth = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
  const lastYear = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

  // time filters for dates
  const dateFilters = {
    anyTime: { startDate: null, endDate: null, label: 'Any Time' },
    today: { startDate: today, endDate: today, label: 'Today' },
    lastWeek: { startDate: lastWeek, endDate: today, label: 'Last Week' },
    lastMonth: { startDate: lastMonth, endDate: today, label: 'Last Month' },
    lastYear: { startDate: lastYear, endDate: today, label: 'Last Year' },
  };

  const { initialOptions } = useInitialSearchOptions({ filters: dateFilters });
  const [layout, setLayout] = useState<string | null>(null);
  const [searchState, setSearchState] = useReducer(mergeReducer, {
    searchResults: {},
    pagination: getLocalStoragePagination(property),
    status: 'loading',
    property,
  });

  const [openImage, setOpenImage] = useState<string | null>(null);
  const [imScrollToId, setImScrollToIdFn] = useState('');
  const setImScrollToId = (id: string) => {
    setImScrollToIdFn(id);
  };

  const searchQueue = { queue: new Queue(), searchWasTriggered: false }; // Update to a proper queue

  const searchMergeReducer = (oldState: any, newState: any) => {
    if (newState.reset) {
      delete newState.reset;
      return { ...initialOptions, ...newState };
    }
    return { ...oldState, ...newState };
  };

  const [searchOptions, setSearchOptions] = useReducer(
    searchMergeReducer,
    getLocalStorageOptions(property) || initialOptions, // checks local storage
  );

  const [isUserTriggered, setIsUserTriggered] = useState(false);

  useEffect(() => {
    // if searchOptions is empty, set it to initialOptions because it's the first time the component is mounted
    // and it won't override the search when we select any new option
    // the second check is to avoid when switching between brands having the same search options as the previous brand
    if (
      isEqual(searchOptions, emptyOptions) ||
      (isEqual(initialOptions, emptyOptions) && isEqual(getLocalStorageOptions(property), emptyOptions))
    ) {
      setSearchOptions(initialOptions);
      return;
    }

    const localStorageOptions = getLocalStorageOptions(property);
    if (localStorageOptions && localStorageOptions.date?.since === '' && localStorageOptions.date?.until === '') {
      setSearchOptions({ ...searchOptions, date: { ...initialOptions.date } });
    }

    // if we have selected a preset (range), it should honor the date range of the preset
    if (searchOptions.date?.range && searchOptions.date?.range !== 'anyTime') {
      const { range } = searchOptions.date;
      const { startDate, endDate } = dateFilters[range as keyof typeof dateFilters];
      const newSince = startDate ? format(startDate, constants.DATE_FORMATS.CLOUDINARY) : '';
      const newUntil = endDate ? format(endDate, constants.DATE_FORMATS.CLOUDINARY) : '';
      setSearchOptions({ ...searchOptions, date: { since: newSince, until: newUntil, range } });
    }
  }, [initialOptions]);

  const triggerSearch = useCallback(
    async ({ cursor = null, options, requestId = generateUuid() }: TTriggerSearchProps) => {
      let { queue } = searchQueue;
      console.groupCollapsed('triggerSearch');
      console.log('location: ', location);
      console.log('property: ', property);
      // console.log('authState?.accessToken: ', authState?.accessToken);
      console.log('options: ', options);
      console.groupEnd();

      if (property && layout === `search_${property}`) {
        try {
          const cleanOptionsObj = cleanOptions(options) as SearchOptionsType;
          queue.enqueue({ cursor, options, requestId });
          if (searchQueue.searchWasTriggered) {
            return null;
          }
          searchQueue.searchWasTriggered = true;
          setSearchState({ status: 'loading' });

          // flatten the options object so it can be sent to the API as before for backward compatibility
          const flattenedOptions = {
            ...cleanOptionsObj,
            since: cleanOptionsObj.date?.since,
            until: cleanOptionsObj.date?.until,
            range: cleanOptionsObj.date?.range,
          };
          delete flattenedOptions.date;

          const requestBody = {
            pageSize: constants.PAGE_SIZE,
            ...flattenedOptions,
            nextCursor: cursor,
            requestId,
          };

          if (queue.head.data.requestId !== queue.tail?.data.requestId) await wait(1800);

          const SearchResult: SearchResultProps = await authFetch(`/api/:property/search`, {
            method: 'POST',
            body: JSON.stringify(requestBody),
          });
          searchQueue.searchWasTriggered = false;

          const responseRequestId = SearchResult.requestId;
          if (queue?.tail?.data?.requestId == responseRequestId) {
            const newSearchState: { [x: string]: any } = {
              searchResults: SearchResult,
            };
            // Resets the pagination on new search due to option updates
            // next/prev pagination will pass down a cursor value
            // cursor = null means it was triggered from the useEffect
            if (cursor === null) {
              newSearchState.pagination = {
                currentPage: 0,
                pages: [null, SearchResult.next_cursor],
              };
            }
            queue.dequeueAll();
            setSearchState({ ...newSearchState, status: 'ready' });
            return SearchResult;
          } else {
            triggerSearch({ ...queue?.tail?.data });
          }
        } catch (err) {
          const { stack = 'n/a', ...error } = err;

          searchQueue.searchWasTriggered = false; // for retry

          const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
          let propertySearchOptions = {};
          if (property) {
            propertySearchOptions = options;
          }

          const isFrame = window.self !== window.top;
          const pageOptions = {
            isFrame,
            url: isFrame ? document.referrer : document.location.href,
          };
          const headers = { 'Content-Type': 'application/json' };
          const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
              title: 'SEARCH ERROR',
              error,
              errorInfo: {
                componentStack: stack,
              },
              pageOptions,
              oktaTokenStorage,
              property,
              propertySearchOptions,
            }),
            headers,
          };

          const postError = new Request('/api/error', requestOptions);
          const response = await fetch(postError);

          console.error('Uncaught error:', response.status, error);

          setSearchState({ status: 'error' });
          return null;
        }
      }
    },
    [layout, property],
  );

  const setImage = (img: ImageType) => {
    const idx = searchState?.searchResults?.resources?.findIndex((item: ImageType) => item.asset_id === img.asset_id);
    if (idx !== undefined && idx !== -1 && Array.isArray(searchState?.searchResults?.resources)) {
      const images = searchState.searchResults?.resources;
      images[idx] = img;
      if (!images || idx === -1) return;
      setSearchState({ ...searchState.searchResult, resources: images });
    }
  };
  const navigatePage = useCallback(
    async (direction: 'next' | 'prev') => {
      try {
        const { currentPage, pages } = searchState.pagination;
        const { cursor, newCurrentPage } = getNextPageCursor(currentPage, pages, direction);
        const searchResults = await triggerSearch({ cursor, options: searchOptions });

        let updatedPages = pages;
        if (pages.length === currentPage + 2) {
          updatedPages = [...pages, searchResults?.next_cursor];
        }

        setSearchState({
          pagination: {
            currentPage: newCurrentPage,
            pages: updatedPages,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [searchState.pagination, searchOptions, triggerSearch],
  );

  useLayoutEffect(() => {
    const localSearchOptions = getLocalStorageOptions(property);
    const cleanSearchLocalOption = localSearchOptions
      ? Object.entries(localSearchOptions).reduce((opts, [key, value]: [string, any]) => {
          if (value !== '' && value !== null && value !== undefined) {
            if (key === 'user' && typeof value === 'object') {
              return { ...opts, [key]: value.dj_user_id || '' };
            }
            return { ...opts, [key]: value };
          }
          return opts;
        }, {})
      : null;
    // If user is loading the brand without extra query params, then load from localStorage
    // TODO: add the cursor on the url for when shared
    try {
      // This is for maintaining the storage of pagination between pages
      // and retrieving the search when the component is mounted for the first time from local storage
      let localPagination = null;

      const storedPagination = localStorage.getItem(`${property}:pagination`);

      if (storedPagination) {
        localPagination = JSON.parse(storedPagination);
      }

      if (localPagination) {
        setSearchState({
          pagination: localPagination,
        });
      }

      if (cleanSearchLocalOption) {
        setSearchOptions({ ...cleanSearchLocalOption });
      }
    } catch (e) {
      console.error(e);
    }
  }, [property]);

  useEffect(() => {
    if (searchState.property !== property) {
      setSearchState({
        searchResults: null,
        pagination: getLocalStoragePagination(property),
        status: 'loading',
        property,
      });
    }

    const options = getLocalStorageOptions(property);
    localStorage.removeItem(`${property}:pagination`);
    setImScrollToId('');
    setOpenImage(null);
    updateSearchOptions({ ...options, ...{ reset: true } }, true);
  }, [property]);

  const updateSearchOptions = useCallback((newOption: SearchOptionsType, resetPagination = false) => {
    setIsUserTriggered(true);
    setSearchOptions({
      ...newOption,
    });
    if (resetPagination) {
      // set pagination to its initial state
      setSearchState({ pagination: paginationInitialState });
    }
  }, []);

  useEffect(() => {
    // If the property exists and parsedSearchOptions is not equal to initialSyncedOptions, then set the next value in localStorage.
    // If localStorage doesn't exist, it will be created.
    try {
      if (searchOptions === null) {
        return;
      }
    } catch (e) {
      console.error('Error updating localStorage:', e);
    }
  }, [searchOptions, property, isUserTriggered]);

  useEffect(() => {
    if (isUserTriggered) {
      const { ref, source, ...parsedSearchOptions } = searchOptions as SearchOptionsType & { [x: string]: any };
      localStorage.setItem(`${property}:searchOptions`, JSON.stringify(parsedSearchOptions));
      setIsUserTriggered(false);
    }
  }, [isUserTriggered]);

  useEffect(() => {
    if (property) {
      try {
        localStorage.setItem(`${property}:pagination`, JSON.stringify(searchState.pagination));
      } catch (e) {
        console.error(e);
      }
    }
  }, [searchState, property]);

  useEffect(() => {
    if (layout === `search_${property}`) {
      const currentLocalStorageProperty = localStorage.getItem(`currentProperty:searchOptions`);
      const { currentPage, pages } = searchState.pagination;
      const cursor = currentLocalStorageProperty === property ? pages[currentPage] || null : null;
      triggerSearch({ options: getLocalStorageOptions(property) || initialOptions, cursor });
      localStorage.setItem(`currentProperty:searchOptions`, property);
    }

    /*
      Ignoring `searchState.pagination` dependency array. It will be 1 render behind (stale)
      but the update happens to get the `next` cursor page. For this useEffect the `current` one is required,
      so the `next` cursor can be ignored. Additionally, adding it triggers a circular dependency, and
      it reaches maximum-depth error
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSearch, searchOptions, layout, initialOptions]);

  useEffect(() => {
    if (
      !location.pathname.includes('batch') &&
      !location.pathname.includes('upload') &&
      !location.pathname.includes('image/')
    ) {
      localStorageHelper.deleteItem(property || location.pathname.split('/')[1]);
    }
  }, [location.pathname]);

  const triggerRefresh = async () => {
    const cursor = searchState.pagination.pages[searchState.pagination.currentPage] || null;
    await triggerSearch({ cursor, options: { ...searchOptions } });
  };

  const resetOptions = () => {
    localStorage.removeItem(`${property}:searchOptions`);
    localStorage.removeItem(`${property}:pagination`);
    setImScrollToId('');
    setOpenImage(null);
    updateSearchOptions({ reset: true }, true);
  };

  const hasAppliedOptions = () => !isEqual(initialOptions, searchOptions) || searchState?.pagination?.currentPage != 0;

  const value: any = {
    property,
    state: searchState,
    options: searchOptions || {},
    navigatePage,
    updateSearchOptions,
    triggerRefresh,
    resetOptions,
    openImage,
    setOpenImage,
    imScrollToId,
    setImScrollToId,
    hasAppliedOptions,
    setImage,
    setLayout,
    dateFilters,
    emptyOptions,
  };
  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

/**
 * Hook for getting and setting information about search options
 * For more information about the pattern used, see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const useSearch = () => {
  const context = useContext(SearchContext);

  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return context;
};

export { useSearch };
export default useSearch;
