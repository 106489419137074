import React, { useState } from 'react';
import {
  Wrapper,
  useSidebarState,
  Sidebar,
  Button,
  IconPlusCircle,
  Group,
  Typography,
  Responsive,
} from '@screentone/core';
import EditMetadataCard from '../../EditMetadataCard/EditMetadataCard';
import { EditImageMetaFormFooter, EditImageMetaForm } from '../../EditMetadataForm';
import SelectBar from '../../SelectBar/SelectBar';

import useUpload from '../../../hooks/useUpload';
import useUploadPositions from '../../../hooks/useUploadPositions';
import { constants } from '../../../utils';
import { removeEmptyKeys } from '../../../utils/fieldsValidation/validate';

import type { GraphicType } from '../../../types';
import { requiredFieldsForPublisher } from '../../../utils/helpers';
import useConfig from '../../../hooks/useConfig';

type UploadFormProps = {
  /** function supplied by react-dropzone to handle opening the file system */
  handleBrowseFiles: React.MouseEventHandler<HTMLElement> | undefined;
};

const UploadForm = ({ handleBrowseFiles }: UploadFormProps) => {
  const {
    session: { property, SEARCH },
  } = useConfig();
  const { assetsToUpload, type, bulk, selectForBulkUpdate, bulkUpdate, assetMetadataUpdate, removeAsset } = useUpload();
  const [calendarError, setCalendarError] = useState('');
  const {
    status: mobileStatus,
    openSidebar: mobileOpenSidebar,
    closeSidebar: mobileCloseSidebar,
    sidebarProps: mobileSidebarProps,
    pageProps: mobilePageProps,
  } = useSidebarState({
    type: 'overlay',
    position: 'right',
    status: 'closed',
  });

  const { status, openSidebar, closeSidebar, sidebarProps, pageProps } = useSidebarState({
    position: 'right',
    status: 'closed',
    type: 'slide',
  });
  const { sidebarElem, sidebarY } = useUploadPositions();

  const openBulkEditForm = () => {
    openSidebar();
  };

  const mobileOpenBulkEditForm = () => {
    // make sure that the bulk edit form's default values are up to date with the currently selected values
    mobileOpenSidebar();
  };

  const validImages = assetsToUpload.filter(({ validation }) => {
    const requiredFields = requiredFieldsForPublisher(property, SEARCH?.OPTIONS?.FORM_VALIDATION);
    const totalRequiredFields = Object.keys(requiredFields);
    const totalComplete = totalRequiredFields.reduce(
      (total, field) => (total += validation[field] == false ? 1 : 0),
      0,
    );
    return totalComplete === totalRequiredFields.length;
  });

  return (
    <Wrapper>
      <div ref={sidebarElem} />
      <Responsive xs>
        {(matches: boolean) => {
          const propsSidebar = matches ? mobileSidebarProps : sidebarProps;
          const sidebarClose = matches ? mobileCloseSidebar : closeSidebar;
          const propsPage = matches ? mobilePageProps : pageProps;
          const sidebarStatus = matches ? mobileStatus : status;

          return (
            <>
              <Sidebar
                top={`${sidebarY}px` || null}
                bottom={null}
                width={matches ? '385px' : '300px'}
                type={matches ? 'overlay' : 'slide'}
                position="right"
                zIndex="minor"
                {...propsSidebar}
              >
                <Sidebar.Header>
                  <Typography data-testid="bulk-metadata-title" variant="label2" margin={{ all: 'md' }}>
                    Bulk Edit Metadata
                  </Typography>
                </Sidebar.Header>
                <Sidebar.Section>
                  <Wrapper padding={{ all: 'md' }}>
                    <Group direction="column">
                      <EditImageMetaForm
                        slug={bulk.metadata.slug || ''}
                        caption={bulk.metadata.caption || ''}
                        credit={bulk.metadata.credit || ''}
                        contact={bulk.metadata.contact || ''}
                        headline={bulk.metadata.headline || ''}
                        graphicType={bulk.metadata.graphicType as GraphicType}
                        datePhotographed={bulk.metadata.datePhotographed || ''}
                        oneTimeUse={bulk.metadata.oneTimeUse || false}
                        resizeOnly={bulk.metadata.resizeOnly || false}
                        uncredited={bulk.metadata.uncredited || false}
                        onChange={(metadata = {}) => {
                          bulkUpdate({ metadata });
                        }}
                        disableOneTimeUse={false}
                        disableResize={false}
                        disableRequiredFields={true}
                        calendarError={calendarError}
                        setCalendarError={setCalendarError}
                        background={bulk.metadata.background}
                      />
                    </Group>
                  </Wrapper>
                </Sidebar.Section>
                <Sidebar.Footer>
                  <EditImageMetaFormFooter
                    disableApplyButton={!bulk.selectedAssetsIndexes.length || !!calendarError}
                    onApplyMetadata={() => {
                      bulk.selectedAssetsIndexes.forEach((index) => {
                        assetMetadataUpdate({ index, metadata: removeEmptyKeys(bulk.metadata), validate: true });
                      });
                      sidebarClose();
                    }}
                    onCancel={() => {
                      bulkUpdate({ metadata: {}, reset: true });
                      sidebarClose();
                      setCalendarError('');
                    }}
                  />
                </Sidebar.Footer>
              </Sidebar>
              <Sidebar.Page {...propsPage}>
                <Wrapper>
                  <SelectBar
                    selectedImages={bulk.selectedAssetsIndexes}
                    totalImages={assetsToUpload.length}
                    numberOfValidImages={validImages.length}
                    status={sidebarStatus}
                    closeSidebar={sidebarStatus !== 'open' ? () => {} : sidebarClose}
                    openSidebar={matches ? mobileOpenBulkEditForm : openBulkEditForm}
                    onRemoveImage={() => {
                      bulk.selectedAssetsIndexes.forEach((index) => {
                        removeAsset({ index });
                      });
                    }}
                    onSelectAll={(selected) => {
                      // Bulk select all images
                      for (let i = 0; i < assetsToUpload.length; i++) {
                        selectForBulkUpdate({ index: i, isSelected: selected });
                      }
                    }}
                  />

                  {assetsToUpload.map((asset, i) => (
                    <EditMetadataCard
                      key={asset.renderData.secure_url}
                      filename={asset.file.name}
                      metadata={asset.metadata}
                      imageBuffer={asset.renderData.secure_url}
                      validation={asset.validation}
                      isBulkEditFormOpen={sidebarStatus === 'open' || sidebarStatus === 'opening'}
                      isSelected={bulk.selectedAssetsIndexes.includes(i)}
                      selectedImages={bulk.selectedAssetsIndexes}
                      width={asset.renderData.width}
                      height={asset.renderData.height}
                      onBlur={(metadata) => {
                        assetMetadataUpdate({ index: i, metadata, validate: true });
                      }}
                      onChange={(metadata) => {
                        assetMetadataUpdate({ index: i, metadata });
                      }}
                      onDelete={() => {
                        removeAsset({ index: i });
                      }}
                      onImageSelect={(selected) => {
                        selectForBulkUpdate({ index: i, isSelected: selected });
                      }}
                      closeSidebar={sidebarStatus !== 'open' ? () => {} : sidebarClose}
                    />
                  ))}
                  <Group direction="column" gap="md">
                    <Typography variant="note" margin={{ horizontal: 'md' }}>
                      Required fields are marked with an asterisk{' '}
                      <Typography inline variant="note" color="lava">
                        *
                      </Typography>
                    </Typography>
                    <Typography data-testid="max-img-text" variant="note" margin={{ horizontal: 'md' }}>
                      Maximum of {constants.MAX_FILES_IN_UPLOADER[type]} images can be uploaded at a one time. (
                      {assetsToUpload.filter(({ accepted }) => ({ accepted })).length} /{' '}
                      {constants.MAX_FILES_IN_UPLOADER[type]})
                    </Typography>
                  </Group>
                  <Group fullWidth align="end">
                    <Button
                      data-testid="upload-more-images-button"
                      type="button"
                      icon={IconPlusCircle}
                      secondary
                      margin={{ top: 'md', bottom: 'lg' }}
                      onClick={handleBrowseFiles}
                      disabled={assetsToUpload.length === constants.MAX_FILES_IN_UPLOADER[type]}
                    >
                      Upload More Images
                    </Button>
                  </Group>
                </Wrapper>
              </Sidebar.Page>
            </>
          );
        }}
      </Responsive>
    </Wrapper>
  );
};

export default UploadForm;
