import React, { useState } from 'react';
import {
  Typography,
  FormLabel,
  Input,
  IconLoader,
  Box,
  Button,
  IconWarning,
  Tabs,
  Token,
  Table,
  Alert,
  FormHelperText,
  Divider,
  IconInfo,
} from '@screentone/core';

import { PropertyType } from '../../../../../types';
import { getAvailablePropertiesLabels } from '../../../../../utils/helpers';

import styles from './styles.module.css';

function DeveloperUpload({ accessToken, property }: { accessToken: string; property: PropertyType }) {
  const [assetsToUpload, setAssets] = useState<File[] | undefined>();
  const properties = getAvailablePropertiesLabels();
  const [filesToUpload, setFiles] = useState<File[] | undefined>();
  const [slug, setSlug] = useState('Test Slug');
  const [headline, setHeadline] = useState('Test Headline');
  const [caption, setCaption] = useState('Test Caption');
  const [credit, setCredit] = useState('Test Credit');
  const [datePhotographed, setDatePhotographed] = useState('2024-02-02');
  const [importSource, setImportSource] = useState('dj.tools.ydj_local_image123456');
  const [importSourceId, setImportSourceId] = useState('image123456');
  const [importSourceName, setImportSourceName] = useState('Test Image 1223456');

  const [apiUploadRequest, setApiUploadRequest] = useState<Request>();
  const [apiUploadRequestBody, setApiUploadRequestBody] = useState<any>();
  const [apiUploadResponse, setApiUploadResponse] = useState<Response>();
  const [apiUploadResult, setApiUploadResult] = useState();

  const [apiUploadCldRequest, setApiUploadCldRequest] = useState<Request>();
  const [apiUploadCldRequestBody, setApiUploadCldRequestBody] = useState<any>();
  const [apiUploadCldResponse, setApiUploadCldResponse] = useState<Response>();
  const [apiUploadCldResult, setApiUploadCldResult] = useState();

  const [currentTab, setCurrentTab] = useState('data');

  const cellStyle = { fontFamily: 'var(--st-code-font-stack)', overflow: 'scroll' };

  const publishImage = () => {
    const body = {
      metadata: [
        {
          headline,
          caption,
          credit,
          datePhotographed,
          importSource,
          importSourceId,
          importSourceName,
          graphicType: 'Photo',
        },
      ],
    };

    setApiUploadRequestBody(body);
    const imRequestOptions = {
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    const request = new Request(`/api/${property}/upload/request`, imRequestOptions);
    setApiUploadRequest(request);

    fetch(request)
      .then((response) => {
        setApiUploadResponse(response);
        return response.json();
      })
      .then((result) => {
        setApiUploadResult(result);

        assetsToUpload?.length === result.length &&
          result.forEach(async (item: any, i: number) => {
            const {
              formData, // Do not modify this object
              uploadUrl, // The URL to upload the image to
              publicImageUrl, // The public URL of the image
              publishedId, // The public ID of the image
            } = item;

            const body = new FormData();
            const file = assetsToUpload && assetsToUpload[i];
            body.append('file', file as File);

            Object.entries(formData).forEach(([key, value]) => {
              body.append(key, value as string);
            });

            setApiUploadCldRequestBody(formData);
            const cldRequest = new Request(uploadUrl, { method: 'POST', body });
            setApiUploadCldRequest(cldRequest);

            fetch(cldRequest)
              .then((cldResponse) => {
                setApiUploadCldResponse(cldResponse);
                return cldResponse.json();
              })
              .then((cldResult) => {
                console.log('cldResult: ', cldResult);
                setApiUploadCldResult(cldResult);
                // The `image.secure_url` and `image.url` are internal image urls and should not be used by any externally facing clients
                // The `publicImageUrl` should be used to display the image in the client
              })
              .catch((error) => console.error(error));
          });
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setCurrentTab('request');
      });
  };

  return (
    <>
      <Box>
        <Box.Content>
          <Alert inline icon={IconInfo}>
            Use the example to see the request and response data
          </Alert>
          <Tabs
            role="tablist"
            onChange={(e: string) => setCurrentTab(e)}
            value={currentTab}
            margin={{ vertical: 'md' }}
          >
            <Tabs.Item value="data">Data</Tabs.Item>
            <Tabs.Item value="example">Example</Tabs.Item>
            <Tabs.Item
              value="request"
              disabled={!apiUploadResult}
              icon={apiUploadRequest && !apiUploadResult ? IconLoader : undefined}
            >
              Request
            </Tabs.Item>
            <Tabs.Item
              value="cld"
              disabled={!apiUploadCldResult}
              icon={apiUploadRequest && !apiUploadCldResult ? IconLoader : undefined}
            >
              Cloudinary Request
            </Tabs.Item>
          </Tabs>
          {currentTab === 'data' && (
            <>
              <Typography variant="bodytext" margin={{ top: 'md', bottom: 'md' }}>
                To upload images to Image Manager, you will need to make multiple calls. If you do not have an access
                token to call the Image Manager API you will need to generate one following the steps above. Once you
                have an access token you need to call Image Manager to get a signature token for Cloudinary. Then the
                image can be upload directly to Cloudinary.
              </Typography>
              <Typography variant="header3">Upload Properties</Typography>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Path</Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                        {`/api/{:PROPERTY}/upload/request`}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Method</Table.Cell>
                    <Table.Cell>
                      <Token color="ice">POST</Token>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan="2" isLabel>
                      Path Parameters
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>PROPERTY</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      Property key <br />
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                        {Object.keys(properties).join(' | ')}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" isLabel>
                      Headers
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>authorization</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>Bearer {`{:TOKEN}`}</Token>
                      <Typography variant="note" margin={{ top: 'xs' }}>
                        <Token>TOKEN</Token> User or M2M access token.
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>Content-Type</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>application/json</Token>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell colSpan="2" isLabel>
                      Body Object
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>metadata</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      Array of image metadata for each image to updated e.g.: <br />
                      <Typography
                        variant="note"
                        style={{ fontFamily: 'var(--st-code-font-stack)' }}
                        margin={{ bottom: 'xs' }}
                      >
                        {`{ metadata: [{:IMAGE_METADATA}]}`}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan="2" isLabel>
                      Image Metadata Object
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>headline</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}The headline of the image
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>Slug</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required from some properties
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '} An identifying slug or label for the published image set. <br />
                      <Typography
                        inline
                        variant="note"
                        style={{ fontFamily: 'var(--st-code-font-stack)' }}
                        margin={{ bottom: 'xs' }}
                      >
                        {`Format: this-is-an-example (underscores and hyphens are allowed)`}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>caption</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required from some properties
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}The image caption
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>credit</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required from some properties
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}The credit of the image
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>datePhotographed</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required from some properties
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}The date the image was created / photographed. <br />
                      <Typography
                        inline
                        variant="note"
                        style={{ fontFamily: 'var(--st-code-font-stack)' }}
                        margin={{ bottom: 'xs' }}
                      >
                        {`Format: YYYY-MM-DD`}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>importSource</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required for M2M tokens
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}A namespaced identifier of the image <br />
                      <Typography
                        variant="note"
                        style={{ fontFamily: 'var(--st-code-font-stack)' }}
                        margin={{ bottom: 'xs' }}
                      >
                        {`{:SCOPE}_{:ENVIRONMENT}_{:EXTERNAL_ID}`} | {`{:SCOPE}_{:EXTERNAL_ID}`} (for production)
                      </Typography>
                      <Typography variant="note" margin={{ bottom: 'xs' }}>
                        SCOPE: The scope of the M2M token used to upload the image.
                      </Typography>
                      <Typography variant="note" margin={{ bottom: 'xs' }}>
                        ENVIRONMENT: The current environment "dev" | "int" (not required for production).
                      </Typography>
                      <Typography variant="note">
                        EXTERNAL_ID: The custom value to identity the image set [a-zA-Z0-9].
                      </Typography>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>importSourceId</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required for M2M tokens
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}The external ID for the image.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>importSourceName</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required for M2M tokens
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}The name of the image.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>graphicType</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Required
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}
                      The type of image.
                      <Typography
                        variant="note"
                        style={{ fontFamily: 'var(--st-code-font-stack)' }}
                        margin={{ bottom: 'xs' }}
                      >
                        'Photo' | 'Illustration' | 'Photo Illustration' | 'Chart' | 'Commerce'
                      </Typography>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>category</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Optional
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}
                      Identifies if the image is related to Sports or Entertainment{' '}
                      <Typography
                        variant="note"
                        style={{ fontFamily: 'var(--st-code-font-stack)' }}
                        margin={{ bottom: 'xs' }}
                      >
                        'Sport' | 'Entertainment'
                      </Typography>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>city</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Optional
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}
                      Identifies if the city where the image was taken.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>state</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Optional
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}
                      Identifies if the state where the image was taken.
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>country</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Optional
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}
                      Identifies if the country where the image was taken.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>country</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Optional
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}
                      Identifies if the country where the image was taken.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>keywords</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Optional
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}A comma-separated list of tag names to assign to the image.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>specialInstructions</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        Optional
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        String
                      </Typography>
                      {' - '}Instructions on how the image can be used.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>oneTimeUse</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        default false
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        Boolean
                      </Typography>
                      {' - '}Sets a flag stating this image can only be used once.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>resizeOnly</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        default false
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        Boolean
                      </Typography>
                      {' - '}Set the cropping to to scale and will return the fill image with padding.
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>resizeOnly</Token>
                      {' - '}
                      <Typography variant="note" inline>
                        default false
                      </Typography>
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                        Boolean
                      </Typography>
                      {' - '}If the image can be cropped, the thumbnail zooming will be disabled.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Typography variant="header4" margin={{ top: 'md', bottom: 'none' }}>
                Example Call
              </Typography>
              <Typography variant="note" style={cellStyle}>
                <pre>{`const body = {
 const body = {
  metadata: [
    {
      slug,
      headline,
      caption,
      credit,
      datePhotographed,
      importSource,
      importSourceId,
      importSourceName,
      graphicType,
    },
  ],
};

const options = {
  method: 'POST',
  headers: {
    authorization: \`Bearer \${accessToken}\`,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
};

    const request = new Request(\`/api/\${property}/upload/request\`, options);
    fetch(request)
      .then((response) => response.json())
      .then((result) => {

        assetsToUpload?.length === result.length &&
          result.forEach(async (item: any, i: number) => {
            const {
              formData, // Do not modify this object
              uploadUrl, // The URL to upload the image to
              publicImageUrl, // The public URL of the image
              publishedId, // The public ID of the image
            } = item;

            const body = new FormData();
            const file = assetsToUpload && assetsToUpload[i];
            body.append('file', file as File);

            Object.entries(formData).forEach(([key, value]) => {
              body.append(key, value as string);
            });

            setApiUploadCldRequestBody(formData);
            const cldRequest = new Request(uploadUrl, { method: 'POST', body });
            setApiUploadCldRequest(cldRequest);

            fetch(cldRequest)
              .then((cldResponse) => cldResponse.json())
              .then((cldResult) => console.log('cldResult: ', cldResult))
              .catch((error) => console.error(error));
          });
      
      })
      .catch((error) => console.error(error));
`}</pre>
              </Typography>
              <Typography variant="header4" margin={{ top: 'md', bottom: 'none' }}>
                Error Response
              </Typography>
              <Typography variant="note" style={cellStyle}>
                <pre>{`{
  "ok": false,
  "message": "Unauthorized 001"
}`}</pre>
              </Typography>
              <Typography variant="header4" margin={{ top: 'md', bottom: 'none' }}>
                Success Response
              </Typography>

              <Typography variant="bodytext" margin={{ bottom: 'md' }}>
                A Cloudinary image object is returned.
              </Typography>
              <Alert type="warning" icon={IconWarning}>
                <span>
                  <strong>NOTE:</strong> The{' '}
                  <Token color="sunshine" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                    image.secure_url
                  </Token>{' '}
                  and{' '}
                  <Token color="sunshine" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                    image.url
                  </Token>
                  are internal image urls and should not be used by any externally facing clients. <br />
                  The{' '}
                  <Token color="sunshine" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                    publicImageUrl
                  </Token>{' '}
                  should be used to display the image in the client.
                </span>
              </Alert>
            </>
          )}

          {currentTab === 'example' && (
            <>
              <Alert type="warning" icon={IconWarning}>
                Currently only one image and required fields can be set for this example.
              </Alert>
              <FormLabel fullWidth label="Slug" required>
                <Input
                  data-testid="slug-input"
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setSlug(target.value.trim());
                  }}
                  placeholder={'Slug'}
                  value={slug}
                />
              </FormLabel>
              <FormLabel fullWidth label="Caption" required>
                <Input
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setCaption(target.value.trim());
                  }}
                  placeholder={'Caption'}
                  value={caption}
                  // error={requiredFields.headline ? !headline.length : false}
                />
              </FormLabel>
              <FormLabel fullWidth label="Headline" required>
                <Input
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setHeadline(target.value.trim());
                  }}
                  placeholder={'Headline'}
                  value={headline}
                />
              </FormLabel>
              <FormLabel fullWidth label="Caption" required>
                <Input
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setCaption(target.value.trim());
                  }}
                  placeholder={'Caption'}
                  value={caption}
                  // error={requiredFields.headline ? !headline.length : false}
                />
              </FormLabel>
              <FormLabel fullWidth label="Credit" required>
                <Input
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setCredit(target.value.trim());
                  }}
                  placeholder={'Credit'}
                  value={credit}
                  // error={requiredFields.headline ? !headline.length : false}
                />
              </FormLabel>

              <FormLabel fullWidth label="Date Photographed" required>
                <Input
                  type="date"
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setDatePhotographed(target.value.trim());
                  }}
                  placeholder={'datePhotographed'}
                  value={datePhotographed}
                  // error={requiredFields.headline ? !headline.length : false}
                />
              </FormLabel>

              <FormLabel fullWidth label="Import Source" required>
                <Input
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setImportSource(target.value.trim());
                  }}
                  placeholder={'{{SCOPE}}_{{ENVIRONMENT}}_{{EXTERNAL_ID}}'}
                  value={importSource}
                  // error={requiredFields.headline ? !headline.length : false}
                />
              </FormLabel>

              <FormLabel fullWidth label="Import Source ID" required>
                <Input
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setImportSourceId(target.value.trim());
                  }}
                  placeholder={'EXTERNAL_ID'}
                  value={importSourceId}
                  // error={requiredFields.headline ? !headline.length : false}
                />
              </FormLabel>

              <FormLabel fullWidth label="Import Source Name" required>
                <Input
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setImportSourceName(target.value.trim());
                  }}
                  placeholder={'importSourceName'}
                  value={importSourceName}
                  // error={requiredFields.headline ? !headline.length : false}
                />
              </FormLabel>

              <Divider margin={{ top: 'md' }} />
              <Alert type="warning" icon={IconWarning} inline componentEl={FormHelperText}>
                The file is use within the secondary call to Cloudinary.
              </Alert>
              <FormLabel fullWidth label="File" required>
                <Input
                  className={styles.input_file}
                  type="file"
                  accept="image/png, image/gif, image/jpg, image/jpeg, image/svg+xml'"
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    setAssets(target.files);
                  }}
                  placeholder={'Select Image'}
                />
                <FormHelperText>
                  Accepted formats: image/png, image/gif, image/jpg, image/jpeg, image/svg+xml.
                </FormHelperText>
              </FormLabel>

              <Button
                primary
                onClick={() => {
                  publishImage();
                }}
                margin={{ top: 'md' }}
              >
                Upload Image
              </Button>
            </>
          )}

          {currentTab === 'request' && (
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell isLabel style={{ width: `120px` }}>
                    Request URL
                  </Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      {apiUploadRequest?.url}
                    </Typography>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell isLabel>Method</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      {apiUploadRequest?.method}
                    </Typography>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell isLabel>Status Code</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      {apiUploadResponse?.status} {apiUploadResponse?.text}
                    </Typography>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell isLabel>Headers</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      authorization: Bearer {accessToken.slice(0, 16)}...
                      <br />
                      'Content-Type': application/json
                    </Typography>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell isLabel>Body</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      <pre>{JSON.stringify(apiUploadRequestBody, null, 2)}</pre>
                    </Typography>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell isLabel>Response</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      <pre>{JSON.stringify(apiUploadResult, null, 2)}</pre>
                    </Typography>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )}

          {currentTab === 'cld' && (
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell isLabel style={{ width: `120px` }}>
                    Request URL
                  </Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      {apiUploadCldRequest?.url}
                    </Typography>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell isLabel>Method</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      {apiUploadCldRequest?.method}
                    </Typography>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell isLabel>Status Code</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      {apiUploadCldResponse?.status} {apiUploadCldResponse?.text}
                    </Typography>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell isLabel>Body</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      <pre>{JSON.stringify(apiUploadCldRequestBody, null, 2)}</pre>
                    </Typography>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell isLabel>Response</Table.Cell>
                  <Table.Cell>
                    <Typography variant="note" style={cellStyle}>
                      <pre>{JSON.stringify(apiUploadCldResult, null, 2)}</pre>
                    </Typography>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )}
        </Box.Content>
      </Box>
    </>
  );
}

export default DeveloperUpload;
