import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';

import { PageWrapper, Loader } from '@screentone/core';
import debounce from 'lodash/debounce';

import * as utils from './Gallery.utils';
import GalleryItem from './GalleryItem';
import ImageCard from '../ImageCard';

import useConfig from '../../hooks/useConfig';
import useAssetManager from '../../hooks/useAssetManager';

import type { ImageType, PropertyType } from '../../types';

import styles from './Gallery.module.css';
import { createPublishedIdsObj } from '../../utils/helpers';
import ButtonRemoveFromLightbox from '../Buttons/RemoveFromLightbox';
import ButtonAddToLightbox from '../Buttons/AddToLightbox';

type GalleryProps = {
  /** Array of images. TODO: need to pull from standard type def once API is finalized */
  images: ImageType[];
  /** whether this takes up all available width at wider breakpoints */
  fullWidth?: boolean;
  /** callback when publish button is pressed, also controls whether it appears */
  setImage(img: ImageType): void;
  /** Id for the image card being open */
  openImage: string | null;
  /** Setter for the image card */
  setOpenImage: (id: string | null) => void;
  /** published id */
  publishedId?: string;
  /** additional actions to be added to the image card */
  additionalActions?: (image: ImageType) => React.ReactNode;
};

function Gallery({
  images,
  fullWidth = false,
  setImage: setSearchImage,
  openImage,
  setOpenImage,
  publishedId,
  additionalActions,
}: GalleryProps) {
  const {
    session: { property, IMAGE_DOMAINS, PREVIEW_SIZES, SHOW_LIGHTBOX },
  } = useConfig();

  const lastColumnCountRef = useRef(0);
  const gridContainerRef = useRef<HTMLDivElement>(null);

  const { lightboxHasAsset, removeAssetFromLightbox, addAssetToLightbox, setImage } = useAssetManager();

  // css class for container
  const galleryClasses = [styles.gallery];
  if (fullWidth) galleryClasses.push(styles['gallery--fullwidth']);

  // keep track of image widths, which depends on breakpoint and also having images add up to 100% width
  const [grid, setGrid] = useState<{ width: number }[]>([]);

  // keep track of current number of columns, which depends on breakpoint
  const [columnCount, setColumnCount] = useState<number>(0);

  const handleOpeningImageCard = useCallback(
    (image: ImageType) => {
      setOpenImage(null);
      if (image.asset_id !== openImage) {
        setOpenImage(image.asset_id);
      }
    },
    [openImage],
  );

  const onImageDismiss = useCallback(() => {
    setOpenImage(null);
  }, [setOpenImage]);

  useLayoutEffect(() => {
    if (images.length === 0) {
      setGrid([]);
      return;
    }

    function getColumnCount() {
      const containerWidth = gridContainerRef?.current?.clientWidth || 0;
      let maxImageWidth = 250;

      if (containerWidth < 500) {
        maxImageWidth = 200;
      } else if (containerWidth > 2560) {
        maxImageWidth = 300;
      }

      const cols = Math.floor(containerWidth / maxImageWidth);
      setColumnCount(cols);

      return cols;
    }

    function buildGrid(cols: number, property: PropertyType) {
      lastColumnCountRef.current = cols;
      const containerWidth = gridContainerRef?.current?.clientWidth || 0;
      const layoutGrid = utils.buildGrid({ images, columnCount: cols, containerWidth }, property);
      setGrid(layoutGrid);
    }

    const debouncedResize = debounce(() => {
      const cols = getColumnCount();
      if (cols !== lastColumnCountRef.current) {
        buildGrid(cols, property);
      }
    }, 500);

    function onResize() {
      debouncedResize();
    }

    window.addEventListener('resize', onResize, { passive: true });
    buildGrid(getColumnCount(), property);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [images, setColumnCount, property]);

  const wasGridComputed = grid.length === images.length;

  if (!wasGridComputed) {
    <div className={galleryClasses.join(' ')} ref={gridContainerRef}>
      <div className={styles.loading}>
        <Loader />
      </div>
    </div>;
  }

  const imageJSX = [];

  if (wasGridComputed) {
    for (let i = 0; i < images.length; i += columnCount) {
      const batch = images.slice(i, i + columnCount);
      let activeImage: ImageType | undefined;

      imageJSX.push(
        <React.Fragment key={i}>
          <div className={styles.row}>
            {batch.map((image, index) => {
              const dimensions = grid[index + i];

              if (openImage == image.asset_id) {
                activeImage = image;
              }

              const publishedIdsObj = createPublishedIdsObj(image as ImageType, IMAGE_DOMAINS, PREVIEW_SIZES, property);
              const imageInLightbox = lightboxHasAsset(image.asset_id);

              return (
                <GalleryItem
                  key={image.asset_id}
                  active={openImage === image.asset_id}
                  isSelected={imageInLightbox}
                  image={image}
                  dimensions={dimensions}
                  onClick={() => handleOpeningImageCard(image)}
                  RemoveFromLightboxEl={
                    SHOW_LIGHTBOX ? (
                      <ButtonRemoveFromLightbox
                        hideLabel
                        onClick={() => {
                          removeAssetFromLightbox(image.asset_id);
                        }}
                      />
                    ) : undefined
                  }
                  AddToLightboxEl={
                    SHOW_LIGHTBOX ? (
                      <ButtonAddToLightbox
                        hideLabel
                        publishedIdsObj={publishedIdsObj}
                        onClick={(e: any, info: any) => {
                          setImage(image);
                          addAssetToLightbox(image.asset_id, info?.id || Object.keys(publishedIdsObj)[0]);
                        }}
                      />
                    ) : undefined
                  }
                />
              );
            })}
          </div>
          {activeImage && (
            <PageWrapper>
              <ImageCard
                additionalActions={additionalActions && additionalActions(activeImage)}
                image={activeImage}
                onDismiss={onImageDismiss}
                setImage={setSearchImage}
                publishedId={publishedId}
              />
            </PageWrapper>
          )}
        </React.Fragment>,
      );
    }
  }

  return (
    <div className={galleryClasses.join(' ')} ref={gridContainerRef}>
      {imageJSX}
    </div>
  );
}

export default Gallery;
