import React, { useEffect } from 'react';
import { Route, Link, useLocation } from 'react-router-dom';
import { Subnav, Group, Typography } from '@screentone/core';
import { AuthRouter, ProtectedRoute, LoadingPage } from '@screentone/addon-auth-wrapper';

import isEmpty from 'lodash/isEmpty';
import GoogleAnalytics from './components/GoogleAnalytics';

import ErrorBoundary from './ErrorBoundary';

import { BaseConfigProvider, useBaseConfig } from './hooks/useBaseConfig';
import useConfig, { ConfigProvider } from './hooks/useConfig';
import { AssetManagerProvider } from './hooks/useAssetManager';
import { SearchProvider } from './hooks/useSearch';
import { AlertProvider } from './hooks/useAppAlerts';
import { UploadProvider } from './hooks/useUpload';

import { isFrame } from './utils/iframe';

import routes from './routes';

import '@screentone/core/css/index.css';
import '@screentone/addon-auth-wrapper/css/index.css';
import '@screentone/addon-autocomplete/css/index.css';
import '@screentone/addon-calendar/css/index.css';

import styles from './App.module.css';

const Navigation = () => {
  const location = useLocation();
  const {
    session: { property, ENABLE },
  } = useConfig();

  const pathnameArray = location.pathname.replace(/\/$/, '').split('/');
  const currentPath = pathnameArray[2];

  if (!ENABLE?.COLLECTIONS) {
    return null;
  }

  return (
    <Subnav margin={{ all: 'none' }} align="space-between">
      <Group>
        <Subnav.Item active={currentPath === 'images'} componentEl={Link} to={`/${property}/images`}>
          Images
        </Subnav.Item>
        {ENABLE.VIDEOS && (
          <Subnav.Item active={currentPath === 'videos'} componentEl={Link} to={`/${property}/videos`}>
            Videos
          </Subnav.Item>
        )}
        {ENABLE.CHARTS && (
          <Subnav.Item active={currentPath === 'charts'} componentEl={Link} to={`/${property}/charts`}>
            Charts
          </Subnav.Item>
        )}
        {ENABLE.COLLECTIONS && (
          <Subnav.Item active={currentPath === 'collections'} componentEl={Link} to={`/${property}/collections`}>
            Collections
          </Subnav.Item>
        )}
      </Group>
    </Subnav>
  );
};

const AppContent = () => {
  const { baseConfig } = useBaseConfig();
  useEffect(() => {
    const timeout = (delay: number) => {
      return new Promise((res) => setTimeout(res, delay));
    };
    const handleLoginCallback = async () => {
      if (window.location.pathname === '/login/callback/') {
        await timeout(1500);
        if (window.location.pathname === '/login/callback/') {
          window.location.pathname = '/';
        }
      }
    };
    handleLoginCallback();
    return () => {};
  }, [window.location.pathname]);

  if (isEmpty(baseConfig)) {
    return <LoadingPage />;
  }

  baseConfig.app.headerProps = {
    after: <Navigation />,
  };

  const Providers = ({ children }: { children: React.JSX.Element }) => (
    <ConfigProvider>
      <AssetManagerProvider>
        <AlertProvider>
          <SearchProvider>
            <UploadProvider>{children}</UploadProvider>
            <GoogleAnalytics analyticsId={baseConfig.app.analyticsId} />
          </SearchProvider>
        </AlertProvider>
      </AssetManagerProvider>
    </ConfigProvider>
  );

  return (
    <div className={isFrame() ? styles.im_browser_mode__iframe : styles.im_browser_mode__standalone}>
      <AuthRouter
        app={baseConfig.app}
        authConfig={baseConfig.auth}
        render={(children) => <Providers>{children}</Providers>}
        headerBarWrapper={({ children }) => <div data-header="true">{children}</div>}
      >
        {routes.map((route) => {
          const RouteComponent = route.component;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.protected ? (
                  <ProtectedRoute path={route.path} restricted={route.restricted}>
                    <RouteComponent />
                  </ProtectedRoute>
                ) : (
                  <RouteComponent />
                )
              }
            />
          );
        })}
      </AuthRouter>

      {isFrame() && baseConfig?.app && (
        <Typography
          componentEl="p"
          variant="note"
          align="center"
          color="asphalt"
          size="sm"
          data-testid="iframe-version"
        >
          {baseConfig.app.versions?.[0]?.name} - {baseConfig.app.versions?.[0]?.version}
          {baseConfig.app.envLabel ? ` - ${baseConfig.app.envLabel}` : ''}
        </Typography>
      )}
    </div>
  );
};

function App() {
  return (
    <ErrorBoundary
      fallback={
        <div className="st_grp st_grp--column st_grp--a-center st_grp--v-center st_grp--g-mlg st_grp--wrap st_m-ver-xl">
          <div
            className="st_text st_text--align-inherit st_text--xxl st_text--color-ink st_text--weight-reg st_whitespace--normal"
            data-testid="delete-confirmation-message"
          >
            Error
          </div>
          <img
            alt="App Error"
            className="st_illustration"
            style={{ width: '100%', maxWidth: '40%' }}
            src="https://screentone.dowjones.io/assets/server-down.svg?src=st_illustration"
          />
          <div className="st_text st_text--align-center st_text--md st_text--color-ink st_text--weight-reg st_whitespace--normal">
            <p className="st_ErrorMessage">Something went wrong.</p>

            <p className="st_ErrorMessage">
              Please{' '}
              <b>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="clear-cache-link"
                  onClick={() => {
                    // @ts-ignore
                    handleHardReload(window.location.href);
                  }}
                >
                  Try Again
                </a>
              </b>{' '}
              or if the issue persists contact the{' '}
              <a
                href="https://dowjones.slack.com/archives/C7XBZEU2V"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="slack-link"
              >
                #image-manager team on slack
              </a>{' '}
              if you need further assistance.
            </p>
          </div>
        </div>
      }
    >
      <BaseConfigProvider>
        <AppContent />
      </BaseConfigProvider>
    </ErrorBoundary>
  );
}
export default App;
