import { User } from '@screentone/addon-auth-wrapper';
import { Group } from '@screentone/core';
import React from 'react';

import ImageCard from '../ImageCard';
import type { ImageType } from '../../types';

type ImageListProps = {
  /** array of images */
  images: ImageType[];
  /** current logged in users email */
  user: User;
  /** callback when publish button is pressed, also controls whether it appears */
  setImage(img: ImageType): void;
  /** published id */
  publishedId?: string;
  /** additional actions to be added to the image card */
  additionalActions?: (image: ImageType) => React.ReactNode;
};

function ImageList({ images, setImage: setSearchImage, publishedId, additionalActions }: ImageListProps) {
  if (!images || images.length === 0) return null;
  return (
    <Group direction="column" gap="sm" margin={{ bottom: 'xl' }}>
      {images.map((image) => {
        return (
          <Group.Item key={image.asset_id} fullWidth>
            <ImageCard
              additionalActions={additionalActions && additionalActions(image)}
              image={image}
              setImage={setSearchImage}
              publishedId={publishedId}
            />
          </Group.Item>
        );
      })}
    </Group>
  );
}

export default ImageList;
