import React, { useContext, useState, useEffect, createContext } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { useSearchParams } from 'react-router-dom';

import { useBaseConfig } from '../useBaseConfig';
import { authFetch as authFetchUtil } from './useConfig.utils';
import { iframe } from '../../utils';

import type { AppConfigBase, OktaAuthServiceBase } from '../useBaseConfig/useConfig.types';
import type { EnvironmentType, PropertyType } from '../../types';

const ConfigContext = createContext({});

type BaseConfigType = {
  app: AppConfigBase;
  auth?: OktaAuthServiceBase;
  property: { [key: string]: any };
};

interface ConfigType extends BaseConfigType {}

type SessionType = {
  appUrl?: string;
  env?: EnvironmentType;
  property: PropertyType;
  IMAGE_DOMAINS?: {
    default: string;
    [key: string]: string;
  };
  PREVIEW_SIZES?: {
    [key: string]: {
      ar: number;
      label: string;
      SELECTION?: boolean;
    };
  };
  DOWNLOADS?: {
    label: string;
    sizes: {
      label: string;
      width: number;
      aspect_ratio: number;
      format: string;
      name: string;
    }[];
  }[];
  RELATED_PROPERTIES: PropertyType[];
  SEARCH?: {
    OPTIONS: {
      show_advanced: boolean;
      keywords?: {
        label: string;
        name: string;
      }[];
      categories?: {
        label: string;
        name: string;
      }[];
      region?: {
        label: string;
        name: string;
      }[];
      sources?: {
        [key: string]: {
          label: string;
          folder: string;
          subSources?: {
            [key: string]: {
              label: string;
              folder: string;
            };
          };
        };
      };
      tags?: {
        [key: string]: {
          key: string;
          color: string;
          icon: string;
          title: string;
          label: string;
          editable?: boolean;
        };
      };
      FORM_VALIDATION?: string[];
    };
  };
  KEY?: PropertyType;
  ENABLE?: {
    COLLECTIONS: boolean;
    VIDEOS: boolean;
    CHARTS: boolean;
  };
  SHOW_LIGHTBOX: boolean;
  OVERLAYS?: {
    options: {
      label: string;
      [key: string]: any;
    };
    fields: {
      [key: string]: {
        description: string;
        type: string;
        component: string;
        enum?: string[];
      };
    };
  };
};

const getUpdatedProperty = (currentProperty?: string | null) => {
  const property = currentProperty || window.location.pathname.split('/')[1];
  const skip = ['developer', 'api', 'login', 'logout', 'auth', 'config', 'api'];
  if (!skip.includes(property)) {
    return property as PropertyType;
  } else {
    return undefined;
  }
};

export function ConfigProvider({ children }: { children: React.ReactNode }) {
  const { currentProperty, oktaAuth, user } = useAuth();
  const { baseConfig } = useBaseConfig();
  const [searchParams] = useSearchParams();
  const [config, setConfig] = useState<ConfigType>((baseConfig as ConfigType) || {});
  const [session, setSession] = useState<SessionType>({
    property: (currentProperty as PropertyType) || getUpdatedProperty(currentProperty),
    appUrl: baseConfig?.app?.appUrl,
    RELATED_PROPERTIES: [],
  });
  const [propertyConfig, setPropertyConfig] = useState<any>({});
  const [showLightbox, setShowLightbox] = useState(propertyConfig.ENABLE?.COLLECTIONS || iframe.sendImageArray());

  useEffect(() => {
    setShowLightbox(propertyConfig.ENABLE?.COLLECTIONS || iframe.sendImageArray());
  }, [propertyConfig]);

  const ref = searchParams.get('ref') || '';
  const source = searchParams.get('source') || '';

  useEffect(() => {
    const fetchConfig = async () => {
      const updatedProperty = getUpdatedProperty(currentProperty);

      if (updatedProperty) {
        setSession({ ...session, property: updatedProperty });
      }

      if (updatedProperty && updatedProperty !== propertyConfig?.KEY) {
        const data = await authFetch(`/api/config/${updatedProperty}`);

        setPropertyConfig(data);
      }
    };
    fetchConfig();
  }, [currentProperty, user]);

  useEffect(() => {
    iframe.initializeSessionStorage(ref, source);
  }, [ref, source]);

  useEffect(() => {
    setConfig({ ...config, ...baseConfig });
  }, [baseConfig]);

  const authFetch = async (resource: string, options?: RequestInit) => {
    // @ts-ignore - getTokens is a valid method
    const newTokens = await oktaAuth.tokenManager.getTokens();
    const fetchOptions = {
      resource,
      options,
      property: session.property || currentProperty,
      accessToken: newTokens.accessToken?.accessToken,
    };
    return authFetchUtil(fetchOptions);
  };

  return (
    <ConfigContext.Provider
      value={{
        config: config as ConfigType,
        session: {
          ...session,
          ...(propertyConfig || {}),
          ...{ SHOW_LIGHTBOX: showLightbox },
        } as SessionType,
        authFetch,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context as {
    config: BaseConfigType;
    session: SessionType;
    authFetch: (resource: string, options?: RequestInit) => Promise<any>;
  };
}

export default useConfig;
