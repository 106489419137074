import React, { useCallback } from 'react';
import {
  Button,
  Dropdown,
  Group,
  IconCaretDown,
  List,
  Dialog,
  useAnimatedModalState,
  useModalPortal,
  Typography,
  useDropdownState,
} from '@screentone/core';

import styles from './VariantButton.module.css';

type VariantButtonItemType = {
  [key in 'primary' | 'secondary' | 'tertiary']?: boolean;
} & {
  id: string;
  previewUrl: string;
  label: string;
  buttonProps: any; // replace 'any' with the actual type if known
};
type VariantButtonType = {
  [key in 'primary' | 'secondary' | 'tertiary']?: boolean;
} & {
  label: string;
  items: VariantButtonItemType[];
  ButtonComponentEl?: any;
  dialogTitle?: string;
  icon?: any;
  size?: string;
  text?: string;
};

type childrenTemplateType = 'dropdown' | 'dialog';
type generateItemsType = {
  /** object containing published ids with its label, description and preview URL */
  items: { [key: string]: { id: string; label: string; url: string } };
  /** function with current object so you can return the correct button icon for this item */
  iconTemplate?: (item: any) => void;
  /** function with current object so you can return the correct button content */
  childrenTemplate: (item: any, type: childrenTemplateType) => React.ReactNode;
  /** function with current object so you can return the correct button text for this item */
  onClickHandler?: (e: React.MouseEvent, item: any) => void;
  /** function with current object so you can return the correct button props for this item */
  otherButtonPropTemplate?: (item: any) => void;
};

function generateItems({
  items,
  iconTemplate,
  onClickHandler,
  otherButtonPropTemplate,
  childrenTemplate,
}: generateItemsType) {
  const keys = Object.keys(items);
  return keys.map((id) => {
    const item = items[id];
    const { label, url } = items[id];
    const otherButtonPops = (otherButtonPropTemplate && otherButtonPropTemplate(item)) || {};
    const data = {
      id,
      previewUrl: url,
      label,
      buttonProps: {
        // tertiary: true,
        icon: iconTemplate && iconTemplate(item),
        onClick: (e: React.MouseEvent) => onClickHandler && onClickHandler(e, item),
        children: (type: childrenTemplateType) => childrenTemplate(item, type),
        ...otherButtonPops,
      },
    };
    return data;
  });
}

const variantChildButtons = (item: any, type: 'dropdown' | 'dialog') => {
  if (type === 'dialog') {
    return item.id;
  }
  return (
    <Group wrap={false} gap="sm" align="space-between" fullWidth>
      <Group.Item>{item.label}</Group.Item>
      <Group.Item>{item.id}</Group.Item>
    </Group>
  );
};

function VariantButton({ label, items, ButtonComponentEl = Button, dialogTitle, ...otherProps }: VariantButtonType) {
  const { open: dropDownOpen, setOpen: dropDownSetOpen, componentRef: dropDownRef } = useDropdownState();
  const handleDropdownClick = useCallback(() => {
    dropDownSetOpen(!open);
  }, [dropDownSetOpen, dropDownOpen]);

  const { open, status, componentRef, openModal, closeModal } = useAnimatedModalState();
  const { renderNode } = useModalPortal();

  if (!items || items.length === 0) {
    return <></>;
  }
  if (items?.length === 1) {
    const buttonProps = { ...items[0].buttonProps, ...otherProps };
    if (!('primary' in buttonProps) || !('secondary' in buttonProps) || !('tertiary' in buttonProps)) {
      buttonProps.tertiary = true;
    }
    if ('primary' in buttonProps && 'secondary' in buttonProps) {
      delete buttonProps.secondary;
    }
    if ('primary' in buttonProps && 'tertiary' in buttonProps) {
      delete buttonProps.tertiary;
    }

    return <ButtonComponentEl {...buttonProps}>{label}</ButtonComponentEl>;
  }

  return (
    <>
      <Group gap={0} className={styles.groupHover}>
        <Button {...otherProps} onClick={openModal}>
          {label}
        </Button>
        <Dropdown
          open={dropDownOpen}
          onToggle={handleDropdownClick}
          componentRef={dropDownRef}
          trigger={<IconCaretDown />}
          position="right"
          padding={{ all: 'none' }}
        >
          <List itemMargin={{ all: 'xs' }}>
            {items.map((item) => {
              const { onClick, children, ...buttonProps } = item.buttonProps;
              if (!('primary' in buttonProps) || !('secondary' in buttonProps) || !('tertiary' in buttonProps)) {
                buttonProps.tertiary = true;
              }
              if ('primary' in buttonProps && 'secondary' in buttonProps) {
                delete buttonProps.secondary;
              }
              if ('primary' in buttonProps && 'tertiary' in buttonProps) {
                delete buttonProps.tertiary;
              }
              return (
                <List.Item>
                  <ButtonComponentEl
                    fullWidth
                    {...buttonProps}
                    onClick={(e: any) => {
                      onClick && onClick(e, item);
                      setTimeout(() => {
                        dropDownSetOpen(false);
                      }, 300);
                    }}
                  >
                    {children('dropdown')}
                  </ButtonComponentEl>
                </List.Item>
              );
            })}
          </List>
        </Dropdown>
      </Group>
      {open && (
        <Dialog
          aria-labelledby="dialogTitle"
          aria-describedby="dialogDesc"
          onDismiss={closeModal}
          renderNode={renderNode}
          status={status}
          componentRef={componentRef}
        >
          <Dialog.Title id="dialogTitle" data-testid="popup-img-set-title">
            {dialogTitle || label}
          </Dialog.Title>
          <Dialog.Content id="dialogDesc">
            <Typography variant="header4" data-testid="popup-img-set-header">
              This image contains multiple image sets
            </Typography>
            <Typography variant="label1" data-testid="popup-img-set-label">
              Please select an image set:
            </Typography>
            <List itemMargin={{ all: 'xs' }} data-testid="popup-image-set-list">
              {items.map((item) => {
                const { onClick, children, ...buttonProps } = item.buttonProps;
                if (!('primary' in buttonProps) || !('secondary' in buttonProps) || !('tertiary' in buttonProps)) {
                  buttonProps.tertiary = true;
                }
                return (
                  <List.Item
                    data-testid="popup-image-set-list"
                    ket={item.id}
                    className={styles.listItem}
                    avatar={{
                      avatar: item.previewUrl,
                    }}
                  >
                    <span>
                      <Typography variant="label3" componentEl="span">
                        {item.label}
                      </Typography>
                      <ButtonComponentEl
                        groupComponentEl="span"
                        {...buttonProps}
                        onClick={(e: any) => {
                          onClick && onClick(e, item);
                          setTimeout(() => {
                            closeModal();
                          }, 500);
                        }}
                      >
                        {children('dialog')}
                      </ButtonComponentEl>
                    </span>
                  </List.Item>
                );
              })}
            </List>
          </Dialog.Content>
        </Dialog>
      )}
    </>
  );
}

export default VariantButton;
export { generateItems, variantChildButtons };
