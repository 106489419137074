import { format } from '@screentone/addon-calendar';
import ExifReader from 'exifreader';

import { constants, helpers } from '../../../utils';
import { slugNormalize } from '../../../utils/helpers';
import config from '../../../utils/fieldsValidation/config';

import type { UserType, tExtendedImageMetadata, UniqueImageResponseObject } from '../../../types';

type UploadTypeType = 'dynamic' | 'single';
type processedType = {
  state: string;
  error: { code: string; message: string; asset_id?: string; folder?: string } | null;
};

type processedFileMetadataType = tExtendedImageMetadata;

type getImageMetadataType = {
  file: File;
  type: UploadTypeType;
  user?: UserType;
};

type renderDataType = {
  width: number;
  height: number;
  secure_url: string;
};

const loadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      return resolve({ 'Image Width': { value: img.width }, 'Image Height': { value: img.height } });
    };
    img.onerror = reject;
    img.src = src;
  });
};

/**
 * Given an image file, extract the EXIF data from the file and handle any possible errors
 */
const parseImageData = async (file: File, url: string) => {
  // This array is used to add image formats that the ExifReader library doesn't support, so we can avoid showing the warning message.

  // TODO: this should change to a supported list of image formats
  const typesNotAllowed = ['gif', 'svg+xml'];
  const currentFileType = file?.type.split('/')[1];
  try {
    if (typesNotAllowed.includes(currentFileType)) {
      const metadata = await loadImage(url);
      return metadata;
    }
    const metadata = await ExifReader.load(file);
    return metadata;
  } catch (error) {
    console.error('error: ', error);
    return null;
  }
};

/**
 * Extract and format the date from an image's metadata
 */
const handleDates = (tags: any) => {
  const parsedDate = helpers.parseDate(
    tags?.DateCreated?.description ||
      tags?.['Date Created']?.description ||
      tags?.CreateDate?.description ||
      tags?.DateTime?.description ||
      tags?.DateTimeOriginal?.description ||
      tags?.DateTimeDigitized?.description,
  );

  let formattedDate = '';
  try {
    formattedDate = format(parsedDate, constants.DATE_FORMATS.CLOUDINARY);
  } catch (err) {} /* eslint-disable-line no-empty */

  return formattedDate;
};

/**
 * Get Category value from metadata
 */
const handleCategory = (category: string) => {
  if (category && ['s', 'sport'].includes(category.toLowerCase())) {
    return 'Sport';
  } else if (category && ['e', 'entertainment'].includes(category.toLowerCase())) {
    return 'Entertainment';
  } else {
    return category || '';
  }
};

/**
 * Extract and format the keywords from an image's metadata
 */
const handleKeywords = (tags: any) => {
  let keywords: string[] = [];

  if (Array.isArray(tags?.Keywords)) {
    keywords = tags.Keywords.map(({ description }: { description: string }) => description);
  } else if (typeof tags?.Keywords?.description === 'string') {
    keywords = [tags.Keywords.description];
  }

  return keywords.filter((keyword) => !!keyword);
};

/**
 *  parse  image file, extract the metadata, format that data and
 *  apply it to the file
 */
const getImageMetadata = async ({
  file,
  type,
  user,
}: getImageMetadataType): Promise<[renderDataType, processedFileMetadataType]> => {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  const secure_url = URL.createObjectURL(file);
  const tags: any = await parseImageData(file, secure_url);
  const renderData: renderDataType = {
    width: tags?.['Image Width']?.value || tags?.PixelXDimension?.value,
    height: tags?.['Image Height']?.value || tags?.PixelYDimension?.value,
    secure_url,
  };

  const regex = /@[\d]x.[a-z]{2,4}$/gi;

  const metadata: processedFileMetadataType = {
    slug: type === 'dynamic' ? 'PROMO' : slugNormalize(file.name.split('.')[0].slice(0, 32)),
    headline: tags?.Headline?.description || tags?.title?.description || file.name || '',
    caption: tags?.Caption?.description || tags?.['Caption/Abstract']?.description || '',
    credit: tags?.PublishedCredit?.description || tags?.Credit?.description || '',
    contact: tags?.Contact?.description || helpers.formatUserName(user?.name || ''),
    datePhotographed: handleDates(tags),
    graphicType: constants.GRAPHIC_TYPES.includes(tags?.GraphicType?.description as string)
      ? tags?.GraphicType?.description
      : constants.DEFAULTS[`${type === 'dynamic' ? 'DYNAMIC_' : ''}GRAPHIC_TYPE`],
    altText: '',
    category: type === 'dynamic' ? '' : handleCategory(tags?.Category?.description),
    city: type === 'dynamic' ? '' : tags?.City?.description || '',
    state: type === 'dynamic' ? '' : tags?.State?.description || tags?.['Province/State']?.description || '',
    country: type === 'dynamic' ? '' : tags?.Country?.description || tags?.['Country/PrimaryLocationName']?.description || '',
    keywords: type === 'dynamic' ? '' : handleKeywords(tags),
    specialInstructions: type === 'dynamic' ? '' : tags?.Instructions?.description || tags?.SpecialInstructions?.description || '',
    subCategories: type === 'dynamic' ? '' : tags?.SupplementalCategories?.description || '',
    oneTimeUse: false,
    resizeOnly: false,
    importSourceName: type === 'dynamic' ? file.name.split('.')[0] : file.name,
    importSourceType: 'upload',
    uniqueId: tags?.UniqueID?.description || '',
    objectName: tags?.ObjectName?.description || tags?.['Object Name']?.description || null,
    transmissionReference: tags?.TransmissionReference?.description || null,
  };

  if (type === 'dynamic') {
    const xScale = file.name.match(regex);
    metadata.xScale = Number(xScale?.[0].split('.')?.[0]?.replace('@', '').replace('x', '')) || 1;
  }

  return [renderData, metadata];
};

type ProcessDroppedFilesParams = {
  /** image file that the user intends to upload to Cloudinary */
  file: File;
  /** user object from useAuth */
  user?: UserType;
  /** upload type */
  type: UploadTypeType;
  /** authFetch function from useAuth */
  authFetch: (resource: string, options?: RequestInit | undefined) => Promise<any>;
};

const fetchByUniqueId = async (
  metadata: processedFileMetadataType,
  authFetch: (resource: string, options?: RequestInit | undefined) => Promise<any>,
): Promise<UniqueImageResponseObject | null> => {
  const { uniqueId, objectName, transmissionReference } = metadata;
  if (uniqueId) {
    try {
      const data: UniqueImageResponseObject = await authFetch(
        `/api/:property/findByUniqueId/${uniqueId}?objectName=${objectName}&transmissionReference=${transmissionReference}`,
      );
      return data;
    } catch (error) {
      console.error('error:', error);
    }
  }
  return null;
};

/**
 * Given an array of accepted files and an array of rejected files, preform extra validation, parse
 * the metadata and generally format the data to put onto state in the useUpload hook
 */

export const processDroppedFile = async ({ file, user, type, authFetch }: ProcessDroppedFilesParams) => {
  const processed: processedType = {
    state: 'accepted',
    error: null,
  };

  const buildValidationObject = () => {
    const validationConfig = Object.keys(config);
    const validation = validationConfig.reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {});
    return validation;
  };

  const [renderData, metadata] = await getImageMetadata({ file, type, user });

  // NOTE: some images may not have width and height (e.g. gif's), just let them pass
  // in all likelihood, gif's with dimensions that are too large would exceed the minimum file size
  // if they do manage to get past this check, they will fail to upload and the user will be notified
  // if image does exceed max pixel limit, add to rejected
  if (!((renderData.width * renderData.height || 0) <= constants.MAX_PIXELS)) {
    processed.state = 'rejected';
    processed.error = {
      code: 'max-pixels',
      message: `File exceeds max pixels (width * height > ${constants.MAX_PIXELS})`,
    };
  }

  /**
   * This block of code execute one by one and is responsible for processing an error that occurs when uploading an image that's already in the system.
   * It fetches data for each file based on its unique ID and checks if the file already exists in the system.
   * If the file exists, it is added to the `rejectedBatch` array along with an error message and remove it from the accepted array.
   */

  try {
    // Fetch data for the file based on its unique ID
    const data: UniqueImageResponseObject | null = await fetchByUniqueId(metadata, authFetch);

    if (data && data.found) {
      const { resources = [] } = data?.response || {};
      if (resources.length) {
        for (let i = 0; i < resources.length; i++) {
          const resource = resources[i];
          if (
            resource.width === renderData?.width &&
            resource.height === renderData?.height &&
            resource.bytes === file.size
          ) {
            //ERROR IMAGE ALREADY EXISTS MSG
            processed.state = 'rejected';
            processed.error = {
              code: 'image-exists',
              message: `This image is already in the system`,
              asset_id: resource.asset_id,
              folder: resource.folder,
            };
            break;
          }
        }
      }
    }
  } catch (error) {
    console.error('Error:', error);
  }
  return { ...processed, file, renderData, metadata, validation: buildValidationObject() };
};
