import React, { useEffect, useRef, useState } from 'react';
import { Alert, Overlay, useModalPortal } from '@screentone/core';
import { useSearchParams } from 'react-router-dom';

import useEditImage from '../../../hooks/useEditImage';
import useImageDetail from '../../../hooks/useImageDetail';

import ImageCoordinates from './ImageCoordinates';
import ImageHeader from './ImageHeader';
import ImagePreview from './ImagePreview';
import Image from '../../Image';

import { isFrame } from '../../../utils/iframe';

import styles from './Content.module.css';

const Content = () => {
  const { image, publishedId, publishedIdsObj } = useImageDetail();
  const { CONTENT_COMPONENT_CONST, contentComponent, previewType } = useEditImage();
  const [searchParams] = useSearchParams();
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [imageContainerWidth, setImageContainerWidth] = useState<number | null>(null);

  const useOverlay = searchParams.get('overlay') !== 'false';
  const PREVIEW_SIZES = publishedIdsObj[publishedId as string]?.previewSizes || [];
  const imageUrl = PREVIEW_SIZES[previewType]?.url || image.preview_url || image.secure_url;
  const { renderNode } = useModalPortal();
  const { width, height, ar } = PREVIEW_SIZES[previewType] || {};
  const imageWidth = width || (height && ar ? height * ar : null);

  useEffect(() => {
    const imageContainer = imageContainerRef.current;
    if (imageContainer) {
      setImageContainerWidth(imageContainer.offsetWidth);
    }
  }, [imageContainerRef.current]);

  if (useOverlay || isFrame()) {
    return (
      <>
        {imageContainerWidth && imageWidth && imageWidth > imageContainerWidth && (
          <Alert
            type="warning"
            style={{
              borderRightColor: 'var(--st-border-color)',
              borderLeftColor: 'var(--st-border-color)',
              borderTop: 'none',
              borderBottom: 'none',
              padding: 'var(--st-spacer-sm) var(--st-spacer-md)',
            }}
          >
            Note: This image preview has been scaled in the browser.
          </Alert>
        )}
        <div ref={imageContainerRef} className={styles.image}>
          <Image src={imageUrl} width={width} height={height} ar={ar} />
        </div>
        <div>
          <ImagePreview />
        </div>
        {[
          CONTENT_COMPONENT_CONST.FOCAL_AREA.KEY,
          CONTENT_COMPONENT_CONST.THUMB_FOCAL_AREA.KEY,
          CONTENT_COMPONENT_CONST.CROP.KEY,
        ].includes(contentComponent) && (
          <Overlay renderNode={renderNode} status="open">
            <div
              style={
                isFrame()
                  ? { margin: 0, width: '100vw', height: '100vh', overflow: 'auto' }
                  : { maxWidth: '100vw', maxHeight: '100vh', overflow: 'auto' }
              }
            >
              <ImageHeader canEditFocalPoint={true} useOverlay />
              <ImageCoordinates />
              <ImagePreview skipOriginal={!!contentComponent} />
            </div>
          </Overlay>
        )}
      </>
    );
  }
  switch (contentComponent) {
    case CONTENT_COMPONENT_CONST.FOCAL_AREA.KEY:
    case CONTENT_COMPONENT_CONST.THUMB_FOCAL_AREA.KEY:
    case CONTENT_COMPONENT_CONST.CROP.KEY:
      return (
        <>
          <ImageCoordinates />
          <ImagePreview />
        </>
      );
    default:
      return (
        <>
          <div className={styles.image}>
            <Image src={imageUrl} />
          </div>
          <div>
            <ImagePreview />
          </div>
        </>
      );
  }
};

export default Content;
